import {Field} from "@/types/fields";
import {OperationQueueListData} from "@/types/operation-queue";

export const operationQueueFields: Field<OperationQueueListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "Код", value: true, minWidth: "120px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
    {field: "sla", sortDirections: ['descend', 'ascend'], name: "Учитывать в SLA", value: true, minWidth: "",
        compute: (val) => val === true ? 'Да' : 'Нет'},
    {field: "active", sortDirections: ['descend', 'ascend'], name: "Активность", value: true, minWidth: "",
        compute: (val) => val === true ?  'Да' : 'Нет'},
];

export const getOperationQueueData = () => ({
    id: null,
    name: "",
    sla: false,
    active: false
});
