import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a90b344"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pl-4 pr-4 pb-5 mb-4 view-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AreasResponsibilityCharacteristics = _resolveComponent("AreasResponsibilityCharacteristics")!
  const _component_EditForm = _resolveComponent("EditForm")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_delete_dialog = _resolveComponent("delete-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.hasAccessToRead('dictionary','areas_of_responsibility','tab_common_information_zone_responsible'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_a_tabs, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_tab_pane, {
                key: "1",
                tab: "Общая информация"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_EditForm, {
                    onSave: _ctx.save,
                    onReject: _ctx.cancel,
                    onDelete: _ctx.openDeleteDialog,
                    data: {id: _ctx.body.id,isHasSave: _ctx.hasAccessToSave('dictionary','areas_of_responsibility', 'tab_common_information_zone_responsible'),
         isDel: _ctx.hasAccessToDelete('dictionary','areas_of_responsibility', 'tab_common_information_zone_responsible')}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AreasResponsibilityCharacteristics, {
                        ref: "characteristics",
                        body: _ctx.body
                      }, null, 8, ["body"])
                    ]),
                    _: 1
                  }, 8, ["onSave", "onReject", "onDelete", "data"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_delete_dialog, {
      ref: "delete",
      onUpdateDialog: _ctx.deleteObject
    }, null, 8, ["onUpdateDialog"])
  ], 64))
}