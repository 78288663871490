import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5aa0f196"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pl-4 pr-4 mb-4 view-container" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      destroyInactiveTabPane: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: "1",
          tab: "Общая информация"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", { innerHTML: _ctx.innerHTMLInfo }, null, 8, _hoisted_2)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["activeKey"])
  ]))
}