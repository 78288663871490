import {Field} from "@/types/fields";
import {MonitoringTemplateListData} from "@/types/monitoring-templates";

export const monitoringTemplateFields: Field<MonitoringTemplateListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "110px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
    {field: "description", sortDirections: ['descend', 'ascend'], name: "Описание", value: true, minWidth: ""},
]

export const getMonitoringTemplateData = () => ({
    id: null,
    name: '',
    description: '',
})
