
import { defineComponent } from "vue";
import ListBase from "@/classes/ListBase.vue";

export default defineComponent({
  mixins: [ListBase],
  methods: {
    routeToAdd(data) {
      console.log(data)
      this.$router.push({
        path: `/directory/calculation-rules/add`,
        query: {
          relatedId: data.metricTypeId,
          defaultValue: data.defaultValue,
          formula: data.formula,
          metricIds: data.metricIds,
          timeCondition: data.timeCondition,
          typeAggregation: data.typeAggregation,
          typeInterpolation: data.typeInterpolation,
          typeWorkTime: data.typeWorkTime
        },
      });
    }
  }
});
