import { Field } from "@/types/fields";
import { formatDate } from "@/filters/date";

export interface schedulerRunEventData {
  id: number;
  result: string;
  status: string;
  timeEnd: string;
  timeStart: string;
}

export const schedulerRunEventFields: Field<schedulerRunEventData>[] = [
  {
    field: "timeStart",
    sortDirections: ["descend", "ascend"],
    name: "Дата запуска",
    compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
      formatDate(value, format),
    value: true,
    minWidth: "",
  },
  {
    field: "timeEnd",
    sortDirections: ["descend", "ascend"],
    name: "Дата завершения",
    compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
      formatDate(value, format),
    value: true,
    minWidth: "",
  },
  {
    field: "status",
    sortDirections: ["descend", "ascend"],
    name: "Статус",
    value: true,
    minWidth: "",
  },
  {
    field: "result",
    sortDirections: ["descend", "ascend"],
    name: "Результат",
    value: true,
    minWidth: "",
  },
];
