import {MailNotificationListData} from "@/types/mail-notification";
import {Field} from "@/types/fields";

export const MailNotificationFields: Field<MailNotificationListData> [] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "50px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "150px"},
    {field: "description", sortDirections: ['descend', 'ascend'], name: "Описание", value: true, minWidth: "150px"},
]
export const getMailNotificationData = () => ({
    id: null,
    name: '',
    description: '',
    counterparty: {
        id: null,
        name: ''
    }
})