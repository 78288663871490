import Axios from 'axios-observable';
import {Observable} from "rxjs/dist/types";
import {engineRestUrl, send, url} from "@/helpers/api";

export const DecisionDefinitionsApi = {
  applyFilter(data: any, size = 10, page = 0) : any  {
    return send({
      method: 'get',
      url: `${engineRestUrl}/decision-definition?latestVersion=true`,
      data,
    });
  },
  applyFilter2(data: any, size = 10, page = 0): Observable<any> {
    return Axios.request({
      method: 'get',
      url: `${engineRestUrl}/decision-definition?latestVersion=true`,
      data,
    });
  },
  getDecisionDefinition(id) {
    return send({
      url: `${engineRestUrl}/decision-definition/key/${id}`,
      method: "get",
    })
  },
  getDecisionDefinitionByID(id) {
    return send({
      url: `${engineRestUrl}/decision-definition/${id}/xml`,
      method: "get",
    })
  },
  deploymentCreate(data): any {
    return send({
      url: `${engineRestUrl}/deployment/create`,
      method: "post",
      data
    });
  },
};
