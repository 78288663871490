import {Field} from "@/types/fields";
import {CalculationRulesHistoryListData} from "@/types/calculation-rules-history";
import {formatDate} from "@/filters/date";

export const calculationRulesHistoryFields: Field<CalculationRulesHistoryListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: ""},
    {field: "dateStart", sortDirections: ['descend', 'ascend'], name: "Дата активации",    compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
            formatDate(value, format), value: true, minWidth: ""},
    {field: "dateEnd", sortDirections: ['descend', 'ascend'], name: "Дата деактивации",     compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
            formatDate(value, format), value: true, minWidth: ""},
    {field: "calculationRuleId", sortDirections: ['descend', 'ascend'], name: "ID правила", value: true, minWidth: ""},
    {field: "calculationTypeRule", sortDirections: ['descend', 'ascend'], name: "Тип правила", value: true, minWidth: ""},
]
export const getCalculationRulesHistoryData = () => ({
    id: null,
    dateStart: '',
    dateEnd: '',
    calculationRuleId: null,
    calculationTypeRule: ''
})
