
import {defineComponent, ref} from 'vue';

export default defineComponent({
  data () {
    return {
      title: '',
      text: 'Вы действительно хотите ',
      textApp: '',
      submitButton: ''
    }
  },
  setup() {
    const visible = ref <boolean> (false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = (e: MouseEvent) => {
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
    };

    return {
      visible,
      showModal,
      handleOk,
      handleCancel
    };
  },
  emits: ["update-dialog"],
  methods: {
    openDialog(title, text,submitButton) {
      this.showModal();
      this.title = ` ${title}`;
      this.textApp = text;
      this.submitButton = submitButton;
    },
    archiveObj() {
      this.$emit('update-dialog');
      this.handleOk(null);
    }
  }
});
