import {Field} from "@/types/fields";
import {GroupsListData} from "@/types/groups";

export const groupsFields: Field<GroupsListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "350px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
    // {field: "access", sortDirections: ['descend', 'ascend'], name: "Доступ", value: true, minWidth: "200px"},
    // {field: "path", sortDirections: ['descend', 'ascend'], name: "Путь", value: true, minWidth: "150px"},
    // {field: "clientRoles", sortDirections: ['descend', 'ascend'], name: "Роль", value: true, minWidth: "150px",
    //     compute: (val) => val === true ? 'Активна' : 'Неактивна'},
    // {
    //     field: "attributes", sortDirections: ['descend', 'ascend'], name: "Атрибуты", value: true, minWidth: "150px",
    //     compute: (val) => val && val.length > 0 ? val.map(v => v.name).join(', ') : ''
    // },
    // {
    //     field: "clientRoles", sortDirections: ['descend', 'ascend'], name: "Клиентские роли", value: true, minWidth: "150px",
    //     compute: (val) => val && val.length > 0 ? val.map(v => v.name).join(', ') : ''
    // },
    // {
    //     field: "realmRoles", sortDirections: ['descend', 'ascend'], name: "Реалм роли", value: true, minWidth: "150px",
    //     compute: (val) => val && val.length > 0 ? val.map(v => v.name).join(', ') : ''
    // },
    // {
    //     field: "subGroups", sortDirections: ['descend', 'ascend'], name: "Группы", value: true, minWidth: "150px",
    //     compute: (val) => val && val.length > 0 ? val.map(v => v.name).join(', ') : ''
    // }
];

export const getGroupsData = () => ({
    id: null,
    name: null,
    access: null,
    attributes: null,
    clientRoles: null,
    path: null,
    realmRoles: null,
    subGroups: null,
});
