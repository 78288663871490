
import { Field } from "../../types/fields";
import { Options, Vue } from "vue-class-component";
import draggable from "./dnd/draggable.js";
import { getRouteProps } from "@/helpers/route";
import { defineDtoObject, defineDtoSubject } from "@/helpers/defineDtoSubject";
import { takeWhile } from "rxjs/operators";
// TODO: фильтрация объектов
@Options({
  components: {
    draggable,
  },
  props: {
    fields: [] as Field<unknown>[],
    visible: {
      default: false,
    },
  },
  data: () => ({
    isVisible: false,
    search: '',
    subject$: null
  }),
  watch: {
    // `visible(value) => this.isVisible = value` could work too
    visible() {
      this.isVisible = this.$props.visible;
    },
    fields: {
      handler() {
        if(getRouteProps(this.$route)?.dto && this.fields) {
          localStorage.setItem(`${getRouteProps(this.$route)?.dto}`, JSON.stringify(this.fields))
        }
      },
      deep:true
    }
  },
  methods: {
    // getFields(){
    //   return this.fields
    //       .filter(field => field.name.toLowerCase().includes(this.search.toLowerCase()));
    // },
    handleVisibleChange(e1) {
      console.log(e1);
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.field);
    },
    onDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.items.find((item) => item.field == itemID);
      item.list = list;
    },
  },
})
export default class TableSettings extends Vue {}
