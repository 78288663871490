import {send} from "@/helpers/api";

export const AuthlogApi = {
    applyFilter(data: any, size = 10, page = 0): any {
        return send({
            url: `/skyv-mon/api/adminapi/events/login/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
}