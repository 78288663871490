import {send, withoutAPIUrl} from "@/helpers/api";

export const AuthService = {
    getCurrentUser() {
        return send({
            url: `${withoutAPIUrl}` + `/users/current-user`,
            method: "get",
        });
    },
    login(data: any) {
        return send({
            url: `${withoutAPIUrl}` + `/users/login/signin`,
            method: "post",
            data,
        });
    },
    updatePassword(data: any) {
        return send({
            url: `${withoutAPIUrl}` + `/users/set-temp-password-by-email`,
            method: "post",
            data,
            params: {
                userName: data
            }
        });
    }
}
