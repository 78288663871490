
import {Options, Vue} from "vue-class-component";
import {MetricIndicatorListData} from "@/types/metric-indicator";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import Accordion from "@/components/ui/Accordion.vue";
import MetricIndicatorList from "@/components/metrics-indicators/MetricIndicatorList.vue";
import {IndicatorApi} from "@/api/indicator.api";
import {getMetricIndicatorData, metricIndicatorFields} from "@/consts/fields/metrics-indicators.fields";
import {Field} from "@/types/fields";
import TableSettings from "@/components/base/TableSettings.vue";
import MetricsIndicatorsFilter from "@/components/metrics-indicators/MetricsIndicatorsFilter.vue";
import {MetricIndicatorFilterImp} from "@/components/classes/MetricIndicatorFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {indicatorListDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";

@Options({
  components: {
    TableSettings,
    Accordion,
    MetricIndicatorList,
    MetricsIndicatorsFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as MetricIndicatorListData[],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      page: 1,
      incidentKeys: "" as keyof MetricIndicatorListData,
      fields: null,
      filter: new MetricIndicatorFilterImp(),
      prepareData: getMetricIndicatorData()
    };
  },
  setup() {
    return {
      locale
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    handleClickOnRelateButton() {
      if(this.exceptKey === 'exceptMetricGroupId') {
        this.relateToMetricGroup();
      }
    },
    relateToMetricGroup() {
      return   IndicatorApi.relateToMetricGroup(this.$route.query.relatedId, this.selectedList).then(() => {
        this.$router.go(-1);
      })
    },
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      IndicatorApi
              .downloadExcel(filter)
              .then((res) => {
                this.$notification.open({
                  message: new TextDecoder().decode(res.data),
                  style: {
                    width: '600px',
                    marginLeft: `${400 - 600}px`,
                    fontSize: "14px",
                  },
                  placement: 'bottomRight',
                  duration: 3,
                });
              })
              .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix(el) {
      this.search = '';
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = '';
      if(value.columnKey === 'active') {
        column = 'activity'
      } else {
        column = value.columnKey;
      }
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddMetricsIndicators() {
      this.$router.push("/directory/metrics-indicators/add");
    },
    searchMethod(_) {
      return IndicatorApi
          .applyFilter2(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    indicatorListDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields = setVisibleToFieldsInTable(metricIndicatorFields, settings.fields) as Field<MetricIndicatorListData>[];
        })
  }
})
export default class MetricsIndicators extends Vue {
  list!: MetricIndicatorListData[];
  search: string;
  filter: MetricIndicatorFilterImp;
}
