import {Field} from "@/types/fields";
import { CollectionAgentListData } from "@/types/collection-agents";

export const collectionAgentFields: Field<CollectionAgentListData>[] = [
  {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "150px"},
  {field: "description", sortDirections: ['descend', 'ascend'], name: "Описание", value: true, minWidth: "150px"},
  {field: "adapterType", sortDirections: ['descend', 'ascend'], compute: val => val?.name, name: "Тип Агента", value: true, minWidth: "150px"},
  {field: "cron",  name: "Расписание запуска", value: true, minWidth: "150px"},
  {field: "deleted",sortDirections: ['descend', 'ascend'],  name: "Архивирован", value: true, minWidth: "150px",
    compute: (val) => val === true ? 'Да' : 'Нет'},
];

export const getCollectionAgentData = () => ({
  name: "",
  description: "",
  connectionString: "",
  username: "",
  password: "",
  cron: "",
  adapterType: {name: '', id: null},
  isActive: false,
  zabbix_host_groups: "",
  status: "",
  ip: "",
  port: "",
  deleted: "",
  properties: ""
});
