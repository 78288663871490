
  import { Options, Vue } from "vue-class-component";
  import { AuthService } from "@/api/auth";
  import { currentUser } from "@/consts/currentUser";
  import { tokenUser } from "@/consts/tokenUser";
  import { SystemApi } from "@/api/system.api";
  import qs from 'qs';
  import InfoDialog from "@/components/info-page/InfoDialog.vue";
  
  @Options({
    components: { InfoDialog },
    data: () => {
      return {
        username: '',
        password: "",
        showPassword: false,
        isLoad: false,
        name: '',
        bigLogoData: '',
        middleLogoData: '',
        color: '#F7F9FB',
        middleLogoExtension: '',
        forgotPasswordModalVisible: false,
        forgotPasswordEmail: '',
        copyright: ''
      };
    },
    methods: {
      signIn() {
        if (this.isLoad) return;
        this.isLoad = true;
        const user = {
          username: this.username,
          password: this.password,
        };
        AuthService.login(user).then(res => {
          tokenUser.next(res.token);
          localStorage.setItem('auth_token', res.token)
          AuthService.getCurrentUser().then(res => {
            currentUser.next(res);
            if(this.$route.query.redirectUrl) {
              const splintedRedirectUrl = this.$route.query.redirectUrl.split('?');
              this.$router.replace({
                path: splintedRedirectUrl[0],
                query: splintedRedirectUrl[1] ? qs.parse(splintedRedirectUrl[1]) : null
              });
            }else {
              this.$router.replace("/menu");
            }
              this.isLoad = false;
          });
        }).catch((err) => this.isLoad = false);
      },
      togglePassword() {
        this.showPassword = !this.showPassword;
      },
      getDefaultSystem() {
        SystemApi.getDefault().then((res) => {
          console.log(res.bigLogoName.slice(res.bigLogoName.indexOf('.') + 1));
          this.middleLogoExtension = this.getExtension(res.mediumLogoName);
          this.name = res.name;
          document.title = res.name;
          this.bigLogoData = res.bigLogo;
          this.middleLogoData = res.mediumLogo;
          this.color = res.color || '#F7F9FB';
          localStorage.setItem('smallLogo',res.smallLogo);
          localStorage.setItem('bigLogo', res.bigLogo);
          localStorage.setItem('name', res.name);
          localStorage.setItem('bigLogoExtension', this.getExtension(res.bigLogoName));
          localStorage.setItem('smallLogoExtension', this.getExtension(res.smallLogoName));
          localStorage.setItem('info', res.about);
        })
      },
      getExtension(name: string) {
        return name.slice(name.indexOf('.') + 1) === 'svg' ? 'svg+xml' : name.slice(name.indexOf('.') + 1);
      },
      showInfo() {
        this.$refs.info.openDialog();
      },
      forgotPassword() {
        this.forgotPasswordModalVisible = true;
      },
      cancelForgotPassword() {
        this.forgotPasswordModalVisible = false;
      },
      sendForgotPasswordEmail() {
        AuthService.updatePassword(this.forgotPasswordEmail)
            .then(() => {
              this.$notification.open({
                message: "Успешно",
                description: 'Информация о смене пароля отправлена на адрес учётной записи',
                style: {
                  width: "330px",
                  marginLeft: `${400 - 330}px`,
                  fontSize: "14px",
                },
                placement: 'bottomRight',
                duration: 3,
              });
              this.forgotPasswordModalVisible = false;
            })
      }
    },
    mounted() {
      console.log(this.$router);
      localStorage.clear();
      this.getDefaultSystem();
    },
  
  })
  export default class Login extends Vue {
    props: ['img']
  }
  