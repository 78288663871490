import {Field} from "@/types/fields";
import {ProcessTemplatesListData} from "@/types/process-templates";

export const processTemplatesFields: Field<ProcessTemplatesListData>[] = [
    {field: "key", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
    {field: "version", sortDirections: ['descend', 'ascend'], name: "Версия", value: true, minWidth: ""},
];

export const getProcessesTemplatesData = () => ({
    key: "",
    version: "",
});
