
import { Options, Vue } from "vue-class-component";
import {MonitoringObjectListData} from "@/types/monitoring-object";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import Accordion from "@/components/ui/Accordion.vue";
import MonitoringObjectsList from "@/components/monitoring-objects/MonitoringObjectsList.vue";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import {getMonitoringObjectsData, monitoringObjectsFields} from "@/consts/fields/monitoring-objects.fields";
import {Field} from "@/types/fields";
import TableSettings from "@/components/base/TableSettings.vue";
import MonitoringObjectsFilter from "@/components/monitoring-objects/MonitoringObjectsFilter.vue";
import {MonitoringObjectsFilterImp} from "@/components/classes/MonitoringObjectsFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {monitoringObjectListDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {
    TableSettings,
    Accordion,
    MonitoringObjectsList,
    MonitoringObjectsFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as MonitoringObjectListData[],
      currentUser,
      search: "",
      selectedList: [],
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      hierarchy: false,
      incidentKeys: "" as keyof MonitoringObjectListData,
      fields: null,
      filter: new MonitoringObjectsFilterImp(),
      prepareData: getMonitoringObjectsData()
    };
  },
  setup() {
    return {
      locale
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      }
    }
  },
  methods: {
    setHierarchy(value: boolean) {
      this.hierarchy = value;
      this.applyFilter('filter')
    },
    withoutAccess() {
      return !this.currentUser.value.userRoles.includes('nav_contracts')
    },
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      MonitoringObjectsApi
              .downloadExcel(filter)
              .then((res) => {
                this.$notification.open({
                  message: new TextDecoder().decode(res.data),
                  style: {
                    width: '600px',
                    marginLeft: `${400 - 600}px`,
                    fontSize: "14px",
                  },
                  placement: 'bottomRight',
                  duration: 3,
                });
              })
              .finally(() => (this.isLoadingExcel = false));
    },
    getSelectedMonitoringObject(e) {
      this.selectedList = e;
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix(el) {
      this.search = '';
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let column = value.columnKey;
      let order = '';
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    handleClickOnRelateButton() {
      if(this.exceptKey === 'exceptServiceId') {
        this.relateService();
        return;
      }
      if(this.exceptKey === 'exceptAreaId') {
        this.relateArea();
        return;
      }
      if(this.exceptKey === 'exceptMonitoringObjectId') {
        this.relateParent();
        return;
      }
      if(this.exceptKey === 'exceptContractId') {
        this.relateContract();
        return;
      }
    },
    routeToAddMonitoringObject() {
        this.$router.push("/monitoring/objects/add");
    },
    searchMethod(_) {
      const filter  = this.defineFilterData;
      filter[this.exceptKey] = this.$route.query.relatedId;
      filter.hierarchy = this.hierarchy
      console.log(filter , 'ПРОВЕРКА ФИЛЬТРА')
      return MonitoringObjectsApi
          .applyFilter2(filter, this.size, this.page);
    },
    relateService() {
      return MonitoringObjectsApi.relateMonitoringObjectToServices([+this.$route.query.relatedId], this.selectedList)
          .then(() => {
            this.$router.go(-1);
          });
    },
    relateArea() {
      return MonitoringObjectsApi.relateMonitoringObjectToArea(+this.$route.query.relatedId, this.selectedList)
          .then(() => {
            this.$router.go(-1);
          });
    },
    relateParent() {
      return MonitoringObjectsApi.addParentMonitoringObject(this.$route.query.relatedId, this.selectedList)
          .then(() => {
            this.$router.go(-1);
          });
    },
    relateContract() {
      return MonitoringObjectsApi.relateMonitoringObjectToContract(this.$route.query.relatedId, this.selectedList)
          .then(() => {
            this.$router.go(-1);
          });
    },
    resultMethod(res) {
      if(this.hierarchy) {
        this.list = res.data.data.map(_ => {
          return this.formatHierarchy(_);
        })
      }else {
        this.list = res.data.data;
      }
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
    formatHierarchy(data) {
      data.children = data?.childMonitoringObjects?.length ? data.childMonitoringObjects.map(_  => {
        return this.formatHierarchy(_);
      }) : null;
      return {...data, title: data.name, key: data.id, children: data.children};
    },
  },
  mounted() {
    monitoringObjectListDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            console.log(settings)
            let roleSettings = []
            if (currentUser.value.userRoles.includes('nav_contracts')) {
              roleSettings.push(settings.find(res=> res.field === 'id'))
              roleSettings.push(settings.find(res=> res.field === 'name'))
              roleSettings.push(settings.find(res=> res.field === 'isActive'))
              roleSettings.push(settings.find(res=> res.field === 'contractName'))
              roleSettings.push(settings.find(res=> res.field === 'contractCreateDate'))
              roleSettings.push(settings.find(res=> res.field === 'contractStart'))
              roleSettings.push(settings.find(res=> res.field === 'contractEnd'))
              console.log(roleSettings)
              this.fields  = roleSettings
              return;
            }
            this.fields = settings;
            return;
          }
          this.fields
              = setVisibleToFieldsInTable(monitoringObjectsFields, settings.fields) as Field<MonitoringObjectListData>[];
        })
  },
})
export default class MonitoringObjects extends Vue {
  list!: MonitoringObjectListData[];
  search: string;
  filter: MonitoringObjectsFilterImp;
}
