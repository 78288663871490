
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getContacts} from "@/components/utils/UtilInfo"
import {ExecutorUsersApi} from "@/api/executor-users.api";
import {getCalculationRuleData} from "@/consts/fields/calculation-rule.fields";

export default defineComponent({
  name: "CalculationRuleCharacteristics",
  mixins: [CharacteristicBase],
  mounted() {
    const query = this.$route.query
    if (query.relatedId) {
      this.data.metricTypeId = +query.relatedId
    }
    if (query.defaultValue) {
      this.data.defaultValue = query.defaultValue
    }
    if (query.metricIds) {
      this.data.metricIds = query.metricIds
    }
    if (query.timeCondition) {
      this.data.timeCondition = +query.timeCondition/60
    }
    if (query.typeAggregation) {
      this.data.typeAggregation = query.typeAggregation
    }
    if (query.typeInterpolation) {
      this.data.typeInterpolation = query.typeInterpolation
    }
    if (query.typeWorkTime) {
      this.data.typeWorkTime = query.typeWorkTime
    }
    if (query.formula) {
      this.data.formula = query.formula
    }


  },
  data: () => ({
    data: getCalculationRuleData(),
    leaderList: [],
    aggregationList: [
      {value: 'max', label: 'Максимум'},
      {value: 'min', label: 'Минимум'},
      {value: 'avg', label: 'Cреднее'},
      {value: 'sum', label: 'Сумма'}
    ],
    typeInterpolationList: [
      {value: 'default', label: 'Значение по умолчанию'},
      {value: 'prev', label: 'Предыдущее значение'}
    ],
    typeWorkTimeList: [
      {value: 'allTime', label: 'Вычисление производится постоянно'},
      {value: 'workTime', label: 'Вычисление производится только в рабочие часы'}
    ],
    validationFields:[
      {field: "varRuleType", validators: ["required"]},
    ],
    ruleTypeList: [
      {name: 'Нормирование', value: 'Ration'},
      {name: 'Вычисление по иерархии', value: 'Hierarchy'},
      {name: 'Вычисление по формуле', value: 'Formula'},
    ],
    varRuleType: ''
  }),
  methods: {

    // changeAuto(event?){
    //   if(!event) {
    //     this.clean();
    //   }
    // },
    // clean() {
    //   this.data.leader = {fio: "", id: 0, name: ""};
    // },
    getName(e){
     let result = this.ruleTypeList.find((res) => {
        return res.value === e
      })
      return result.name
    },
    setRuleType(e, option){
      this.data.typeRule = option.id
      this.data[option.field] = option;
      this.$emit("select", { option });
      return;
    }
  },
  watch: {
    data: {
      handler() {
        if (this.data.typeRule) {
          console.log(123)
          this.varRuleType = this.getName(this.data.typeRule)
        }
      },
      deep: true
    },
  },
})
