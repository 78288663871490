
import {defineComponent} from "vue";
import ListBase from "@/classes/ListBase.vue";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {ServiceApi} from "@/api/service.api";

export default defineComponent({
  components: {DeleteDialog},
  mixins: [ListBase],
  data: () => ({
    selectedId: ''
  }),
  methods: {
    deleteRelatedService() {
      if (this.typeRelate === 'monitoring-objects') {
        MonitoringObjectsApi.deleteMonitoringObjectService(this.relatedId, this.selectedId)
            .then(() => {
          this.onChange(this.currentPage, this.currentSize);
        });
        return;
      }
      if (this.typeRelate === 'area') {
        ServiceApi.deleteAreaService(this.selectedId, this.relatedId)
            .then(() => {
              this.onChange(this.currentPage, this.currentSize);
            });
        return;
      }
    },
    openDeleteDialog(id, name) {
      this.selectedId = id;
      this.$refs.delete.openDialog("связи", `связь с услугой ${name}`);
    }
  }
});
