import {send, url} from "@/helpers/api";
import { Observable } from "rxjs/dist/types";
import Axios from "axios-observable";

export const CollectionAgentsApi = {
  applyFilter(data: any , size = 10 , page = 0): any {
    return send({
      url: `${url}` + `/monitoring-system/filter`,
      method: "post",
      data: data,
      params: {
        page: page.toString(),
        size: size.toString(),
      },
    })
  },
  applyFilter2(data, size = 10, page = 0): Observable<any> {
    return Axios.request({
      url: `${url}/monitoring-system/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getById(id) {
    return send({
      url: `${url}` + `/monitoring-system/${id}`,
      method: "get",
    });
  },
  delete(id) {
    return send({
      url: `${url}` + `/monitoring-system/${id}`,
      method: "delete",
    });
  },
  archive(id) {
    return send({
      url: `${url}` + `/monitoring-system/?id=${id}`,
      method: "delete",
    });
  },
  unArchive(id) {
    return send({
      url: `${url}` + `/monitoring-system/?id=${id}`,
      method: "post",
    });
  },
  save(data) {
    return send({
      url: `${url}` + `/monitoring-system/save`,
      method: "post",
      data
    });
  },
  getAdapterType() {
    return send({
      url: `${url}` + `/classifiers/adapter-types`,
      method: "get",
    });
  },
  getSchedulerRunEvent(data, size = 10 , page = 0) {
    return send({
      url: `${url}` + `/monitoring-system/scheduler-run-event/filter`,
      method: "post",
      params: {
        page: page.toString(),
        size: size.toString(),
      },
      data
    });
  },
  agentStart(data) {
    return send({
      url: `${url}` + `/monitoring-system/agent/start?agentId=${data.agentId}`,
      method: "post",
      data
    });
  },
  agentStop(data) {
    return send({
      url: `${url}` + `/monitoring-system/agent/stop?agentId=${data.agentId}`,
      method: "post",
      data
    });
  }
}
