import {Field} from "@/types/fields";
import {MeasureListData} from "@/types/measure";

export const measureFields: Field<MeasureListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "50px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "140px"},
    {field: "scale", sortDirections: ['descend', 'ascend'], name: "Шкала", value: true, minWidth: "100px"},
    {field: "description", sortDirections: ['descend', 'ascend'], name: "Описание", value: true, minWidth: "100px"},
    {field: "measureType", name: "Категория", value: true, minWidth: "100px", compute: (val) => val ? val.name : ''},
]
export const getMeasureData = () => ({
    id: null,
    name: '',
    scale: '',
    description: '',
    measureType: {name: ''}
})