
import {Options, Vue} from "vue-class-component";
import {MetricGroupListData} from "@/types/metric-group";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import Accordion from "@/components/ui/Accordion.vue";
import {Field} from "@/types/fields";
import {reactive} from 'vue'
import TableSettings from "@/components/base/TableSettings.vue";
import NSIBase from "@/classes/NSIBase.vue";
import {getMetricLimitData, metricLimitFields} from "@/consts/fields/metric-limit.fields";
import {MetricLimitListData} from "@/types/metric-limit";
import {MetricLimitFilterImp} from "@/components/classes/MetricLimitFilterImp";
import {MetricLimitApi} from "@/api/metric-limit.api";
import MetricLimitList from "@/components/metric-limits/MetricLimitList.vue";
import MetricLimitsFilter from "@/components/metric-limits/MetricLimitsFilter.vue";

@Options({
  components: {
    TableSettings,
    Accordion,
    MetricLimitList,
    MetricLimitsFilter,
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as MetricLimitListData[],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      /*TODO: dynamic fields*/
      fields: reactive(metricLimitFields.slice() as Field<MetricLimitListData>[]),
      filter: new MetricLimitFilterImp(),
      prepareData: getMetricLimitData()
    };
  },
  setup() {
    return {
      locale
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      MetricLimitApi
          .downloadExcel(filter)
          .then((res) => {
            this.$notification.open({
              message: new TextDecoder().decode(res.data),
              style: {
                width: '600px',
                marginLeft: `${400 - 600}px`,
                fontSize: "14px",
              },
              placement: 'bottomRight',
              duration: 3,
            });
          })
          .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix(el) {
      this.search = '';
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddMetricsGroups() {
      this.$router.push("/directory/metric-limits/add");
    },
    searchMethod(_) {
      const filter = this.defineFilterData;
      return MetricLimitApi
          .applyFilter2(filter, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    this.applyFilter();
  },
})
export default class MetricLimits extends Vue {
  list!: MetricLimitListData[];
  search: string;
  filter: MetricLimitFilterImp;
}
