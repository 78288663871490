import { Field } from "@/types/fields";
import {MetricListData} from "@/types/metric";

export const metricFields: Field<MetricListData>[] = [
  {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "100px"},
  {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "140px"},
  {field: "active", sortDirections: ['descend', 'ascend'], name: "Активность", value: true, minWidth: "100px",
    compute: (val) => val === true ?  'Да' : 'Нет'},
  {field: "isCalculated", sortDirections: ['descend', 'ascend'], name: "Вычисляемая", value: true, minWidth: "100px",
    compute: (val) => val === true ?  'Да' : 'Нет'},
  {field: "key", sortDirections: ['descend', 'ascend'], name: "Ключ", value: true, minWidth: "120px"},
  {field: "description", sortDirections: ['descend', 'ascend'], name: "Описание", value: false, minWidth: ""},
  {field: "aggrType", sortDirections: ['descend', 'ascend'], name: "Агрегация", value: false, minWidth: ""},
  {field: "measure", sortDirections: ['descend', 'ascend'], name: "Единица измерения", value: true, minWidth: "120px"},
  // {field: "displayUnit", sortDirections: ['descend', 'ascend'], name: "Единица отображения", value: true, minWidth: "120px"},
  {field: "monitoringSystemName", sortDirections: ['descend', 'ascend'], name: "Система мониторинга", value: false, minWidth: "120px"},
  {field: "limitValue", sortDirections: ['descend', 'ascend'], name: "Пороговое значение", value: true, minWidth: "120px"},
  {field: "snmpOid", sortDirections: ['descend', 'ascend'], name: "SNMP OID", value: true, minWidth: "120px"},
  {field: "metricGroups", name: "Группы метрики", value: true, minWidth: "200px",
    compute: (val) => val && val.length > 0 ? val.map(v => v.name).join(', ') : ''},
];
export const getMetricTypeData = () => ({
  id: null,
  active: false,
  aggrType: "",
  description: "",
  key: "",
  limitValue: "",
  measure: {
    name: '',
    id: null
  },
  displayUnit: {
    name: '',
    id: null
  },
  monitoringSystemId: "",
  monitoringSystemName: "",
  name: "",
  testKpd: true,
  metricCategory: {
    name: '',
    id: null
  },
  isCalculated: null,
  snmpOid: {
    name: "",
    id: null
  },
});
