
import { defineComponent } from "vue";
import {SupportTeamApi} from "../../api/supportTeam.api";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import CalculationRuleCharacteristics from "@/components/calculation-rules/CalculationRuleCharacteristics.vue";
import {CalculationRuleApi} from "@/api/calculationRule.api";
import ArchiveDialog from "@/components/dialogs/ArchiveDialog.vue";
import {ServiceApi} from "@/api/service.api";

export default defineComponent({
  name: "CalculationRulesView",
  components: {CalculationRuleCharacteristics, EditForm, DeleteDialog, ArchiveDialog},
  mixins: [ViewBase],
  data: () => ({
    body: {},
    count: 0
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openArchiveDialog(){
      if (this.body.isActive) {
        this.$refs.archive.openDialog("Деактивировать правило",
            `деактивировать правило ${this.body.typeRule}` , "Деактивировать");
      }
      else {
        this.$refs.archive.openDialog("Активировать правило ",
            `активировать правило ${this.body.typeRule} `, "Активировать");
      }
    },
    activatedObject() {
      if (this.body.isActive) {
        CalculationRuleApi.deactivated(this.id).then(() => {
          this.getById();
          this.$notification.open({
            message: "Правило деактивированно",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        });
      }
      else {
        CalculationRuleApi.activated(this.id).then(() => {
          this.getById();
          this.$notification.open({
            message: "Правило активировано",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        });
      }
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("правила расчета", `правило расчета ${this.body.id}`);
    },
    getById() {
      CalculationRuleApi.getById(this.id).then((res) => {
        res.timeCondition = res.timeCondition/60
        this.body = res;
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
        this.count++
        if (this.body.wasActivated && this.count == 1) {
          this.$notification.open({
            message: "Внесение изменений невозможно, так как правило было активировано ранее",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        }
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      console.log(characteristics);
      characteristics.metricTypeId = +characteristics.metricTypeId
      CalculationRuleApi.save(
          characteristics
      ).then(() => {
        this.getById();
      });
    },
    deleteObject() {
      CalculationRuleApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  },
});
