
import {defineComponent, PropType} from "vue";
import FilterBase from "@/classes/FilterBase.vue";
import {Field} from "@/types/fields";
import {CalculationRulesHistoryFilterImp} from "@/components/classes/CalculationRulesHistoryFilterImp";
import {CalculationRulesFilterImp} from "@/components/classes/CalculationRulesFilterImp";
import moment from "moment";

export default defineComponent({
  name: "CalculationRulesHistoryFilter",
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter();
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    handleChangeTime (ev) {
      console.log(ev)
      console.log(321)
      if (ev) {
        this.setQueryParams();
        this.applyFilter('filter')
      }
      else {
        this.value = ''
        this.filter.date = ''
        this.setQueryParams();
        this.applyFilter('filter')
      }

    },
    applyFilter() {
      this.filterChange(new CalculationRulesHistoryFilterImp());
      if (this.value) {
        this.filter.date = moment(this.value).format("DD.MM.YYYY 00:00:00");
      }
      console.log(321)
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, ['calculationTypeRule']);
      this.setQueryParamsArrDefault(queryParams, []);
      this.$router.push({query: queryParams});
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['id']);
      this.setCheckboxesArrFromQuery(query, []);

    },
    cleanFilter() {
      this.filter = new CalculationRulesHistoryFilterImp();
      this.defaultValueFilter();
      this.setFilterFields();
      this.filterChange(new CalculationRulesHistoryFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if (f.field === 'calculationTypeRule') {
              this.filter.calculationTypeRule = '';
            }
            if (f.field === 'date') {
              this.filter.date = '';
            }
          });
    },
  },
  mounted() {
    console.log(123)
    this.defaultValueFilter();
    this.getQueryParams();
    this.setFilterFields();
    this.applyFilter();
  },
  data: () => ({
    filter: new CalculationRulesHistoryFilterImp(),
    filterData: {},
    value: ''
  }),

})
