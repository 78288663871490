
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getBrandsData} from "@/consts/fields/brands.fields";

export default defineComponent({
  name: "BrandCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getBrandsData(),
    validationFields:[
      {field: "name", validators: ["required"]},
    ],
    brandList: [],
    typeList: [],
  }),
  mounted() {
    // this.getBrandList();
    // this.getTypeList();
    this.getCRUDSettings(100)
  },
  methods: {
    clean(){
      this.data = getBrandsData();
    },
    onSelectBrand(value, option) {
      const key = "id";
      this.data[option.field][key] = option[key];
    },
  }
})
