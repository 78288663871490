
import {Options, Vue} from "vue-class-component";
import NSIBase from "@/classes/NSIBase.vue";
import {NoticeTemplateListData} from "@/types/notice-template";
import {ServiceFilterImp} from "@/components/classes/ServiceFilterImp";
import TableSettings from "@/components/base/TableSettings.vue";
import Accordion from "@/components/ui/Accordion.vue";
import {Field} from "@/types/fields";
import {MonitoringTemplateListData} from "@/types/monitoring-templates";
import {MonitoringTemplateFilterImp} from "@/components/classes/MonitoringTemplateFilterImp";
import {getMonitoringTemplateData, monitoringTemplateFields} from "@/consts/fields/monitoring-template.fields";
import {MonitoringTemplateApi} from "@/api/monitoring-template.api";
import MonitoringTemplateList from "@/components/monitoring-templates/MonitoringTemplateList.vue";
import MonitoringTemplateFilter from "@/components/monitoring-templates/MonitoringTemplateFilter.vue";
import {reactive} from "vue";

@Options({
  components: {MonitoringTemplateFilter, MonitoringTemplateList, TableSettings, Accordion},
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as MonitoringTemplateListData[],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof MonitoringTemplateListData,
      fields: reactive(monitoringTemplateFields.slice() as Field<MonitoringTemplateListData>[]),
      filter: new MonitoringTemplateFilterImp(),
      prepareData: getMonitoringTemplateData(),
      selectedList: []
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
    }
  },
  mounted() {
    this.applyFilter();
  },
  methods: {
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix(el) {
      this.search = '';
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if (from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    resultMethod(res) {
      this.list = res.data;
      this.totalCount = res.totalCount;
      this.isLoad = true;
    },
    routeToAddMonitoringTemplate() {
      this.$router.push("/settings/monitoring-templates/add");
    },
    searchMethod(_) {
      return MonitoringTemplateApi
          .applyFilter(this.defineFilterData, this.size, this.page);
    },
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
    },
  },
})
export default class MonitoringTemplates extends Vue {
  list!: MonitoringTemplateListData[];
  search: string;
  filter: MonitoringTemplateFilterImp ;
}
