import {send, url} from "@/helpers/api";
import Axios from "axios-observable";
import {Observable} from "rxjs/dist/types";
import axios from "axios";

export const SupportTeamApi = {
    applyFilter(data:any, size = 10, page = 0): any {
        return send({
            url: `${url}` + `/support-teams/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    applyFilter2(data: any, size = 25, page = 0): Observable<any> {
        return Axios.request({
            url: `${url}` + `/support-teams/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/support-teams/${id}`,
            method: "get",
        });
    },
    deleteAdditionalContact(id) {
        return send({
            url: `${url}` + `/support-teams/contact?additionalContactId=${id}`,
            method: "delete",
        });
    },
    addAdditionalContact(data) {
        return send({
            url: `${url}` + `/support-teams/contact`,
            method: "post",
            data,
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/support-teams/${id}`,
            method: "delete",
        });
    },
    save(data) {
        return send({
            url: `${url}` + `/support-teams`,
            method: "post",
            data,
        });
    },
    downloadExcel(request) {
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        return axios({
            method: "post",
            url: `${url}/support-teams/download?zoneId=${zoneId}`,
            data: request,
            responseType: "arraybuffer",
            headers: {
                "Accept": "application/octet-stream",
            }
        });
    },
    addExecutorUser(supportTeamId: number, users: string[]) {
        return send({
            url: `${url}` + `/support-teams/users`,
            method: "post",
            data: {
                supportTeamId,
                users
            },
        });
    },
    addMonitoringObjectTypes(supportTeamId: number, monitoringObjectTypes: string[]) {
        return send({
            url: `${url}` + `/support-teams/monitoring-object-type`,
            method: "post",
            data: {
                supportTeamId,
                monitoringObjectTypes
            },
        });
    },
    deleteMonitoringObjectType(monitoringObjectType: string) {
        return send({
            url: `${url}` + `/support-teams/monitoring-object-type`,
            method: "post",
            data: {
                supportTeamId: null,
                monitoringObjectTypes: [monitoringObjectType]
            },
        });
    }
}
