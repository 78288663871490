
import {defineComponent} from "vue";
import {AreaTypesApi} from "../../api/area-types.api";
import AreaTypesCharacteristics from "@/components/area-types/AreaTypesCharacteristics.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "AreaTypesView",
  components: { AreaTypesCharacteristics, DeleteDialog },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    currentUser
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getByName();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("типа местоположения", `тип местоположения ${this.body.name}`);
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    getByName() {
      this.body = {name: this.id};
      suffix.next(' ' + this.body.name);
      setTimeout(() => {
        this.$refs.characteristics.cleanWarning();
      }, 1000);
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristics();
      characteristics.oldName = this.id;
      characteristics.newName = characteristics.name;
      this.id = characteristics.newName +'';
      delete characteristics.name;
      delete characteristics.defaultName;
      AreaTypesApi.edit(
          characteristics
      ).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.getByName();
      });
    },
    deleteObject() {
      AreaTypesApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  },
});
