
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getServiceData} from "@/consts/fields/services.fields";
import {AreaApi} from "@/api/area.api";
import {OperatorsApi} from "@/api/operators.api";
import {ClassifilesApi} from "@/api/classifires.api";
import {ServiceApi} from "@/api/service.api";
import {ContractsApi} from "@/api/contracts.api";
import { defineTextColorByStatus } from "@/helpers/define-status-color";

export default defineComponent({
  name: "ServiceCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getServiceData(),
    statusList: [],
    areaList: [],
    contractList: [],
    lastMileList: [],
    orderNWfprocessList: [],
    orderNWfstatusList: [],
    speedMeasureList: [],
    channelTypeList: [],
    validationFields:[
      {field: "orderN", validators: ["required"]},
      {field: "channelType.name", validators: ["required"]},
    ],
  }),
  mounted() {
    this.getAreaList();
    this.getStatusList();
    this.getContractList();
    // this.getLastMileList();
    // this.getOrderNWfprocessList();
    // this.getOrderNWfstatusList();
    this.getChannelType();
    this.getSpeedMeasure();
    if(this.data.connectSpeed) {
      this.customValidation('connectSpeed', this.data.connectSpeed)
    }
    this.getCRUDSettings(51)
  },
  methods: {
    defineTextColorByStatus,
    clean(){
      this.data.status = { id: null, name: "" };
      this.data.orderNWfprocess = { id: null, name: "" };
      this.data.orderNWfstatus = { id: null, name: "" };
      // this.data.lastMileOperator = { id: null, name: "" };
      this.data.area = { id: null, name: "" };
      this.data.contract = { id: null, name: "" };
      this.data = getServiceData();
    },
    getAreaList() {
      AreaApi.applyFilter({name: this.data.area?.name}, 10, 0).then((res) => {
        this.areaList = res.data;
      });
    },
    getStatusList() {
      ServiceApi.getStatuses()
          .then(res => {
            this.statusList = res;
          })
    },
    getContractList() {
      ContractsApi.applyFilter({number: this.data.contract.number ? this.data.contract.number : ''})
          .then(res => {
            this.contractList = res.data;
          })
    },
    // getLastMileList() {
    //   OperatorsApi.applyFilter({name: this.data.lastMileOperator?.name}, 10, 0)
    //       .then(res => {
    //         this.lastMileList = res.data;
    //       })
    // },
    // getOrderNWfprocessList() {
    //   ClassifilesApi.getOrderNWfprocess({}, 10, 0)
    //       .then(res => {
    //         this.orderNWfprocessList = res.data;
    //       })
    // },
    // getOrderNWfstatusList() {
    //   ClassifilesApi.getOrderNWfstatus({name: this.data.orderNWfstatus.name}, 10, 0)
    //       .then(res => {
    //         this.orderNWfstatusList = res.data;
    //       })
    // },
    getChannelType() {
      ServiceApi.getChannelTypes()
          .then(res => {
            this.channelTypeList = res;
          })
    },
    getSpeedMeasure() {
      ClassifilesApi.getSpeedMeasure()
              .then(res => {
                console.log(res)
                this.speedMeasureList = res;
              })
    },
    onSelectSpeedMeasure(value, option) {
      console.log(option)
      delete option.field
      this.data.measure = option;
    },
    customValidation(field = "", event?, validationField?) {
      if(field !== 'connectSpeed' && field !== "measure.name") {
        return false;
      }
      let data;

      if (!validationField) {
        validationField = this.validationFields.find((_) => _.field === field);
      }

      if (event && event.target) {
        data = event.target.value;
      }
      if (typeof event === "string" || typeof event === "number") {
        data = event;
      }
      if(this.data.connectSpeed) {
        this.validationFields.push({field: 'measure.name', touched: false, validators: []})
      }
      if((field === 'measure.name' && this.data.connectSpeed)
        || (field === 'connectSpeed' && !this.data.measure)
        || (this.data.connectSpeed && !this.data.measure)) {
        if(!validationField) {
          this.validationFields.push({field: 'measure.name', touched: false, validators: []});
        }
        if(this.data.connectSpeed && !this.data.measure) {
          this.errors.push({field: 'measure.name', touched: false, validators: []})
        }
        if (this.formTouched && this.hasErrors('measure.name')) {
          this.setHasErrorClass('measure.name');
        }
        return true;
      }
      this.validationFields = this.validationFields.filter(validationField => validationField.field !== field);
      this.errors = this.errors.filter(validationField => validationField.field !== field);
      this.removeHasErrorClass('measure.name')
      return false;
    },
  }
})
