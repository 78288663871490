
import {Options, Vue} from "vue-class-component";
import NSIBase from "@/classes/NSIBase.vue";
import MeasureList from "@/components/measure/MeasureList.vue";
import {MeasureListData} from "@/types/measure";
import {reactive} from "vue";
import {getMeasureData, measureFields} from "@/consts/fields/measure.fields";
import {Field} from "@/types/fields";
import {MeasureFilterImps} from "@/components/classes/MeasureFilterImps";
import TableSettings from "@/components/base/TableSettings.vue";
import MeasureFilter from "@/components/measure/MeasureFilter.vue";
import {CollectionAgentsApi} from "@/api/collection-agents.api";
import {MeasureApi} from "@/api/measure.api";
import Accordion from "@/components/ui/Accordion.vue";
import {brandDtoSettings$, measureDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";
import {brandsFields} from "@/consts/fields/brands.fields";
import {BrandsListData} from "@/types/brands";

@Options({
  components: {
    MeasureFilter,
    MeasureList,
    TableSettings,
    Accordion,
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as MeasureListData[],
      search: "",
      selectedList: [],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof MeasureListData,
      fields: null,
      filter: new MeasureFilterImps(),
      prepareData: getMeasureData()
    }
  },
  methods: {
    handleClickOnRelateButton() {
      // if(this.exceptKey === 'exceptUserId') {
      //   this.relateGroups();
      //   return;
      // }
    },
    routeToAddMeasure() {
      this.$router.push("/directory/measure/add");
    },
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;

      MeasureApi
        .downloadExcel(filter)
        .then((res) => {
          this.$notification.open({
            message: new TextDecoder().decode(res.data),
            style: {
              width: '600px',
              marginLeft: `${400 - 600}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        })
        .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    updateList(value) {
      console.log(value);
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    searchMethod(_) {
      return MeasureApi
          .applyFilter(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      console.log(res)
      this.list = res.data;
      this.totalCount = res.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    measureDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields = setVisibleToFieldsInTable(measureFields, settings.fields) as Field<MeasureListData>[];
        });
  },
})
export default class Measure extends Vue {
  search: string;
  list!: MeasureListData;
  filter: MeasureFilterImps;
}
