
import { defineComponent } from "vue";
import EditForm from "@/components/base/EditForm.vue";
import ViewBase from "@/classes/ViewBase.vue";
import { MonitoringTemplateApi } from "@/api/monitoring-template.api";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

import {
  LoadingOutlined,
} from '@ant-design/icons-vue';
import { suffix } from "@/consts/nameSuff";

export default defineComponent({
  components: {
    EditForm,
    DeleteDialog,
    LoadingOutlined
  },
  data: () => ({
    monitoringTemplate: [],
    data: [],
    checkedKeys: [],
    name: '',
    description: '',
    currentMonitoringTemplateData: {
      name: ''
    },
    editModalOpen: false,
    openPages: [],
  }),
  mixins: [ViewBase],
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeUnmount() {
    suffix.next(null);
  },
  methods: {
    expand(expanded, record) {
      const id = record.id;
      if (expanded) {
        console.log(this.openPages)
        if (!this.openPages.includes(id)) {
          console.log(this.openPages)
          const set = new Set(this.openPages);
          set.add(id);
          this.openPages = [...set];
          console.log(this.openPages)
        }
      } else {
        this.openPages.splice(this.openPages.indexOf(id), 1);
      }
    },
    setOpenPages(list) {
      list.forEach(l => {
        this.openPages.push(l.id);
        if (l.children) {
          this.setOpenPages(l.children);
        }
      });
    },
    handleVisibleChangeTooltip(visibleState, id) {
      if (!visibleState) {
        return;
      }
      this.currentMonitoringTemplateData = {};
      MonitoringTemplateApi.getDataById(id)
        .then(res => {
          this.currentMonitoringTemplateData = res;
        })
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog(
        "шаблона мониторинга",
        `шаблон мониторинга ${this.name}`
      );
    },
    getById() {
      this.isLoad = false;
      MonitoringTemplateApi.getById(this.id)
        .then(res => {
          this.monitoringTemplate = res.data.map(_ => {
            return this.formatHierarchy(_);
          });
          this.description = res.description;
          this.name = res.name;
          this.resetCheckedKeys();
          this.isLoad = true;
          this.setOpenPages( this.monitoringTemplate)
          suffix.next(' ' + res.name);
        })
    },
    resetCheckedKeys() {
      this.checkedKeys = ['0-0'];
    },
    save() {
      if (this.checkedKeys = ['0-0']) {
        this.data = this.getAllMonitoringTds();
      }
      MonitoringTemplateApi.save({
        id: this.id,
        name: this.name,
        description: this.description,
        data: this.data
      })
        .then(res => {
          this.$notification.open({
            message: "Сохранено",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: "bottomRight",
            duration: 3,
          });
          this.getById();
        })
    },
    getAllMonitoringTds() {
      let ids = [];
      const loop = (children) => {
        children.map(_ => {
          ids.push(_.id)
          if (!!_.children) {
            loop(_.children)
          }
        })
      }
      loop(this.monitoringTemplate)
      return ids;
    },
    handleCheckTreeEvent(e, ev) {
      this.data = [];
      ev.checkedNodes.forEach(({ props }) => {
        this.data.push(props.id);
      })
    },
    deleteObject() {
      MonitoringTemplateApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    openEditModal(item) {
      this.editModalOpen = true; 
      this.handleVisibleChangeTooltip(true, item.id);
    },
    saveEditSnmp() {
      MonitoringTemplateApi.editSnmp(this.currentMonitoringTemplateData)
        .then(() => {
          this.getById()
          this.editModalOpen = false;
        })
    },
    deleteSnmp() {
      MonitoringTemplateApi.deleteSnmp(this.currentMonitoringTemplateData.id)
        .then(() => {
          this.getById()
          this.editModalOpen = false;
        })
    },
    formatHierarchy(data) {
      data.children = data?.children?.length  ? data.children.map(_  => {
        return this.formatHierarchy(_);
      }) : null;
      return {...data, key: data.id, id: data.id, children:data.children};
    },
  },
  computed: {
    formatCurrentMonitoringTemplateData() {
      return Object.keys(this.currentMonitoringTemplateData).map(key => `${key}: ${this.currentMonitoringTemplateData[key]}`).join('<br>')
    }
  }
})
