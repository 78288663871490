import {send, url} from "@/helpers/api";

export const FileManagerApi = {
    applyFilter(data: any, size = 10, page = 0): any {
        return send({
            url: `/skyv-mon/api/file-manager/file/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    delete(fileId) {
        return send({
            url: `${url}` + `/file-manager/file/${fileId}`,
            method: "delete",
        });
    },
    deleteFileStorage(fileId) {
        return send({
            url: `${url}` + `/file-manager/file/${fileId}/file-storage`,
            method: "delete",
        });
    },
}
