import {send, url, urlAdmin} from "@/helpers/api";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";

export const HealthModelRuleApi = {
    applyFilter(data: any, size = 10, page = 0): any {
        return send({
            url: `/skyv-mon/api/healthmodel/templates/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    applyFilter2(data: any, size = 10, page = 0): Observable<any>  {
        return Axios.request({
            url: `/skyv-mon/api/healthmodel/templates/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    save(data: any) {
        return send({
            url: `${url}` + `/healthmodel/templates/save`,
            method: "post",
            data,
        });
    },
    saveRule(data: any) {
        return send({
            url: `${url}` + `/healthmodel/templates/rules/save`,
            method: "post",
            data,
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/healthmodel/templates/${id}`,
            method: "get",
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/classifiers/calculation-rule/${id}`,
            method: "delete",
        });
    },
    deleteHealthModel(id) {
        return send({
            url: `${url}` + `/healthmodel/templates/${id}`,
            method: "delete",
        });
    },
    deleteRule(id) {
        return send({
            url: `${url}` + `/healthmodel/templates/rule/${id}`,
            method: "delete",
        });
    },
    getCharacteristicsField() {
        return send({
            url: `${url}/view-settings?dtoObjectClass=MonitoringObjectListDto&onlyWithUrl=true`,
            method: "get",
        });
    },
    getProperty(urls) {
        return send({
            url: `${url}` + `${urls}`,
            method: "get",
        });
    },
    getPropertyPost(data , urls , size) {
        return send({
            url: `${url}` + `${urls}`,
            method: "post",
            data,
            params: {
                size: size.toString(),
            },
        });
    },
    getHealthModelRules(data: any, size = 10, page = 0) {
        return send({
            url: `/skyv-mon/api/healthmodel/templates/rules/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getRuleById(id) {
        return send({
            url: `${url}` + `/healthmodel/templates/rules/${id}`,
            method: "get",
        });
    },
    getMetricForRule(id) {
        return send({
            url: `${url}` + `/healthmodel/templates/${id}/metric-limits`,
            method: "get",
        });
    },
    getLimitList() {
        return send({
            url: `${url}` + `/classifiers/metric-limit-names`,
            method: "get",
        });
    },
    getRefreshList(data: any, size = 10, page = 0) {
        return send({
            url: `/skyv-mon/api/monitoring-object/healthmodel-template/filter?healthModelTemplateId=${data.healthModelTemplateId}`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    acceptRefreshList(data){
        return send({
            url: `/skyv-mon/api/monitoring-object/healthmodel-template/apply`,
            method: "post",
            data,
        });
    },
    getRuleActionType() {
        return send({
            url: `${url}` + `/healthmodel/rule-action-type`,
            method: "get",
        });
    }
}
