
import {defineComponent} from "vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ListBase from "@/classes/ListBase.vue";

export default defineComponent(
    {
      components: {DeleteDialog},
      mixins: [ListBase],
      methods: {
        openDeleteDialog(id, name) {
          this.selectedId = id;
          this.$refs.delete.openDialog("связи", `связь с контрагентом ${name}`);
        },
      }
    }
)
