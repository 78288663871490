import {send, url} from "@/helpers/api";
import {Observable} from "rxjs";
import Axios from "axios-observable";


export const ContractsApi = {
    applyFilter(data: any, size = 10, page = 0) {
        return send({
            url: `${url}` + `/contracts/filter`,
            method: 'post',
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        })
    },
    applyFilter2(data: any, size = 10, page = 0): Observable<any>{
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        return Axios.request({
            url: `${url}` + `/contracts/filter?zoneId=${zoneId}`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/contracts/${id}`,
            method: "get",
        });
    },
    save(data: any) {
        return send({
            url: `${url}` + `/contracts`,
            method: "post",
            data,
        });
    },
    edit(data: any) {
        return send({
            url: `${url}` + `/contracts`,
            method: "post",
            data,
        });
    },
    delete(id: string) {
        return send({
            url: `${url}` + `/contracts/${id}`,
            method: "delete"
        });
    },
    deleteCounterpartyFromContracts(contractId, counterpartyId) {
        return send({
            url: `${url}` + `/contracts/${contractId}/counterparty/${counterpartyId}`,
            method: "delete",
        });
    },
    deleteDepartmentsFromContracts(contractId, departmentId) {
        return send({
            url: `${url}` + `/contracts/${contractId}/department/${departmentId}`,
            method: "delete",
        });
    },
    relateCounterpartyToContract(relatedId, selectedList) {
        return send({
            url: `${url}` + `/contracts/counterparties`,
            method: "post",
            data: {
                contractId: relatedId,
                counterparties: selectedList
            }
        })
    },
    relateDepartmentsToContract(relatedId, selectedList) {
        return send({
            url: `${url}` + `/contracts/departments `,
            method: "post",
            data: {
                contractId: relatedId,
                departments: selectedList
            }
        })
    },
}
