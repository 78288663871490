import {send, url} from "@/helpers/api";
import Axios from "axios-observable";
import {Observable} from "rxjs/dist/types";
import axios from "axios";

export const CalculationRuleApi = {
    applyFilter(data:any, size = 10, page = 0): any {
        return send({
            url: `${url}` + `/classifiers/calculation-rule/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    applyFilter2(data: any, size = 25, page = 0): Observable<any> {
        return Axios.request({
            url: `${url}` + `/classifiers/calculation-rule/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/classifiers/calculation-rule/${id}`,
            method: "get",
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/classifiers/calculation-rule/${id}`,
            method: "delete",
        });
    },
    save(data) {
        return send({
            url: `${url}` + `/classifiers/calculation-rule/save`,
            method: "post",
            data,
        });
    },
    downloadExcel(request) {
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        return axios({
            method: "post",
            url: `${url}/classifiers/calculation-rule-download?zoneId=${zoneId}`,
            data: request,
            responseType: "arraybuffer",
            headers: {
                "Accept": "application/octet-stream",
            }
        });
    },
    getHistoryChangelog(data:any, size = 10, page = 0): any {
        return send({
            url: `${url}` + `/classifiers/calculation-rule/changelog`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    activated(id: any) {
        return send({
            url: `${url}` + `/classifiers/calculation-rule/activate/${id}`,
            method: "post",
        });
    },
    deactivated(id: any) {
        return send({
            url: `${url}` + `/classifiers/calculation-rule/deactivate/${id}`,
            method: "post",
        });
    },
}
