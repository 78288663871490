
import {defineComponent, ref, watch} from "vue";
import { IncidentsApi } from "@/api/incidents.api";
import IncidentCharacteristics from "@/components/incidents/IncidentCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  components: {IncidentCharacteristics, EditForm},
  data: () => {
    return {
      supportTeamId: null,
      areaId: null,
      description: "",
      priorityId: null,
      serviceId: null,
      equipmentId: null,
      clientId: null,
      addButtonDidabled: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    routeToIncident(id?) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace("/incidents/" + id);
    },
    add() {
      this.addButtonDidabled = true;
      this.$refs.characteristics.deleteValidation();
      if(this.$refs.characteristics.validate()) {
        this.addButtonDidabled = false;
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      console.log(characteristics, 'characteristics')
      const incidentNew: FormData = new FormData();

      if (characteristics.equipment?.id) {
        incidentNew.append("equipmentObjectId", characteristics.equipment.id);
      }

      if (characteristics.monitoringObjectDto?.id) {
        incidentNew.append("monitoringObjectId", characteristics.monitoringObjectDto.id);
      }
      if (characteristics.supportTeam?.id) {
        incidentNew.append("supportTeamId", characteristics.supportTeam.id);
      }
      if (characteristics.priority?.id) {
        incidentNew.append("priorityId", characteristics.priority.id);
      }
      if (characteristics.urgency?.id) {
        incidentNew.append("urgencyId", characteristics.urgency.id);
      }
      if (characteristics.customerAgent?.id) {
        incidentNew.append("customerAgentId", characteristics.customerAgent.id);
      } else if (currentUser.getValue().userType.id === 1) {
        incidentNew.append("customerAgentId", currentUser.getValue().id);
      }
      incidentNew.append("description", characteristics.description);
      incidentNew.append("clientId", this.clientId);
      if(characteristics.type) {
        incidentNew.append("typeId", characteristics.type.id);
      }
      if(this.$refs.characteristics.selectedContacts) {
        incidentNew.append("additionalContacts", this.$refs.characteristics.selectedContacts);
      }
      console.log(characteristics, 'characteristics')
      if (!characteristics.monitoringObjectDto?.id) {
        this.$refs.characteristics.setHasErrorClass('monitoringObjectDto.name')
        this.$notification.open({
          message: "Заполните обязательные поля",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.addButtonDidabled = false;
        return;
      }
      IncidentsApi.addRequest(incidentNew).then((res) => {
        this.addButtonDidabled = false;
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.routeToIncident(res);
      });
    },
  },
  mounted() {
    currentUser.subscribe((res) => {
      this.clientId = res?.clientId.id
    })
  }
})
