
import {defineComponent} from "vue";
import {DashboardsApi} from "@/api/dashboards.api";

export default defineComponent({
  data: () => ({
    filter: { sortFieldName: "availability", direction: "asc" },
    page: 0,
    size: 10,
    isLoad: false,
    search: '',
    list: [],
    fields: [
      {
        field: "regionName",
        sortDirections: ["descend", "ascend"],
        name: "Регион",
        value: true,
        getClass: ({availability}) => {
          if(availability < 90 && availability > 80) {
            return 'available-less-than-25'
          }
          if(availability < 80) {
            return 'available-less-than-70'
          }
          return 'available'
        },
        minWidth: "160px",
      },
      {
        field: "availability",
        sortDirections: ["descend", "ascend"],
        name: "Доступность услуг (%)",
        value: true,
        compute: val => val + '%',
        getClass: ({availability}) => {
          if(availability < 90 && availability > 80) {
            return 'available-less-than-25'
          }
          if(availability < 80) {
            return 'available-less-than-70'
          }
          return 'available'
        },
        minWidth: "110px",
      },
      {
        field: "availableCount",
        getClass: (val) => "text-green-1",
        sortDirections: ["descend", "ascend"],
        name: "Доступных услуг",
        value: true,
        minWidth: "150px",
      },
      {
        field: "failedCount",
        getClass: (val) => "text-red-6",
        sortDirections: ["descend", "ascend"],
        name: "Недоступных услуг",
        value: true,
        minWidth: "150px",
      },
      {
        field: "warningCount",
        getClass: (val) => "text-orange-1",
        sortDirections: ["descend", "ascend"],
        name: "Проблемные услуги",
        value: true,
        minWidth: "150px",
      },
      {
        field: "totalCount",
        sortDirections: ["descend", "ascend"],
        name: "Всего услуг",
        value: true,
        minWidth: "150px",
      },
    ],
    debounce: null,
  }),
  mounted() {
    this.getRegionStats();
  },
  methods: {
    changeRegionSize() {
      if(this.size === 10) {
        this.size = 100;
      } else {
        this.size = 10;
      }
      this.getRegionStats();
    },
    getRegionStats() {
      this.isLoad = false;
      DashboardsApi.getRegionStats(this.filter, this.search, this.size).then((res) => {
        this.list = res;
        this.isLoad = true;
      });
    },
    handleTableChange(
      pag: { pageSize: number; current: number },
      filters: any,
      sorter: any
    ) {
      let direction = "";
      let sortFieldName = "";
      sortFieldName = sorter.columnKey;
      if (sorter.order) {
        direction = sorter.order === "descend" ? "desc" : "asc";
        this.filter = {sortFieldName, direction }
      } else {
        this.filter = { sortFieldName: "regionName", direction: "desc" };
      }
      this.getRegionStats();
    },
    sort(a, b) {
      return null; //a[name] - b[name];
    },
  },
  computed: {
    textShowRegionsButton() {
      return (this.size <= 10) ? 'Показать остальные регионы' : 'Оставить 10 регионов'
    }
  },
  watch: {
    search: {
      handler(val) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.getRegionStats()
        }, 600)
      }
    }
  }
});
