
import {mixins, Options, Vue} from "vue-class-component";
import FilterBase from "@/classes/FilterBase.vue";
import Super from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {ExecutorUsersFilterImp} from "@/components/classes/ExecutorUsersFilterImp";
import { Util } from "@/components/utils/Util";
import Accordion from "@/components/ui/Accordion.vue";
import {ClassifilesApi} from "@/api/classifires.api";
import {ExecutorUsersApi} from "@/api/executor-users.api";
import {CounterpartyApi} from "@/api/counterparty.api";
import { filter } from "rxjs";

export default defineComponent({
  name: "ExecutorUsersFilter",
  components: {
    Accordion
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    getSupportTeam(supportTeamName?) {
      console.log("aasdasdas")
      const name = supportTeamName || this.supportTeamName;
      ClassifilesApi.getSupportTeamList(name)
          .then(res => {
            this.valuesForCheckboxes.supportTeamIds = Util.transformToCheckboxes(res);
          });
    },
    
    getExecutor() {
      ExecutorUsersApi.applyFilterExecutor({})
          .then(res => {
            this.valuesForCheckboxes.executorIds = Util.transformToCheckboxes(res.data);
          });
    },
    getCounterparty(event?) {
      const name = this.filter.organization || ""
      console.log(event) 
      CounterpartyApi.applyFilter({name} , 25)
          .then(res => {
            this.valuesForCheckboxes.clientIds = Util.transformToCheckboxes(res.data);
          })
    },
    getUserGroups() {
      ExecutorUsersApi.applyFilterUsersGroups({}, 70)
          .then(res => {
            this.valuesForCheckboxes.groups = Util.transformToCheckboxes(res.data);
          });
    },
    getUserType() {
      ExecutorUsersApi.getUserType()
          .then(res => {
            this.valuesForCheckboxes.userType = Util.transformToCheckboxes(res);
          });
    },
    onCheckLastValue(array, name) { // only last value
      Util.onCheckLastValue(array, name);
    },
    onCheckManyValues(array, name) { // many values
      Util.onCheckManyValues(array, name);
    },
    applyFilter() {
      this.filter.executorIds = (this.valuesForCheckboxes.executorIdsArr.length === 1
          && this.valuesForCheckboxes.executorIdsArr[0] === '')
          ? [] : this.valuesForCheckboxes.executorIdsArr;
      this.filter.groups = (this.valuesForCheckboxes.groupsArr.length === 1
          && this.valuesForCheckboxes.groupsArr[0] === '')
          ? [] : this.valuesForCheckboxes.groupsArr;
      this.filter.userTypeId = (this.valuesForCheckboxes.userTypeArr.length === 1
          && this.valuesForCheckboxes.userTypeArr[0] === '')
          ? [] : this.valuesForCheckboxes.userTypeArr;
      this.filter.clientIds = (this.valuesForCheckboxes.clientIdsArr.length === 1
      && this.valuesForCheckboxes.clientIdsArr[0] === '')
      ? [] : this.valuesForCheckboxes.clientIdsArr;
      this.filter.isEnabled = this.valuesForCheckboxes.incidentStatusesArr[0] === ''
          ? null : this.valuesForCheckboxes.incidentStatusesArr[0] === 'true';
      this.filterChange(new ExecutorUsersFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      if (!this.$route.query.isRelated) {
        let queryParams: any = {};
        this.setQueryFromFilterParam(queryParams, ['id', 'login', 'fio']);
        this.setQueryParamsArrDefault(queryParams, ['groupsArr']);
        this.setQueryParamsArrDefault(queryParams, ['executorIds']);
        this.setQueryParamsArrDefault(queryParams, ['userType']);
        this.setQueryParamsArrDefault(queryParams, ['clientIds']);
        this.$router.push({query: queryParams});
      }
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      console.log(query);
      this.setFilterFromQueryParam(query, ['id', 'login', 'fio']);
      this.setCheckboxesArrFromQuery(query, ['executorIds', 'userType' , "clientIds"]);
      if(query.incidentStatuses) {
        this.valuesForCheckboxes.incidentStatusesArr[0] = query.incidentStatuses;
      }
    },
    cleanFilter() {
      this.filter = new ExecutorUsersFilterImp();
      this.defaultValueFilter();
      this.setFilterFields();
      this.filterChange(new ExecutorUsersFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes(['incidentStatusesArr']);
      this.valuesForCheckboxes.incidentStatusesArr = [''];
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'id') {
              this.filter.id = '';
            }
            if(f.field === 'login') {
              this.filter.login = '';
            }
            if(f.field === 'fio') {
              this.filter.fio = '';
            }
            if(f.field === 'isEnabled') {
              this.filter.isEnabled = null;
            }
            if(f.field === 'organization') {
              this.valuesForCheckboxes.executorIdsArr = [];
            }
          });
    },
  },
  data: () => ({
    supportTeamName: '',
    organizationName: "",
    filter: new ExecutorUsersFilterImp(),
    valuesForCheckboxes: {
      incidentStatuses: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'}
      ],
      incidentStatusesArr: [],
      executorIds: [],
      executorIdsArr: [],
      groups: [],
      groupsArr: [],
      userType: [],
      userTypeArr: [],
      clientIds: [],
      clientIdsArr: []

    },
    filterData: {}
  }),
  mounted() {
    Promise.all([this.getExecutor(), this.getUserGroups(), this.getUserType(), this.getCounterparty()])
        .then(() => {
          this.defaultValueFilter();
          this.getQueryParams();
          // this.setFilterFields();
          this.applyFilter();
        })
  },
  setup() {
    return {
      locale
    }
  },
})
