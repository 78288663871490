import {send, url} from "@/helpers/api";

export const MailNotificationApi = {
    applyFilter(data: any, size = 10, page = 0): any {
        return send({
            url: `/skyv-mon/api/mail/mailing-list/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/mail/mailing-list/${id}`,
            method: "get",
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/mail/mailing-list/${id}`,
            method: "delete",
        });
    },
    save(data) {
        return send({
            url: `${url}` + `/mail/mailing-list/save`,
            method: "post",
            data,
        });
    },
    relateMailNotificationToUser(departmentId: number, users: string[]) {
        return send({
            url: `${url}` + `/mail/add-users-to-mailing-list`,
            method: "post",
            data: {
                mailingListId:departmentId,
                userIds:users
            },
        });
    },
   deleteMailNotificationToUser(departmentId: number, users: number) {
        return send({
            url: `${url}` + `/mail/delete-user-from-mailing-list`,
            method: "post",
            data: {
                mailingListId:departmentId,
                userId:users
            },
        });
    },
    sendMassNotification(data) {
        return send({
            url: `${url}` + `/mail/send-message-to-list`,
            method: "post",
            data,
        })
    }
}