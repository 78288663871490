
import { defineComponent } from "vue";
import MetricsTypeCharacteristics from "@/components/metrics/MetricsTypeCharacteristics.vue";
import {MetricApi} from "../../api/metric.api";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase, {
  GET_CALCULATION_RULES,
  GET_CALCULATION_RULES_HISTORY,
  GET_METRIC_GROUP_RELATED_OBJECT, GET_MONITORING_OBJECT_RELATED_OBJECT, GET_MONITORING_OBJECT_TYPE_RELATED_OBJECT
} from "@/classes/ViewBase.vue";
import MetricsGroupsFilter from "@/components/metrics-groups/MetricsGroupsFilter.vue";
import MetricGroupList from "@/components/metrics-groups/MetricGroupList.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import { MetricGroupApi } from "@/api/metric-group.api";
import {CalculationRuleApi} from "@/api/calculationRule.api";
import CalculationRulesList from "@/components/calculation-rules/CalculationRulesList.vue";
import CalculationRulesFilter from "@/components/calculation-rules/CalculationRulesFilter.vue";
import CalculationRulesHistoryFilter from "@/components/calculation-rules-history/CalculationRulesHistoryFilter.vue";
import CalculationRulesHistoryList from "@/components/calculation-rules-history/CalculationRulesHistoryList.vue";
import {defineDtoObject, defineDtoSubject} from "@/helpers/defineDtoSubject";
import {SettingsApi} from "@/api/settings.api";
import {
  calculationRuleDtoHistorySettings$,
  monitoringObjectTypeListDtoSettings$,
  setVisibleToFieldsInTable
} from "@/consts/settings";
import {Field} from "@/types/fields";
import {CalculationRulesHistoryListData} from "@/types/calculation-rules-history";
import {first} from "rxjs";
import MonitoringObjectsFilter from "@/components/monitoring-objects/MonitoringObjectsFilter.vue";
import MonitoringObjectsList from "@/components/monitoring-objects/MonitoringObjectsList.vue";
import EquipmentTypesList from "@/components/equipment-types/EquipmentTypesList.vue";
import EquipmentTypesFilter from "@/components/equipment-types/EquipmentTypesFilter.vue";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "MetricsTypeView",
  components: {
    MetricsTypeCharacteristics,
    EditForm,
    TableSettings,
    DeleteDialog,
    MetricsGroupsFilter,
    MetricGroupList,
    MonitoringObjectsList,
    MonitoringObjectsFilter,
    EquipmentTypesList,
    EquipmentTypesFilter,
    CalculationRulesList,
    CalculationRulesFilter,
    CalculationRulesHistoryList,
    CalculationRulesHistoryFilter
  },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    selectorData: [
      {value: "Группы метрик", menuCode: 'dictionary', childMenuCode: 'groups_of_metrics'},
      { value: "Объекты", menuCode: 'monitoring', childMenuCode: 'object' },
      {value: "Типы объектов", menuCode: 'dictionary', childMenuCode: 'types_of_objects'}
    ],
    currentSelect: "Группы метрик",
    currentRuleSelect: "Правила расчета",
    selectorRuleData: [{value: "Правила расчета"}, {value: "История правил расчета"}],
    type: "metric",
    ruleType: 'calculationRule',
    currentUser,
    relatedObjects: {
      "metric-groups": {
        ...GET_METRIC_GROUP_RELATED_OBJECT(),
      },
      monitoringObjects: {
        ...GET_MONITORING_OBJECT_RELATED_OBJECT(),
      },
      'equipment-types': {
        ...GET_MONITORING_OBJECT_TYPE_RELATED_OBJECT()
      },
      "calculationRule": {
        ...GET_CALCULATION_RULES()
      },
      "calculationRuleHistory": {
        ...GET_CALCULATION_RULES_HISTORY()
      }
    },
    relatedTitle: 'типа метрики',
    visibleSidebarFilter: false,
    visibleSidebarTableSettings: false,
    id: null
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();

    const accessParams = { module: "dictionary", object: "types_of_metrics" };
    const tabMap = {
      "tab_common_information_types_of_metrics": "1",
      "tab_relation_types_of_metrics": "2",
      "tab_relation_rule_of_calc": "3",
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    routeToRuleObject(relatedName = this.body.name) {
      this.$router.push({
        path: `${this.currentRule.routingUrl}`,
        query: {
          relatedId: this.$route.params.id,
        },
      });
    },
    onSelectedCalculationRule(value) {
      this.isLoad = false;
      this.prepareRuleDataForList();
      const ind = this.selectorRuleData.findIndex(d => d.value === value);
      this.$router.replace({ query: {...this.$route.query, sel: ind}});
    },
    handleDeleteRelatedObject(id) {
      if(this.currentSelect ==='Группы метрик') {
        MetricGroupApi.deleteMetric(id, this.id)
          .then(res => {
            this.applyCurrentRelatedFilter();
          })
      }
      if(this.currentSelect ==='Типы объектов') {
        MetricApi.deleteMetricTypeFromMonitoringObjectType(this.id, id)
            .then(res => {
              this.applyCurrentRelatedFilter();
            })
      }
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("типа метрик", `тип метрик ${this.body.name}`);
    },
    getById() {
      MetricApi.getById(this.id).then((res) => {
        this.body = res;
        suffix.next(' ' + res.name);
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    getCalculationRule() {
      CalculationRuleApi.applyFilter({metricTypeId: this.id}).then((res) => {
      })
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();

      MetricApi.save(
          characteristics
      ).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.getById();
      });
    },
    deleteObject() {
      MetricApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    updateRuleList(value) {
      this.isLoad = false;
      let order = '';
      let column = '';
      if(value.columnKey === 'active') {
        column = 'activity'
      } else {
        column = value.columnKey;
      }
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.currentRule.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyCurrentRuleFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.currentRule.filter.sortParams = [sortParams];
      this.applyCurrentRuleFilter();
    },
    applyCurrentRuleFilter() {
      this.keyForRelatedRequest = this.getKeyForRuleRequest();
      this.prepareRuleDataForList();
      this.isLoad = false;
      this.currentRule.get({
        ...this.currentRule.filter,
        [this.keyForRelatedRequest]: this.$route.params.id,
      }, this.currentRule.size, this.currentRule.page)
          .then(res => {
            this.currentRule.list = [];
            this.currentRule.totalCount = res.totalCount;
            setTimeout(() => {
              this.currentRule.list = res.data;
            }, 1);
            this.isLoad = true;
          });
    },
    getKeyForRuleRequest() {
      if(this.ruleType === 'calculationRule') {
        return 'metricTypeId';
      }
      if(this.ruleType === 'calculationRuleHistory') {
        return 'metricTypeId';
      }
    },
    prepareRuleDataForList() {
      if(this.currentRule
          && this.currentRule.prepareData != null) {
        for (let i = 0; i < this.countForList; i++) {
          this.currentRule.list
              .push(this.currentRule?.prepareData);
        }
      }
    },
    updateRulePage({ page, size }) {
      this.currentRule.size = size;
      this.currentRule.page = page - 1;
      this.currentRule.listPage = page;
      this.applyCurrentRuleFilter();
    },
    filterRuleUpdate(filter) {
      this.currentRule.filter = Object.assign({}, filter);
      this.applyCurrentRuleFilter();
    },
    getViewSettings(dtoObjectClass) {
      if(localStorage.getItem(dtoObjectClass)) {
        const dtoObject = defineDtoObject(dtoObjectClass) as any[];
        const fields = JSON.parse(localStorage.getItem(dtoObjectClass)).map(fieldFromLocalStorage => {
          const fieldDto = dtoObject.find(field => fieldFromLocalStorage.field === field.field);
          fieldFromLocalStorage.compute = fieldDto?.compute;
          fieldFromLocalStorage.getClass = fieldDto?.getClass;
          return fieldFromLocalStorage;
        })
        defineDtoSubject(dtoObjectClass).next(fields);
        return;
      }
      calculationRuleDtoHistorySettings$.next(null)
      SettingsApi.get(dtoObjectClass)
          .then(res => {
            calculationRuleDtoHistorySettings$.next(res[0])
            calculationRuleDtoHistorySettings$
                .pipe(
                    first(),
                )
                .subscribe(settings => {
                  if(!settings) {
                    return;
                  }
                  this.relatedObjects['calculationRuleHistory'].fields
                      = setVisibleToFieldsInTable(this.relatedObjects['calculationRuleHistory'].fields, settings.fields) as Field<CalculationRulesHistoryListData>[];
                });
          })
    },
    defineDtoSubject,
  },
  computed: {
    currentRule() {
      if (this.currentRuleSelect === 'Правила расчета') {
        return  this.relatedObjects['calculationRule']
      }
      if (this.currentRuleSelect === 'История правил расчета') {
        if(!calculationRuleDtoHistorySettings$.value) {
          this.getViewSettings(this.relatedObjects['calculationRuleHistory'].dto)
        }
        return  this.relatedObjects['calculationRuleHistory']
      }
      return this.relatedObjects.area;
    }
  },
});
