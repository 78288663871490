import { BehaviorSubject } from "rxjs";
import { Field } from "@/types/fields";

export const viewSettings$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

export const operationQueueSettings$ = new BehaviorSubject<any>(null);

export const incidentsSettings$ = new BehaviorSubject<any>(null);
export const systemLogoListDtoSettings$ = new BehaviorSubject<any>(null);
export const processDefinitionDtoSettings$ = new BehaviorSubject<any>(null);
export const brandDtoSettings$ = new BehaviorSubject<any>(null);
export const modelListDtoSettings$ = new BehaviorSubject<any>(null);
export const responseZoneDtoSettings$ = new BehaviorSubject<any>(null);
export const сhannelTypeDtoSettings$ = new BehaviorSubject<any>(null);
export const contractsDtoSettings$ = new BehaviorSubject<any>(null)
export const areaTypeDtoSettings$ = new BehaviorSubject<any>(null);
export const monitoringObjectTypeListDtoSettings$ = new BehaviorSubject<any>(null);
export const departmentsListDtoSettings$ = new BehaviorSubject<any>(null)
export const indicatorListDtoSettings$ = new BehaviorSubject<any>(null);
export const metricGroupListDtoSettings$ = new BehaviorSubject<any>(null);
export const metricListDtoWithMetricGroupsSettings$ = new BehaviorSubject<any>(null);
export const calculateStatusRuleDtoSettings$ = new BehaviorSubject<any>(null);
export const supportTeamListDtoSettings$ = new BehaviorSubject<any>(null);
export const counterpartyListDtoSettings$ = new BehaviorSubject<any>(null);
export const customAttributesListDtoSettings$ = new BehaviorSubject<any>(null);
export const reportsExecDtoSettings$ = new BehaviorSubject<any>(null);
export const monitoringObjectListDtoSettings$ = new BehaviorSubject<any>(null);
export const areaListDtoSettings$ = new BehaviorSubject<any>(null);
export const serviceListDtoSettings$ = new BehaviorSubject<any>(null);
export const groupDtoSettings$ = new BehaviorSubject<any>(null);
export const collectionAgentDtoSettings$ = new BehaviorSubject<any>(null);
export const viewSettingsDtoSettings$ = new BehaviorSubject<any>(null);
export const userListDtoSettings$ = new BehaviorSubject<any>(null);
export const fileManagerDtoSettings$ = new BehaviorSubject<any>(null);
export const GroupDtoSettings$ = new BehaviorSubject<any>(null);
export const userRoleDtoSettings$ = new BehaviorSubject<any>(null);
export const unitDtoSettings$ = new BehaviorSubject<any>(null);
export const metricTagDtoSettings$ = new BehaviorSubject<any>(null);
export const urgencyDtoSettings$ = new BehaviorSubject<any>(null);
export const measureDtoSettings$ = new BehaviorSubject<any>(null);
export const calculationRuleDtoSettings$ = new BehaviorSubject<any>(null);
export const calculationRuleDtoHistorySettings$ = new BehaviorSubject<any>(null);
export const noticeTemplateDtoSettings$ = new BehaviorSubject<any>(null);

export const getViewSettings = (name) => {
  return viewSettings$.value?.find(_ => _.dtoObjectClass === name)
}

export const getFieldSettings = (settings, field) => {
  return settings?.find(_ => _.dtoField   === field);
}

export const setVisibleToFieldsInTable = (fields: Field<any>[], viewSettingFields) => {
  return fields.map(field => {
    const viewField = viewSettingFields.find(viewSettingField => viewSettingField.dtoField == field.field);
    if(!viewField) {
      return;
    }
    field.value = viewField?.displayList;
    // field.name = viewField?.name;
    field.fieldOrder = viewField?.fieldOrder;
    return field;
  })
    .filter(_ => _)
    .sort((a, b) => {
    return a.fieldOrder - b.fieldOrder
  });
}

