
import {Options, Vue} from "vue-class-component";
import ruRU from 'ant-design-vue/es/locale/ru_RU';
import {SettingsApi} from "@/api/settings.api";
import {getRouteProps} from "@/helpers/route";
import { defineDtoObject, defineDtoSubject } from "@/helpers/defineDtoSubject";

  @Options({
    components: {
    },
    data: () => ({
      locale: ruRU,
      // isOpened: true,
    }),
    mounted() {
      const favicons: any = document.getElementById("favicon");
      favicons.href = 'data:image/'+ localStorage.getItem('smallLogoExtension')+';base64,'+ localStorage.getItem('smallLogo');
      // document.title = localStorage.getItem('name');
    },
    methods: {
      getViewSettings(dtoObjectClass) {
        if(localStorage.getItem(dtoObjectClass)) {
          const dtoObject = defineDtoObject(dtoObjectClass) as any[];
          const fields = JSON.parse(localStorage.getItem(dtoObjectClass)).map(fieldFromLocalStorage => {
            const fieldDto = dtoObject.find(field => fieldFromLocalStorage.field === field.field);
            fieldFromLocalStorage.compute = fieldDto?.compute;
            fieldFromLocalStorage.getClass = fieldDto?.getClass;
            return fieldFromLocalStorage;
          })
          defineDtoSubject(dtoObjectClass).next(fields);
          return;
        }
        defineDtoSubject(dtoObjectClass).next(null)
        SettingsApi.get(dtoObjectClass)
          .then(res => {
            defineDtoSubject(dtoObjectClass).next(res[0])
          })
      },
      defineDtoSubject
    },
    watch: {
      $route(to) {
        if(!getRouteProps(to)?.dto) {
          return;
        }
        this.getViewSettings(getRouteProps(to).dto);
      }
    }
  })
  export default class App extends Vue {
  }
