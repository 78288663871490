
import {Options, Vue} from "vue-class-component";
import NSIBase from "@/classes/NSIBase.vue";
import IncidentTypeList from "@/components/incident-type/IncidentTypeList.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import IncidentTypeFilter from "@/components/incident-type/IncidentTypeFilter.vue";
import {IncidentTypeListData} from "@/types/incident-type";
import {reactive} from "vue";
import {getIncidentTypeData, IncidentTypeFields} from "@/consts/fields/incident-type.fields";
import {Field} from "@/types/fields";
import {IncidentTypeFilterImp} from "@/components/classes/IncidentTypeFilterImp";
import {HealthModelRuleApi} from "@/api/health-model-rule.api";
import {IncidentTypeApi} from "@/api/incident-type.api";
import {IncidentFilterImp} from "@/components/classes/IncidentFilterImp";
import {currentUser} from "@/consts/currentUser";

@Options({
  mixins: [NSIBase],
  components: {IncidentTypeFilter, IncidentTypeList, TableSettings},
  data: () => {
    return {
      list: [] as IncidentTypeListData[],
      search: '',
      selectedList: [],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      userId: null,
      incidentKeys: "" as keyof IncidentTypeListData,
      fields: reactive(IncidentTypeFields.slice() as Field<IncidentTypeListData> []),
      filter: new IncidentTypeFilterImp(),
      prepareData: getIncidentTypeData(),
      currentUser
    }
  },
  methods: {
    routeToAddProcesses() {
      this.$router.push("/directory/incident-types/add");
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    resultMethod(res) {
      this.list = res.data;
      this.totalCount = res.totalCount;
      this.isLoad = true;
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    searchMethod(_) {
      const filter  = this.defineFilterData
      filter[this.exceptKey] = +this.$route.query.relatedId;
      return IncidentTypeApi
          .applyFilter(filter);
    },

  },
  mounted() {
    this.applyFilter();
  }
})
export default class IncidentType extends Vue{
  list!: IncidentTypeListData[];
  search: string;
  filter: IncidentTypeFilterImp;
}
