import {send, url, withoutAPIUrl} from "@/helpers/api";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";

export const GroupsApi = {
  applyFilter(data: any, size = 10, page = 0): any {
    return send({
      url: `/skyv-mon/users/groups/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilter2(data: any, size = 10, page = 0): Observable<any>  {
    return Axios.request({
      url: `/skyv-mon/users/groups/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  save(data: any) {
    return send({
      url: `${url}` + `/adminapi/groups/save`,
      method: "post",
      data,
    });
  },
  getById(id) {
    return send({
      url: `${url}` + `/adminapi/groups/${id}`,
      method: "get",
    });
  },
  delete(id) {
    return send({
      url: `${url}` + `/adminapi/groups/${id}`,
      method: "delete",
    });
  },
  addUserGroups(id: number, groups: string[]) {
    const data = {
      userId: id,
      groups: groups
    };
    return send({
      url: `${withoutAPIUrl}` + `/users/groups`,
      method: "post",
      data,
    });
  },
  deleteGroupUser(userId, groupId) {
    return send({
      url: `${withoutAPIUrl}` + `/users/${userId}/groups/${groupId}`,
      method: "delete",
    });
  },
  addGroupUsers(id: number, data: string[]) {
    return send({
      url: `${url}` + `/adminapi/groups/${id}/users`,
      method: "post",
      data,
    });
  },
};
