import { Field } from "@/types/fields";
import { AreaListData } from "@/types/area";
import { defineTextColorByAreaStatus } from "@/helpers/define-status-color";

export const areasFields: Field<AreaListData>[] = [
  {
    field: "id",
    sortDirections: ["descend", "ascend"],
    name: "ID",
    value: true,
    minWidth: "100px",
  },
  {
    field: "name",
    sortDirections: ["descend", "ascend"],
    name: "Наименование",
    value: true,
    minWidth: "160px",
  },
  {
    field: "areaType",
    sortDirections: ["descend", "ascend"],
    name: "Тип местоположения",
    value: true,
    minWidth: "160px",
  },
  {
    field: "code",
    sortDirections: ["descend", "ascend"],
    name: "Код местоположения",
    value: true,
    minWidth: "175px",
  },
  {
    field: "district",
    sortDirections: ["descend", "ascend"],
    name: "Район",
    value: false,
    minWidth: "100px",
  },
  {
    field: "division",
    sortDirections: ["descend", "ascend"],
    name: "Подразделение",
    value: false,
    minWidth: "200px",
  },
  {
    field: "region",
    sortDirections: ["descend", "ascend"],
    name: "Регион",
    value: true,
    minWidth: "150px",
  },
  {
    field: "city",
    sortDirections: ["descend", "ascend"],
    name: "Населенный пункт",
    value: true,
    minWidth: "200px",
  },
  {
    field: "street",
    sortDirections: ["descend", "ascend"],
    name: "Улица",
    value: false,
    minWidth: "150px",
  },
  {
    field: "home",
    sortDirections: ["descend", "ascend"],
    name: "Дом",
    value: false,
    minWidth: "150px",
  },
  {
    field: "postIndex",
    sortDirections: ["descend", "ascend"],
    name: "Индекс",
    value: false,
    minWidth: "120px",
  },
  {
    field: "areaStatus",
    sortDirections: ["descend", "ascend"],
    name: "Статус",
    value: false,
    minWidth: "160px",
    getClass: (val) => val ? defineTextColorByAreaStatus(val) : '',
    compute: (val) => val ? val.name : ''
  },
  {
    field: "active",
    sortDirections: ["descend", "ascend"],
    name: "Активность",
    value: false,
    minWidth: "140px",
    compute: (val) => val === true ? 'Да' : 'Нет'
  },
  {
    field: "areaStage",
    sortDirections: ["descend", "ascend"],
    name: "Этап внедрения",
    value: false,
    minWidth: "150px",
  },
  {field: "deleted",sortDirections: ['descend', 'ascend'],  name: "Архивирован", value: true, minWidth: "150px",
    compute: (val) => val === true ? 'Да' : 'Нет'},
];

export const getAreaData = () => ({
  id: null,
  code: "",
  name: "",
  region: { id: null, name: "" },
  district: null,
  city: "",
  street: "",
  home: "",
  regionCode: "",
  cityCode: null,
  districtCode: null,
  streetCode: null,
  // client: { id: null, name: "" },
  postIndex: "",
  active: true,
  areaType: "",
  division: { id: null, name: "" },
  services: [
    {
      id: null,
      serviceNum: null,
      contractNum: null,
      dateStartContr: null,
      interfaceType: null,
      connectSpeed: 200,
      dateStartService: "",
      trafficSize: null,
      orderN: "",
      mtu: "",
      channelType: { id: null, name: "" },
      status: { id: null, name: "", color: "#FF0000" },
      lastMileOperator: null,
      fragmentation: true,
      serviceStatusDt: "",
    },
    {
      id: null,
      serviceNum: null,
      contractNum: null,
      dateStartContr: null,
      interfaceType: null,
      connectSpeed: 200,
      dateStartService: "",
      trafficSize: null,
      orderN: "",
      mtu: "",
      channelType: { id: null, name: "" },
      status: { id: null, name: "", color: "#FF0000" },
      lastMileOperator: null,
      fragmentation: true,
      serviceStatusDt: "",

    },
  ],
  stage: { id: null, name: "" },
  address: "",
  hasOpenIncidents: false,
  status: { id: null, name: "" },
  deleted: ""
});
