import {Field} from "@/types/fields";
import {RepresentationsListData} from "@/types/representations";

export const representationsFields: Field<RepresentationsListData>[] = [
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Сущность", value: true, minWidth: "300px"},
    {field: "nameRus", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
];

export const getRepresentationsData = () => ({
    name: "",
    nameRus: ""
});
