
import { defineComponent } from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {GroupsApi} from "@/api/groups.api";
import {ExecutorUsersApi} from "@/api/executor-users.api";

export default defineComponent({
  components: {DeleteDialog},
  mixins: [ListBase],
  props: {
    groupId: {default: null},
  },
  data: () => ({
    id: ''
  }),
  methods: {
    deleteRelatedGroup() {
      if(this.groupId) {
        GroupsApi.deleteGroupUser(this.id, this.groupId).then(() => {
          this.onChange(this.currentPage, this.currentSize);
        });
      }
      if(this.typeRelate === 'support-teams') {
        ExecutorUsersApi.deleteSupportTeamRelation(this.id, this.relatedId).then(() => {
          this.onChange(this.currentPage, this.currentSize);
        });
      }
      else {
        this.selectedId = this.id;
        this.deleteObjectEmit();
      }
    },
    openDeleteDialog(id, name) {
      this.id = id;
      this.$refs.delete.openDialog("связи", `связь с пользователем ${name}`);
    }
  }
});
