import {Field} from "@/types/fields";
import {HealthModelRuleListData} from "@/types/health-model-rule";

export const HealthModelRuleFields: Field<HealthModelRuleListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "150px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
];

export const getHealthModelRuleData = () => ({
    id: null,
    name: null,
    // ruleOpen: {
    //     checkTime: null,
    //     metricLimitCondition: "{expression: []}",
    //     incidentTemplate: {
    //         priority: {
    //             id: null,
    //             name: ''
    //         },
    //         type: {
    //             id: null,
    //             name: ''
    //         },
    //         supportTeam: {
    //             id: null,
    //             name: ''
    //         }
    //     }
    // },
    // ruleClose: {
    //     checkTime: null,
    //     metricLimitCondition: "{expression: []}",
    // },
    metricLimits: [
        {
        metricId: null,
        metricName: '',
        limits: [
            {id: null, name: {
                id: null, name: ''
                }, value: null}
        ],
    }
    ],
    monitoringObjectParamExpression: "{type: '', characteristics: []}"
})
