import { send, url } from "@/helpers/api";
import axios from "axios";

export const ClassifilesApi = {
  getIncidentFilterList(): any {
    return axios.get(`${url}` + `/classifiers/incident`);
  },
  getOrderNWfstatus(data, size = 10, page = 0) {
    return send({
      method: "post",
      url:
        `${url}/classifiers/wfstatus/filter`,
      data,
      headers: this.headers,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getOrderNWfprocess(data, size = 10, page = 0) {
    return send({
      method: "post",
      url:
        `${url}` + `/classifiers/wfprocess/filter`,
      data,
      headers: this.headers,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getSupportTeamStatuses() {
    return send({
      method: "get",
      url:
          `${url}` + `/classifiers/support-team/statuses`
    });
  },
  getSupportTeamList(name?, size = 10) {
    const params = {name: name, size: size};
    return send({
      method: "get",
      url: `${url}` + `/classifiers/supportteams`,
      params,
    });
  },
  getMeasure(name) {
    const data = {name: name};
    return send({
      method: "post",
      url: `${url}` + `/classifiers/measure`,
      data,
    });
  },
  getMonitoringSystem() {
    return send({
      method: "get",
      url: `${url}` + `/classifiers/monitoring-system`
    });
  },
  getSnmpOid(oid, size) {
    return send({
      method: "get",
      url: `${url}` + `/snmp/filter/oid`,
      params: {
        oid: oid,
        size: size
      }
    });
  },
    getSpeedMeasure() {
    return send({
      method: "get",
      url: `${url}` + `/classifiers/measure`
    });
  },
  getMeasureType() {
    return send({
      method: "get",
      url: `${url}` + `/classifiers/measure-types`
    });
  },
  getContractsStatus() {
    return send({
      method: "get",
      url: `${url}` + `/classifiers/contracts/statuses`
    });
  },
  getMetricTypes() {
    return send({
      method: "get",
      url: `${url}` + `/classifiers/metric-types`
    });
  },
  getEventType() {
    return send({
      method: "get",
      url: `${url}` + `/classifiers/event-type`
    });
  },
};
