
import {defineComponent} from "vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import EditForm from "@/components/base/EditForm.vue";
import ViewBase from "@/classes/ViewBase.vue";
import {BrandsApi} from "@/api/brands.api";
import BrandCharacteristics from "@/components/brands/BrandCharacteristics.vue";
import {suffix} from "@/consts/nameSuff";

export default defineComponent({
  name: "BrandView",
  components: {
    BrandCharacteristics,
    EditForm,
    DeleteDialog,
  },
  mixins: [ViewBase],
  data: () => ({
    list: [],
    body: null,
    visibleSidebarFilter: false,
    visibleSidebarTableSettings: false,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    onSelect(value) {
      const ind = this.selectorData.findIndex(sd => sd.value === value);
      this.$router.replace({ query: {...this.$route.query, sel: ind}});
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("производителя", `производитель ${this.body.name}`);
    },
    getById() {
      if (this.$refs.characteristics)
        this.$refs.characteristics.clean();

      BrandsApi.getById(this.id)
          .then(res => {
            this.body = res;
            suffix.next(' ' + res.name);
          }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristic = this.$refs.characteristics.formatCharacteristicsCopy();
      BrandsApi.save(characteristic).then(() => {
        this.getById();
      })
    },
    deleteObject() {
      BrandsApi.delete(this.id).then(res => {
        this.$router.go(-1);
      })
    }
  }
})
