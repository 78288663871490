import {Field} from "@/types/fields";
import {ChannelTypeListData} from "@/types/channel-type";

export const channelTypesFields: Field<ChannelTypeListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "120px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""}
];

export const getChannelTypesData = () => ({
    id: null,
    name: ""
});
