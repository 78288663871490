
import {Field} from "@/types/fields";
import {Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import BrandsList from "@/components/brands/BrandsList.vue";
import BrandsFilter from "@/components/brands/BrandsFilter.vue";
import {BrandsFilterImp} from "@/components/classes/BrandsFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {BrandsListData} from "@/types/brands";
import {BrandsApi} from "@/api/brands.api";
import {brandsFields, getBrandsData} from "@/consts/fields/brands.fields";
import {takeWhile} from "rxjs/operators";
import {brandDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";

@Options({
  components: {
    TableSettings,
    Accordion,
    BrandsList,
    BrandsFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as BrandsListData[],
      search: "",
      selectedList: [],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      userId: null,
      incidentKeys: "" as keyof BrandsListData,
      fields: null,
      filter: new BrandsFilterImp(),
      prepareData: getBrandsData()
    };
  },
  methods: {
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      BrandsApi
          .downloadExcel(filter)
          .then((res) => {
            this.$notification.open({
              message: new TextDecoder().decode(res.data),
              style: {
                width: '600px',
                marginLeft: `${400 - 600}px`,
                fontSize: "14px",
              },
              placement: 'bottomRight',
              duration: 3,
            });
          })
          .finally(() => (this.isLoadingExcel = false));
    },
    getSelectedEquipment(e) {
      this.selectedList = e;
    },
    handleClickOnRelateButton() {
      // if(this.exceptKey === 'exceptUserId') {
      //   this.relateGroups();
      //   return;
      // }
    },
    // relateGroups() {
    //   let data: string[] = [];
    //   this.selectedList.forEach(role => data.push(role+''));
    //   return BrandsApi.addUserGroups(+this.$route.query.relatedId, data).then(() => {
    //     this.$router.go(-1);
    //   })
    // },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddBrand() {
      this.$router.push("/directory/brands/add");
    },
    searchMethod(_) {
      const filter  = this.defineFilterData
      filter[this.exceptKey] = +this.$route.query.relatedId;
      return BrandsApi
          .applyFilter2(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    brandDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields = setVisibleToFieldsInTable(brandsFields, settings.fields) as Field<BrandsListData>[];
        });
  },
})
export default class Brands extends Vue {
  list!: BrandsListData[];
  search: string;
  filter: BrandsFilterImp;
}
