import {send, url} from "@/helpers/api";
import {Observable} from "rxjs";
import Axios from "axios-observable";

export const CounterpartyApi = {
    applyFilter(data:any, size = 10, page = 0): any {
        return send({
            url: `${url}` + `/counterparty/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    applyFilter2(data: any, size = 25, page = 0): Observable<any> {
        return Axios.request({
            url: `${url}` + `/counterparty/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/counterparty/${id}`,
            method: "get",
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/counterparty/${id}`,
            method: "delete",
        });
    },
    save(data) {
        return send({
            url: `${url}` + `/counterparty`,
            method: "post",
            data,
        });
    },
    addContactToCounterParty(data) {
        return send({
            url: `${url}` + `/counterparty/contact/add`,
            method: "post",
            data,
        });
    },
    removeContactFromCounterParty(id) {
        return send({
            url: `${url}` + `/counterparty/contact/remove/${id}`,
            method: "delete",
        });
    }
}