
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getCustomAttributesData} from "@/consts/fields/custom-attributes.fields";

export default defineComponent (
    {
      name: "CustomAttributesCharacteristics",
      mixins: [CharacteristicBase],
      data: () => ({
        data: getCustomAttributesData(),
        typeList: [{name: 'Текст', id:'text'}, {name: 'Дата', id: 'date'}, {name: 'Бинарное', id: 'boolean'}],
        entityList: [{name: 'Заявки', id: 'incident'}, {name: 'Объекты', id: 'monitoring_object'}],
        validationFields: [
          {field: "name", validators: ["required"]},
          {field: "type", validators: ["required"]},
          {field: "entityName", validators: ["required"]},
          {field: "listOrder", validators: ["required"]},
          {field: "attributeName", validators: ["required"]},
        ]
      }),
      methods: {
        onSelectEntityName(e,a) {
          this.data.entityName = a.id;
          this.data.entityNameLabel = a.value
        },
        onSelectType(e,a) {
          this.data.type = a.id;
          this.data.typeLabel = a.value
        }
      },
      mounted() {
        this.getCRUDSettings(101)
      },
      watch: {
        body() {
          if(this.data.entityName) {
            this.data.entityNameLabel = this.entityList.find(({id}) => id === this.data.entityName).name;
          }
          if(this.data.type) {
            this.data.typeLabel = this.typeList.find(({id}) => id === this.data.type).name;
          }
        }
      }
    }
)
