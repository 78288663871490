import {send, url, withoutAPIUrl} from "@/helpers/api";
import axios from "axios";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";

export const ExecutorUsersApi = {
  applyFilter(data: any, size = 10, page = 0): any {
    return send({
      url: `/skyv-mon/users/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilter2(data: any, size = 10, page = 0): Observable<any>  {
    return Axios.request({
      url: `/skyv-mon/users/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getUserType() {
    return send({
      url: `${url}` + `/classifiers/user_type`,
      method: "get",
    });
  },
  getUserByName(name) {
    return send({
      url: `${url}` + `/classifiers/users?name=${name}`,
      method: "get",
    });
  },
  applyFilterExecutor(data: any, size = 10, page = 0): any {
    return send({
      url: `${url}` + `/executors/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilterUsersGroups(data: any, size = 10, page = 0): any {
    return send({
      url: `/skyv-mon/users/groups/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getById(id) {
    return send({
      url: `/skyv-mon/users/${id}`,
      method: "get",
    });
  },
  delete(id) {
    return send({
      url: `${url}` + `/adminapi/users/${id}`,
      method: "delete",
    });
  },
  save(data) {
    return send({
      url: `${url}` + `/adminapi/users`,
      method: "post",
      data,
    });
  },
  downloadExcel(request) {
    const zoneId = encodeURIComponent(
        "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return axios({
      method: "post",
      url: `${withoutAPIUrl}/users/download?zoneId=${zoneId}`,
      data: request,
      responseType: "arraybuffer",
      headers: {
        "Accept": "application/octet-stream",
      }
    });
  },
  deleteSupportTeamRelation(executorId, supportTeamId) {
    return send({
      url: `${withoutAPIUrl}/users/${executorId}/support-teams/${supportTeamId}`,
      method: "delete",
    });
  },
  changePassword(executorId, password, data) {
    return send({
      url: `${withoutAPIUrl}` + `/users/reset-password?userId=${executorId}&password=${password}`,
      method: "post",
      data
    });
  },
  sendMessageToChangePassword(executorId, data) {
    return send({
      url: `${withoutAPIUrl}` + `/users/update-password?userId=${executorId}`,
      method: "post",
    });
  }
};
