import {send, url} from "@/helpers/api";

export const  MailApi = {
    applyFilter(data: any, size = 10, page = 0): any {
        return send({
            url: `/skyv-mon/api/mail/smtp/settings/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/mail/smtp/settings/${id}`,
            method: "get",
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/mail/smtp/settings/${id}`,
            method: "delete",
        });
    },
    save(data) {
        return send({
            url: `${url}` + `/mail/smtp/settings/save`,
            method: "post",
            data,
        });
    },
    sendMessages(data) {
        return send({
            url: `${url}` + `/mail/smtp/send-message`,
            method: "post",
            data
        });
    },

}