import {send, send2, url, withoutAPIUrl} from "@/helpers/api";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";

export const ReportsApi = {
  applyFilter(data: any, size = 10, page = 0): any {
    const zoneId = encodeURIComponent(
        "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return send({
      url: `${url}` + `/reports/history?zoneId=${zoneId}`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilter2(data: any, size = 10, page = 0, params = {}): Observable<any>  {
    const zoneId = encodeURIComponent(
        "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return Axios.request({
      url: `${url}` + `/reports/history?zoneId=${zoneId}`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
        ...params
      },
    });
  },

  delete(id) {
    return send({
      url: `${url}` + `/reports/history/${id}`,
      method: "delete",
    });
  },

  getUploadObjects() {
    return send({
      url: `${url}` + `/classifiers/uploadObjects`,
      method: "get",
    });
  },
  downloadReport(id) {
    return send2({
      url: `${url}` + `/reports/download?fileId=${id}`,
      method: "get",
      responseType: "blob",
      observe: 'response',
    });
  },
  uploadData(data, {objectName, packageCount, ignoreErrors}) {
    return send({
      url: `${url}` + `/reports/uploadData?objectName=${objectName}&packageCount=${packageCount}&ignoreErrors=${ignoreErrors}`,
      method: "post",
      data,
    });
  },
  getReportType() {
    return send({
      url: `${url}` + `/classifiers/report-types`,
      method: "get",
    });
  },
  addUserRoles(id: number, roles: string[]) {
    const data = {
      userId: id,
      roles: roles
    };
    return send({
      url: `${withoutAPIUrl}` + `/users/roles`,
      method: "post",
      data,
    });
  },
};
