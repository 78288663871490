import {Field} from "@/types/fields";
import {MailListData} from "@/types/mail";

export const MailFields: Field<MailListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "150px"},
    {field: "host", sortDirections: ['descend', 'ascend'], name: "Сервер", value: true, minWidth: ""},
    {field: "propsDb", sortDirections: ['descend', 'ascend'], name: "По умолчанию", value: true, minWidth: "",
        compute: (val) => val === true ? 'Да' : 'Нет'},
    {field: "isEnabled", sortDirections: ['descend', 'ascend'], name: "Включено", value: true, minWidth: "",
        compute: (val) => val === true ? 'Да' : 'Нет'},
    {field: "state", sortDirections: ['descend', 'ascend'], name: "Статус", value: true, minWidth: "",
        compute: (val) => val === true ? 'Успешно' : 'Неуспешно'},
];
export const getMailData = () => ({
    id: null,
    name: null,
    password: '',
    encriptType: '',
})