import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f8ecd71"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"max-height":"calc(100vh - 265px)","overflow-y":"auto"} }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  class: "title",
  style: {"width":"400px"}
}
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  class: "title",
  style: {"width":"400px"}
}
const _hoisted_6 = { class: "flex mt-5" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (!!_ctx.data.id && !_ctx.withoutId && !_ctx.data.isHealthModel)
        ? (_openBlock(), _createBlock(_component_a_row, {
            key: 0,
            class: "raw"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 6 }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.idTitle), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 6 }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.data.id), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!!_ctx.data.id && _ctx.data.isHealthModel)
        ? (_openBlock(), _createBlock(_component_a_row, {
            key: 1,
            class: "raw"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 3 }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.idTitle), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 2 }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.data.id), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (!_ctx.data.id && _ctx.data.id !== undefined)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.data.id)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "primary",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitAdd && _ctx.emitAdd(...args))),
                  disabled: _ctx.data.addButtonDisabled
                }, _toDisplayString(_ctx.addButtonText), 9, _hoisted_7))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "small second mar-l-16",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitReject && _ctx.emitReject(...args)))
            }, "Отмена")
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!!!_ctx.data.isHasSave )
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "small primary",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitSave && _ctx.emitSave(...args)))
                }, "Сохранить"))
              : _createCommentVNode("", true),
            (!!_ctx.data.isHasSave)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  disabled: true,
                  class: "small second",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.emitSave && _ctx.emitSave(...args)))
                }, "Сохранить"))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "small second mar-l-16",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.emitReject && _ctx.emitReject(...args)))
            }, "Отмена"),
            (!!_ctx.data.isArchive )
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: "archive small ml-3",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.emitArchive && _ctx.emitArchive(...args)))
                }, "В архив"))
              : _createCommentVNode("", true),
            (!!_ctx.data.isHasRefreshRelated)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 3,
                  class: "archive small ml-3",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.emitArchive && _ctx.emitArchive(...args)))
                }, "Обновить связи"))
              : _createCommentVNode("", true),
            (!!_ctx.data.unArchive )
              ? (_openBlock(), _createElementBlock("button", {
                  key: 4,
                  class: "archive small ml-3",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.emitArchive && _ctx.emitArchive(...args)))
                }, "Восстановить"))
              : _createCommentVNode("", true),
            (!!_ctx.data.isActivated)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 5,
                  class: "activated small ml-3",
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.emitArchive && _ctx.emitArchive(...args)))
                }, "Активировать"))
              : _createCommentVNode("", true),
            (!!_ctx.data.isDeactivated)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 6,
                  class: "archive small ml-3",
                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.emitArchive && _ctx.emitArchive(...args)))
                }, "Деактивировать"))
              : _createCommentVNode("", true),
            (!!_ctx.data.isChangePass)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 7,
                  class: "archive small ml-3",
                  onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.emitChangePass && _ctx.emitChangePass(...args)))
                }, "Сменить пароль"))
              : _createCommentVNode("", true),
            (!!_ctx.data.isDel)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 8,
                  class: "warn small ml-3",
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.emitDelete && _ctx.emitDelete(...args)))
                }, "Удалить"))
              : _createCommentVNode("", true),
            (!!_ctx.data.isSend)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 9,
                  class: "archive small ml-3",
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.emitSend && _ctx.emitSend(...args)))
                }, "Отправить оповещение"))
              : _createCommentVNode("", true)
          ], 64))
    ])
  ]))
}