import {Field} from "@/types/fields";
import {ExecutorUsersListData} from "@/types/executor-user";

export const executorUsersFields: Field<ExecutorUsersListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "120px"},
    {field: "login", sortDirections: ['descend', 'ascend'], name: "Логин", value: true, minWidth: ""},
    {field: "fio", sortDirections: ['descend', 'ascend'], name: "ФИО", value: true, minWidth: ""},
    {field: "isEnabled", sortDirections: ['descend', 'ascend'], name: "Активность", value: true, minWidth: "",
        compute: (val) => val === true ? 'Да' : 'Нет'},
    {field: "organization", sortDirections: ['descend', 'ascend'], name: "Организация", value: true, minWidth: "",
        compute: (val) => val ? val.name : ''},
    {field: "groups", name: "Группы", value: true, minWidth: "",
        compute: (val) => val?.length > 0 ? val.map(v => v.name).join(', ') : ''},
    {field: "userType", sortDirections: ['descend', 'ascend'], name: "Тип пользователя", value: true, minWidth: "250px"},
    {field: "email", sortDirections: ['descend', 'ascend'], name: "Email", value: true, minWidth: "250px"},
];

export const getExecutorUsersData = () => ({
    id: null,
    contacts: [], // contract
    userTypeId: null,
    userType: {name: "", id: null},
    clientId: null,
    executorId: null,
    fio: "",
    firstName: "",
    middleName: "",
    lastName: "",
    isEnabled: true,
    login: "",
    organization: {name: "", id: null},
    userRoles: [],
    roleObjects: [],

    // "password": "string",
    // "photo": "string",
    // "isEnabled": true,
    // "keycloakId": "string",
    // "position": "string"
});

export const contract = {
    id: 0,
    contactInfo: "",
    contactType: null // contactType
}

export const contactType = {
    id: 0,
    name: "",
    description: ""
}
