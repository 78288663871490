
import {defineComponent} from "vue";
import MetricsTypeCharacteristics from "@/components/metrics/MetricsTypeCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import CalculatedStatusRuleCharacteristics
  from "@/components/calculated-status-rule/CalculatedStatusRuleCharacteristics.vue";
import { CalculatedStatusRuleApi } from "@/api/calculated-status-rule.api";

export default defineComponent({
  name: "AddCalculatedStatusRule",
  components: { CalculatedStatusRuleCharacteristics, EditForm },
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      console.log('ADD?')
      // if(this.$refs.characteristics.validate()) {
      //   return;
      // }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      console.log('characreristic?', characteristics)
      let dto = characteristics;
      dto.maxValueOperator = characteristics.maxValueOperator?.value;
      dto.minValueOperator = characteristics.minValueOperator?.value;
      dto.minValue = Number(characteristics.minValue);
      dto.maxValue = Number(characteristics.maxValue);
      dto.metricInstance = {id: Number(characteristics.metricInstance)};

      CalculatedStatusRuleApi.save(
        dto
      ).then((res) => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.route(res.id);
      });
    },
    route(id?) {
      this.$refs.characteristics.cleanWarning();
      if (!id) {
        this.$router.replace("/directory/calculated-status/");
        return;
      }
      this.$router.replace("/directory/calculated-status/" + id);
    },
  },
});
