import {send, url, withoutAPIUrl} from "@/helpers/api";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";
import axios from "axios";

export const ModelsApi = {
  applyFilter(data: any, size = 10, page = 0): any {
    return send({
      url: `${url}` + `/model/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilter2(data: any, size = 10, page = 0): Observable<any>  {
    return Axios.request({
      url: `${url}` + `/model/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  save(data: any) {
    return send({
      url: `${url}` + `/model/save`,
      method: "post",
      data,
    });
  },
  getById(id) {
    return send({
      url: `${url}` + `/model/${id}`,
      method: "get",
    });
  },
  delete(id) {
    return send({
      url: `${url}` + `/model/${id}`,
      method: "delete",
    });
  },
  downloadExcel(request) {
    const zoneId = encodeURIComponent(
        "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return axios({
      method: "post",
      url: `${url}/model/download?zoneId=${zoneId}`,
      data: request,
      responseType: "arraybuffer",
      headers: {
        "Accept": "application/octet-stream",
      }
    });
  },
};
