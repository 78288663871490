
import {defineComponent} from "vue";
import MeasureCharacteristics from "@/components/measure/MeasureCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import {CollectionAgentsApi} from "@/api/collection-agents.api";
import {MeasureApi} from "@/api/measure.api";

export default defineComponent({
  components: {MeasureCharacteristics, EditForm },
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = Object.assign({}, this.$refs.characteristics.data);
      MeasureApi.save(
          characteristics
      ).then((res) => {
        this.route(res.id);
      });
    },
    route(id) {
      this.$refs.characteristics.cleanWarning();
      if (id) {
        this.$router.replace("/directory/measure/" + id);
        return;
      }

      this.$router.replace("/directory/measure/");
    },
  },
})
