import {Field} from "@/types/fields";
import {ReportsListData} from "@/types/reports";
import {formatDate} from "@/filters/date";

export const reportsFields: Field<ReportsListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "150px"},
    {
        field: "startTime",
        sortDirections: ["descend", "ascend"],
        name: "Дата запуска",
        value: true,
        compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
            formatDate(value, format),
        minWidth: "175px",
    },
    {
        field: "endTime",
        sortDirections: ["descend", "ascend"],
        name: "Дата окончания",
        value: true,
        compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
            formatDate(value, format),
        minWidth: "175px",
    },
    {
        field: "author",
        sortDirections: ["descend", "ascend"],
        name: "Автор",
        compute: (val) => val.name,
        value: true,
        minWidth: "175px",
    },
    {
        field: "reportType",
        sortDirections: ["descend", "ascend"],
        name: "Тип",
        compute: (val) => val.name,
        value: true,
        minWidth: "250px",
    },
    {
        field: "reportStatus",
        sortDirections: ["descend", "ascend"],
        name: "Статус",
        value: true,
        minWidth: "150px",
    },
    {
        field: "link",
        sortDirections: [],
        name: "Скачать",
        value: true,
        minWidth: "100px",
    },
    {
        field: "params",
        sortDirections: [],
        name: "Параметры",
        value: true,
        minWidth: "150px",
    },
];

export const getReportsData = () => ({
    id: null,
    startTime: null,
    endTime: null,
    author: null,
    reportType: null
});
