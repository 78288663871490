
import {defineComponent} from "vue";
import ViewBase from "@/classes/ViewBase.vue";
import {suffix} from "@/consts/nameSuff";
import {MailNotificationApi} from "@/api/mail-notification.api";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import MailNotificationCharacteristics from "@/components/mail-notification/MailNotificationCharacteristics.vue";
import ExecutorUsersList from "@/components/executor-users/ExecutorUsersList.vue";
import {executorUsersFields} from "@/consts/fields/executor-users.fields";
import {CounterpartyApi} from "@/api/counterparty.api";


export default defineComponent({
  name: "MailNotificationView",
  components: {ExecutorUsersList, MailNotificationCharacteristics, DeleteDialog},
  mixins: [ViewBase],
  data: () => ({
    body: null,
    executorUserList: [],
    executorUserListFields: executorUsersFields.slice(),
    currentSelect: 'Пользователи',
    isSendNotificationOpen: false,
    msgObj: '',
    msgText: '',
    addButton: false,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();

    const accessParams = { module: 'settings', object: 'alerts' };
    const tabMap = {
      'tab_common_information_alerts': '1',
      'tab_users_alerts': '2',
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("оповещения", `оповещение ${this.body.name}`);
    },
    getById() {
      MailNotificationApi.getById(this.id).then((res) => {
        this.body = res;
        this.executorUserList = res.users;
        suffix.next(' ' + res.name);
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      console.log(characteristics)
      MailNotificationApi.save(
          characteristics
      ).then(() => {
        this.getById();
      });
    },
    deleteObject() {
      MailNotificationApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    openSendNotification() {
      this.isSendNotificationOpen = true
    },
    closeDialog() {
      this.isSendNotificationOpen = false;
      this.msgText = '';
      this.msgObj = '';
    },
    sendMessage() {
      let data = {
        mailingListId: this.id,
        subj: this.msgObj,
        textMessage: this.msgText
      }
      MailNotificationApi.sendMassNotification(data)
          .then((res) => {
            this.closeDialog();
          })
    },
    deleteMailNotification(e) {
      MailNotificationApi.deleteMailNotificationToUser(this.id, e)
          .then((res) => {
            this.getById();
          })
    }
  }
})
