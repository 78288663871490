import Axios from 'axios-observable';
import {Observable} from "rxjs/dist/types";
import {engineRestUrl, send, url} from "@/helpers/api";

export const ProcessApi = {
  applyFilter2(data: any, size = 10, page = 0): Observable<any> {
    return Axios.request({
      method: 'post',
      url: `${url}/process/entity-definition/filter`,
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getById(id) {
    return send({
      url: `${url}` + `/process/entity-definition/${id}`,
      method: "get",
    });
  },
  save(data: any) {
    return send({
      url: `${url}` + `/process/entity-definition/save`,
      method: "post",
      data,
    });
  },
  delete(id: string) {
    return send({
      url: `${url}` + `/process/entity-definition/${id}`,
      method: "delete"
    });
  },
  getProcessDefinition(key = ''): any {
    const url = `${engineRestUrl}/process-definition?latestVersion=true`;
    //+ (key !== '' ? `&keyLike=${key}` : '');
    return send({
      url: url,
      method: "get"
    });
  },
  getEntityType(name = ''): any {
    return send({
      url: `${url}/classifiers/incident-type`,
      method: "get",
      params: {
        name
      },
    });
  },
};
