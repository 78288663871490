import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";
import {send, url} from "@/helpers/api";

export const SystemApi = {
    applyFilter(data: any, size = 10, page = 0): Observable<any> {
        return Axios.request({
            method: 'post',
            url: `${url}/systemLogo/filter`,
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/systemLogo/${id}`,
            method: "get",
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/systemLogo/${id}`,
            method: "delete",
        });
    },
    save(data) {
        return send({
            url: `${url}` + `/systemLogo/save`,
            method: "post",
            data,
        });
    },
    getDefault() {
        return send({
            url: `${url}` + `/systemLogo/default`,
            method: "get",
        });
    },
}