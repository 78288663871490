import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63e89e54"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "chart",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "flex justify-content-end" }
const _hoisted_3 = { style: {"font-size":"14px","margin-right":"9.7%","font-family":"'Inter', sans-serif","color":"rgb(116,124,132)"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chart = _resolveComponent("v-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_chart, {
      onDataZoom: _ctx.handleTimePeriod,
      loading: _ctx.isLoading,
      ref: "chart",
      autoresize: "",
      loadingOptions: _ctx.loadingOptions,
      option: _ctx.option,
      style: {"height":"500px","width":"100%"}
    }, null, 8, ["onDataZoom", "loading", "loadingOptions", "option"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.startDate) + " - " + _toDisplayString(_ctx.endDate), 1)
    ])
  ]))
}