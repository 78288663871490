
import {defineComponent} from "vue";
import ViewBase from "@/classes/ViewBase.vue";
import {CollectionAgentsApi} from "@/api/collection-agents.api";
import {MeasureApi} from "@/api/measure.api";
import MeasureCharacteristics from "@/components/measure/MeasureCharacteristics.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import EditForm from "@/components/base/EditForm.vue";
import {suffix} from "@/consts/nameSuff";

export default defineComponent({
  components: {MeasureCharacteristics, EditForm,DeleteDialog},
  mixins: [ViewBase],
  data: () => ({
    body: {},
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm(
        "Закрыть сайт? Возможно внесенные изменения не сохранятся!"
    );
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    getById() {
      MeasureApi.getById(this.id)
          .then((res) => {
            this.body = res;
            suffix.next(' ' + res.name);
          })
          .finally(() => {
            this.$refs.characteristics.cleanWarning();
          });
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog(
          "единицы измерения",
          `еденицу измерения ${this.body.name}`
      );
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = Object.assign(
          {},
          this.$refs.characteristics.data
      );
      MeasureApi.save(characteristics).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.getById();
      });
    },
    deleteObject() {
      MeasureApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  }
})
