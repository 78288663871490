
import {defineComponent} from "vue";
import ViewBase from "@/classes/ViewBase.vue";
import {NoticeTemplateApi} from "@/api/notice-template.api";
import NoticeTemplateCharacteristics from "@/components/notice-template/NoticeTemplateCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {suffix} from "@/consts/nameSuff";

export default defineComponent({
  components: {NoticeTemplateCharacteristics , EditForm, DeleteDialog},
  mixins: [ViewBase],
  data: () => ({
    body: {},
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm(
        "Закрыть сайт? Возможно внесенные изменения не сохранятся!"
    );
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    getById(){
      console.log(this.id)
      NoticeTemplateApi.getById(this.id)
      .then((res) => {
        this.body = res
        suffix.next(' ' + res.name)
      })
          .finally(() => {
            this.$refs.characteristics.cleanWarning();
          });
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog(
          "шаблона уведомлений",
          `шаблон уведомлений ${this.body.name}`
      );
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = Object.assign(
          {},
          this.$refs.characteristics.data
      );
      NoticeTemplateApi.save(characteristics).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: "bottomRight",
          duration: 3,
        });
        this.getById();
      });
    },
    deleteObject() {
      NoticeTemplateApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  }
})
