import {send, url} from "@/helpers/api";

export const ClickhouseApi = {
    getMonitoringData(
        itemId: string,
        period: string,
        startDate: string,
        endDate: string,
        monitoringObjectId: string,
        avgType?: string
    ) {
        /*TODO: вынести zoneId в Utils*/
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        const queryPeriod = startDate
            ? `&dateStart=${startDate}&dateEnd=${endDate}&zoneId=${zoneId}&agrType=${avgType}`
            : `&period=${period.toUpperCase()}`;
        return send({
            url:
                `${url}` +
                `/metrics/clickhouse/${itemId}?monitoringObjectId=${monitoringObjectId}${queryPeriod}`,
            method: "get",
        });
    },
    getIndicatorData(
        itemId: string,
        period: string,
        startDate: string,
        endDate: string,
        avgType?: string
    ) {
        /*TODO: вынести zoneId в Utils*/
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        const queryPeriod = startDate
            ? `dateStart=${startDate}&dateEnd=${endDate}&zoneId=${zoneId}&agrType=${avgType}`
            : `&period=${period.toUpperCase()}`;
        return send({
            url:
                `${url}` +
                `/indicators/clickhouse/${itemId}?${queryPeriod}`,
            method: "get",
        });
    },
}
