import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pl-4 pr-4 view-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MonitoringObjectStatusesCharacteristics = _resolveComponent("MonitoringObjectStatusesCharacteristics")!
  const _component_EditForm = _resolveComponent("EditForm")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_delete_dialog = _resolveComponent("delete-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change(_ctx.activeKey), _ctx.handleTabChange, _ctx.changeTab(_ctx.activeKey)))
      }, {
        default: _withCtx(() => [
          (_ctx.hasAccessToRead('dictionary','monitoring_object_statuses', 'tab_common_information_monitoring_object_statuses'))
            ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: "1",
                tab: "Общая информация"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_EditForm, {
                    onSave: _ctx.save,
                    onReject: _ctx.cancel,
                    onDelete: _ctx.openDeleteDialog,
                    data: {id: _ctx.body.id,
         isHasSave: _ctx.hasAccessToSave('dictionary','monitoring_object_statuses', 'tab_common_information_monitoring_object_statuses'),
         isDel: _ctx.hasAccessToDelete('dictionary','monitoring_object_statuses', 'tab_common_information_monitoring_object_statuses')}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MonitoringObjectStatusesCharacteristics, {
                        ref: "characteristics",
                        body: _ctx.body
                      }, null, 8, ["body"])
                    ]),
                    _: 1
                  }, 8, ["onSave", "onReject", "onDelete", "data"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["activeKey"])
    ]),
    _createVNode(_component_delete_dialog, {
      ref: "delete",
      onUpdateDialog: _ctx.deleteObject
    }, null, 8, ["onUpdateDialog"])
  ], 64))
}