
import { Util } from "@/components/utils/Util";
import {mixins, Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import FilterBase from "@/classes/FilterBase.vue";
import Super from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {ClassifilesApi} from "@/api/classifires.api";
import {SupportTeamsFilterImp} from "@/components/classes/SupportTeamsFilterImp";

export default defineComponent({
  name: "SupportTeamsFilter",
  components: {
    Accordion,
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    handleVisibleChange(e1) {
      console.log(e1);
    },
    onCheckLastValue(array, name) { // only last value
      Util.onCheckLastValue(array, name);
    },
    onCheckManyValues(array, name) { // many values
      Util.onCheckManyValues(array, name);
    },
    getSupportTeamStatuses() {
      ClassifilesApi.getSupportTeamStatuses()
          .then(res => {
            this.valuesForCheckboxes.status = Util.transformToCheckboxes(res);
          });
    },
    applyFilter() {
      this.filter.statuses = (this.valuesForCheckboxes.statusArr.length === 1
          && this.valuesForCheckboxes.statusArr[0] === '')
          ? [] : this.valuesForCheckboxes.statusArr;
      this.filter.isDefault = this.valuesForCheckboxes.defaultsArr[0] === ''
          ? null : this.valuesForCheckboxes.defaultsArr[0] === 'true';
      this.filterChange(new SupportTeamsFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, ['name', 'leaderFio', 'id']);
      this.setQueryParamsArrDefault(queryParams, []);
      this.$router.push({query: queryParams});
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['name', 'leaderFio', 'id']);
      this.setCheckboxesArrFromQuery(query, ['status']);
    },
    cleanFilter() {
      this.filter = new SupportTeamsFilterImp();
      this.defaultValueFilter();
      this.setFilterFields();
      this.filterChange(new SupportTeamsFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'id') {
              this.filter.id = '';
            }
            if(f.field === 'name') {
              this.filter.name = '';
            }
            if(f.field === 'leaderFio') {
              this.filter.leaderFio = '';
            }
            if(f.field === 'status') {
              this.valuesForCheckboxes.statusArr = [''];
            }
          });
    },
  },
  data: () => ({
    filter: new SupportTeamsFilterImp(),
    valuesForCheckboxes: {
      status: [],
      statusArr: [],
      defaults: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'}
      ],
      defaultsArr: [],
    },
    filterData: {}
  }),
  mounted() {
    Promise.all([this.getSupportTeamStatuses()])
        .then(() => {
          this.defaultValueFilter();
          this.getQueryParams();
          this.setFilterFields();
          this.applyFilter();
        });
  },
  setup() {
    return {
      locale
    }
  },
})
