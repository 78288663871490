import { send, url } from "@/helpers/api";

export const SupersetSettingsApi = {
  saveSettings(data: any ): any {
    return send({
      url: `${url}` + `/superset-dashboard/settings`,
      method: "post",
      data,
      params: {

      },
    })
  },

  getSettings() {
    return send({
      url: `${url}` + `/superset-dashboard/settings`,
      method: "get",
      params: {
      }
    })
  },
}
