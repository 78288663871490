import {Field} from "@/types/fields";
import {ProcessListData} from "@/types/process";

export const processFields: Field<ProcessListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "200px"},
    {field: "entityName", sortDirections: ['descend', 'ascend'], name: "Сущность", value: true, minWidth: ""},
    {field: "entityType", sortDirections: ['descend', 'ascend'], name: "Категория", value: true, minWidth: "",
        compute: (val) => val ? val.name : ''},
    {field: "processDefinitionKey", sortDirections: ['descend', 'ascend'], name: "Процесс", value: true, minWidth: ""}
];

export const getProcessesData = () => ({
    id: null,
    entityName: "",
    entityType: { id: null, name: '' },
    processDefinitionKey: "",
});
