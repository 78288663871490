import {send, url} from "@/helpers/api";
import axios from "axios";

export const MeasureApi = {
    applyFilter(data: any, size = 10, page = 0) {
        return send({
            url: `${url}` + `/classifiers/measure`,
            method: 'post',
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        })
    },
    getById(id) {
        return send({
            url: `${url}` + `/classifiers/measure/${id}`,
            method: "get",
        });
    },
    save(data: any) {
        return send({
            url: `${url}` + `/classifiers/measure/new`,
            method: "post",
            data,
        });
    },
    delete(id: string) {
        return send({
            url: `${url}` + `/classifiers/measure/${id}`,
            method: "delete"
        });
    },
    downloadExcel(request) {
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        return axios({
            method: "post",
            url: `${url}/classifiers/measure-download?zoneId=${zoneId}`,
            data: request,
            responseType: "arraybuffer",
            headers: {
                "Accept": "application/octet-stream",
            }
        });
    },
}