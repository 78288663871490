import {Field} from "@/types/fields";
import {AuthlogListData} from "@/types/authlog";
import {formatDate} from "@/filters/date";

export const authlogFields: Field<AuthlogListData>[] = [
    {field: "login", sortDirections: ['descend', 'ascend'], name: "Логин", value: true, minWidth: "150px"},
    {field: "ip", sortDirections: ['descend', 'ascend'], name: "IP адрес", value: true, minWidth: "150px"},
    {field: "eventTime", sortDirections: ['descend', 'ascend'], name: "Дата авторизации", value: true, minWidth: "150px",        compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
        {
            return formatDate(new Date(new Date(value).getTime()).toString(), format)
        }
        ,},
]