import {Field} from "@/types/fields";
import {ContractsListData} from "@/types/contracts";

export const contractsFields: Field<ContractsListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "120px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
    {field: "number", sortDirections: ['descend', 'ascend'], name: "Номер договора", value: true, minWidth: ""},

]
export const getContractsData = () => ({
    id: null,
    name: "",
    number: '',
    status: {name: '', id: null},
    contractEnd: '',
    contractStart: ''
})