
import {defineComponent} from "vue";
import ViewBase from "@/classes/ViewBase.vue";
import {MailApi} from "@/api/mail.api";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import EditForm from "@/components/base/EditForm.vue";
import MailCharacteristics from "@/components/mail/MailCharacteristics.vue";
import MailSendDialog from "@/components/mail/MailSendDialog.vue";
import {suffix} from "@/consts/nameSuff";

export default defineComponent({
  name: "MailView",
  components: {MailCharacteristics, DeleteDialog,EditForm,MailSendDialog},
  mixins: [ViewBase],
  data: () => ({
    body: null,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("почты", `почты ${this.body.id}`);
    },
    openSendDialog() {
      this.$refs.send.openDialog("почты", `почты ${this.body.id}`);
    },
    getById() {
      MailApi.getById(this.id).then((res) => {
        this.body = res;
        suffix.next(' ' + res.id);
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      console.log(characteristics)
      if (characteristics.password === '********') {
        delete characteristics.password
      }

      MailApi.save(
          characteristics
      ).then(() => {
        this.getById();
      });
    },
    deleteObject() {
      MailApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    sendMessage(e) {
     MailApi.sendMessages({to: e}).then(() => {
       this.$notification.open({
         message: "Сообщение отправлено",
         style: {
           width: "330px",
           marginLeft: `${400 - 330}px`,
           fontSize: "14px",
         },
         placement: 'bottomRight',
         duration: 3,
       });
       this.getById()
     })
    }
  },
})
