import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-element-container flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isVisible) = $event)),
    onChange: _cache[3] || (_cache[3] = ($event: any) => {_ctx.$emit('update:visible', $event);}),
    class: "custom-class",
    width: "640px",
    title: "Фильтры",
    placement: "right"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "primary",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.applyFilterBtn && _ctx.applyFilterBtn(...args)))
        }, "Применить"),
        _createElementVNode("button", {
          class: "second mar-l-16",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cleanFilter && _ctx.cleanFilter(...args)))
        }, "Сбросить")
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}