
import { defineComponent } from "vue";
import MetricsGroupsCharacteristics from "@/components/metrics-groups/MetricsGroupsCharacteristics.vue";
import {MetricGroupApi} from "../../api/metric-group.api";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import MetricList from "@/components/metrics/MetricList.vue";
import MetricsIndicatorsFilter from "@/components/metrics-indicators/MetricsIndicatorsFilter.vue";
import MetricIndicatorList from "@/components/metrics-indicators/MetricIndicatorList.vue";
import MetricsFilter from "@/components/metrics/MetricsFilter.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "MetricsGroupsView",
  components: {
    MetricsGroupsCharacteristics,
    EditForm,
    TableSettings,
    DeleteDialog,
    MetricList,
    MetricsFilter,
    MetricIndicatorList,
    MetricsIndicatorsFilter
  },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    selectorData: [{ value: "Типы метрик", menuCode: 'dictionary', childMenuCode: 'types_of_metrics' }],
    currentSelect: "Типы метрик",
    relatedObjectCannotBeAddedList: [],
    relatedTitle: 'группы метрик',
    type: 'metric-group',
    currentUser
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
    const accessParams = { module: 'dictionary', object: 'groups_of_metrics' };
    const tabMap = {
      'tab_common_information_metric_group': '1',
      'tab_relation_metric_group': '2',
    };

    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    handleDeleteRelatedObject(id) {
      if(this.currentSelect ==='Типы метрик') {
        MetricGroupApi.deleteMetric(this.id, id)
          .then(res => {
            this.applyCurrentRelatedFilter();
          })
      }
    },
    applyCurrentRelatedFilter() {
      this.keyForRelatedRequest = this.getKeyForRelatedRequest();
      this.isLoad = false;
      this.currentRelatedObject.get({
        ...this.currentRelatedObject.filter,
         metricGroupsIds: [this.$route.params.id],
      }, this.currentRelatedObject.size, this.currentRelatedObject.page)
        .then(res => {
          this.currentRelatedObject.list = [];
          this.currentRelatedObject.totalCount = res.totalCount;
          setTimeout(() => {
            this.currentRelatedObject.list = res.data;
          }, 1);
          this.isLoad = true;
        });
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("группы метрик", `группу метрик ${this.body.name}`);
    },
    getById() {
      MetricGroupApi.getById(this.id).then((res) => {
        this.body = res;
        suffix.next(' ' + res.name);
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();

      MetricGroupApi.save(
          characteristics
      ).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.getById();
      });
    },
    deleteObject() {
      MetricGroupApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  },
});
