import { send, url } from "@/helpers/api";
import axios from "axios";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";

export const MonitoringObjectsApi = {
  applyFilter(data: any, size = 25, page = 0) {
    return send({
      url: `${url}` + `/monitoring-object/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilter2(data: any, size = 25, page = 0): Observable<any> {
    return Axios.request({
      url: `${url}` + `/monitoring-object/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getById(id) {
    return send({
      url: `${url}` + `/monitoring-object/${id}`,
      method: "get",
    });
  },
  delete(id) {
    return send({
      url: `${url}` + `/monitoring-object/${id}`,
      method: "delete",
    });
  },
  save(data) {
    return send({
      url: `${url}` + `/monitoring-object/save`,
      method: "post",
      data,
    });
  },
  getStatuses() {
    return send({
      url: `${url}` + `/classifiers/monitoring-object/statuses`,
      method: "get",
    });
  },
  getParents() {
    return send({
      url: `${url}` + `/monitoring-object/parent`,
      method: "get",
    });
  },
  getTypesList(name = "", size = 100, supportTeamId?): any {
    const data = {name};
    const params = {size: size.toString()};
    if (supportTeamId) {
      const key = "supportTeamId";
      params[key] = supportTeamId;
    }
    return send({
      url: `${url}` + `/classifiers/monitoring-object/filter`,
      method: "post",
      data,
      params: params,
    });
  },
  getProducerList(name?, size?): any {
    const params = {};
    if (name) {
      params[name] = name;
    }
    if (size) {
      params[size] = size;
    }

    return send({
      url: `${url}` + `/classifiers/brands`,
      method: "get",
      params: params,
    });
  },
  getModelList(name = '', size = 100, brandId = null, page = 0): any {
    const urlP = `/model?size=${size}&page=${page}`
    + (brandId ? `&brandId=${brandId}` : '') + (brandId ? `&name=${name}` : '');
    return send({
      url: `${url}` + urlP,
      method: "get",
    });
  },
  getModelListWithSomeBrands(name = '', size = 100, brands = [], page = 0): any {
    const urlP = `/classifiers/models?size=${size}&page=${page}`
    return send({
      url: `${url}` + urlP,
      method: "post",
      data: {
        brands,
        name
      }
    });
  },
  getMetrics(id) {
    return send({
      url: `${url}/monitoring-object/${id}/metric-groups?size=100`,
      method: "post",
      data: {
        sortParams: [{sortFieldName: "name", direction: "desc"}]
      }
    })
  },
  deleteMonitoringObjectService(objectId, serviceId) {
    return send({
      url: `${url}` + `/monitoring-object/service?objectItemId=${objectId}&serviceId=${serviceId}`,
      method: "delete",
    });
  },
  deleteMonitoringObjectHealthModelRule(objectId, serviceId) {
    return send({
      url: `${url}` + `/monitoring-object/healthmodel-template?monitoringObjectId=${objectId}`,
      method: "delete",
    });
  },
  relateMonitoringObjectToServices(services, objects) {
    return send({
      url: `${url}` + `/monitoring-object/service`,
      method: "post",
      data: {services: services, monitoringObjectId: objects},
    });
  },
  relateMonitoringObjectToArea(areaId, monitoringObjects) {
    return send({
      url: `${url}` + `/monitoring-object/area`,
      method: "post",
      data: {areaId: +areaId, monitoringObjects: monitoringObjects},
    });
  },
  deleteMonitoringObjectArea(monitoringObjectId, areaId) {
    return send({
      url: `${url}` + `/monitoring-object/area?monitoringObjectId=${monitoringObjectId}&areaId=${areaId}`,
      method: "delete",
    });
  },
  addParentMonitoringObject(parentId, childIds) {
    return send({
      url: `${url}` + `/monitoring-object/parent`,
      method: "post",
      data: {parentMonitoringObjectId: parentId, childMonitoringObjectIds: childIds},
    });
  },
  deleteParentMonitoringObject(childId) {
    return send({
      url: `${url}` + `/monitoring-object/parent?childId=${childId }`,
      method: "delete",
    });
  },
  deleteContractFromMonitoringObject(contractId,monitoringObjectId) {
    return send({
      url: `${url}` + `/contracts/${contractId}/monitoring-objects/${monitoringObjectId}`,
      method: "delete",
    });
  },
  downloadExcel(request) {
    const zoneId = encodeURIComponent(
        "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return axios({
      method: "post",
      url: `${url}/monitoring-object/download?zoneId=${zoneId}`,
      data: request,
      responseType: "arraybuffer",
      headers: {
        "Accept": "application/octet-stream",
      }
    });
  },
  archive(id) {
    return send({
      url: `${url}` + `/monitoring-object/archive?id=${id}`,
      method: "delete",
    });
  },
  unArchive(id) {
    return send({
      url: `${url}` + `/monitoring-object/unarchive/?id=${id}`,
      method: "post",
    });
  },
  getHealthModelTemplates(id){
    return send({
      url: `${url}` + `/monitoring-object/${id}/healthmodel-templates/applicable`,
      method: "get",
    });
  },
  relateMonitoringObjectToContract(contractId, monitoringObjects): any {
    return send({
      url: `${url}` + `/contracts/monitoring-objects`,
      method: "post",
      data: {
        contractId: contractId, monitoringObjects: monitoringObjects
      }
    });
  }
}
