
import {Options, Vue} from "vue-class-component";
import NSIBase from "@/classes/NSIBase.vue";
import MailList from "@/components/mail/MailList.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import MailFilter from "@/components/mail/MailFilter.vue";
import {MailListData} from "@/types/mail";
import {reactive} from "vue";
import {getMailData, MailFields} from "@/consts/fields/mail.fields";
import {Field} from "@/types/fields";
import {MailFilterImp} from "@/components/classes/MailFilterImp";
import {MailApi} from "@/api/mail.api";


@Options({
  components: {MailFilter, MailList, TableSettings,},
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as MailListData[],
      search: '',
      selectedList: [],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      userId: null,
      incidentKeys: "" as keyof MailListData,
      fields: reactive(MailFields.slice() as Field<MailListData>[]),
      filter: new MailFilterImp(),
      prepareData: getMailData()
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    routeToAddMail() {
      this.$router.push("/settings/mail/add");
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    resultMethod(res) {
      this.list = res.data;
      this.totalCount = res.totalCount;
      this.isLoad = true;
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    searchMethod(_) {
      const filter  = this.defineFilterData
      filter[this.exceptKey] = +this.$route.query.relatedId;
      return MailApi
          .applyFilter(_ ? {containsValue: this.search, sortParams: this.filter.sortParams}
              : this.filter, this.size, this.page);
    },
  },
  mounted() {
    console.log(123)
    this.applyFilter();
  }

})
export default class Mail extends Vue{

}
