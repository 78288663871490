import {engineRestUrl, send, urlAdmin, url} from "@/helpers/api";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";

export const SettingsApi = {
  applyFilter2(data: any, size = 10, page = 0): Observable<any> {
    return Axios.request({
      method: 'post',
      url: `${url}/view-settings/classes/filter`,
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  get(dtoObjectClass): any {
    return send({
      url: `${url}/view-settings`,
      method: "get",
      params: {
        dtoObjectClass
      }
    });
  },
  save(data): any {
    return send({
      url: `${url}/view-settings/save`,
      method: "post",
      data
    });
  },
  getFields(dtoObjectClass): any {
    return send({
      url: `${url}/view-settings/classes/fields?dtoObjectClass=${dtoObjectClass}`,
      method: "get"
    });
  },
}
