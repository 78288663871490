import {Field} from "@/types/fields";
import {AreaTypeListData} from "@/types/area-type";

export const areaTypesFields: Field<AreaTypeListData>[] = [
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""}
];

export const getAreaTypesData = () => ({
    defaultName: false,
    name: ""
});
