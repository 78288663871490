import {send, url} from "@/helpers/api";

export const DashboardsApi = {
  getAreaByStatusStatistics(region?, clientId?) {
    if (clientId) {
      if (region) {
        return send({
          url: `${url}/areas/statistics-areasByStatus?clientId=${clientId}limit=10`,
        });
      } else {
        return send({
          url: `${url}/areas/statistics-areasByStatus?&clientId=${clientId}&limit=10`,
        });
      }
    } else {
      if (region) {
        return send({
          url: `${url}/areas/statistics-areasByStatus?${region}limit=10`,
        });
      } else {
        return send({
          url: `${url}/areas/statistics-areasByStatus?&limit=10`,
        });
      }
    }
  },
  getMonitoringObjectByStatusStatistics(data = {}, region?, clientId?) {
    if (clientId) {
      if (region) {
        return send({
          method: 'post',
          url: `${url}/areas/statistics-monitoringObjectByStatus?clientId=${clientId}`,
          data
        });
      } else {
        return send({
          method: 'post',
          url: `${url}/areas/statistics-monitoringObjectByStatus?&clientId=${clientId}`,
          data
        });
      }
    } else {
      if (region) {
        return send({
          method: 'post',
          url: `${url}/areas/statistics-monitoringObjectByStatus?${region}`,
          data
        });
      } else {
        return send({
          method: 'post',
          url: `${url}/areas/statistics-monitoringObjectByStatus`,
          data
        });
      }
    }
  },
  getIncidentsByCategory(dateStart, dateEnd, region?, clientId?) {
      /*TODO; refactor*/
    dateStart = dateStart.format('DD.MM.yyyy');
    dateEnd = dateEnd.format('DD.MM.yyyy');
    if (clientId) {
      if (region) {
        return send({
          url: `${url}/areas/statistics-incidentsByCategory?dateStart=${dateStart}&dateEnd=${dateEnd}&${region}&clientId=${clientId}limit=10`,
        });
      } else {
        return send({
          url: `${url}/areas/statistics-incidentsByCategory?dateStart=${dateStart}&dateEnd=${dateEnd}&clientId=${clientId}&limit=10`,
        });
      }
    } else {
      if (region) {
        return send({
          url: `${url}/areas/statistics-incidentsByCategory?dateStart=${dateStart}&dateEnd=${dateEnd}&${region}limit=10`,
        });
      } else {
        return send({
          url: `${url}/areas/statistics-incidentsByCategory?dateStart=${dateStart}&dateEnd=${dateEnd}&limit=10`,
        });
      }
    }
  },

  getRegionStats(data, regionName = '', size = 10, page = 0) {
    return send({
      method: 'post',
      url: `${url}/areas/regions/service-stats`,
      params: {
        size,
        page,
        regionName
      },
      data
    })
  },

  getWidgets() {
    return send({
          method: 'get',
          url: `${url}/classifiers/widget-settings`
        })
  },

  saveWidgets(data) {
    return send({
          method: 'post',
          url: `${url}/classifiers/widget-settings`,
          data
        })
  },
  getSupersetDashboard() {
    return send({
      method: 'get',
      url: `${url}/superset-dashboard`
    })
  }
};
