import { send, url } from '@/helpers/api'
import { Observable } from 'rxjs/dist/types'
import Axios from 'axios-observable'

export const UrgencyApi = {
  applyFilter(data: any, size = 10, page = 0) {
    return send({
      url: `${url}` + `/classifiers/urgency/filter`,
      method: 'post',
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    })
  },
  applyFilter2(data: any, size = 25, page = 0): Observable<any> {
    return Axios.request({
      url: `${url}` + `/classifiers/urgency/filter`,
      method: 'post',
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    })
  },
  save(data: any) {
    return send({
      url: `${url}` + `/classifiers/urgency/save`,
      method: 'post',
      data,
    })
  },
  delete(id) {
    return send({
      url: `${url}` + `/classifiers/urgency/${id}`,
      method: 'delete',
    })
  },
  getById(id) {
    return send({
      url: `${url}` + `/classifiers/urgency/${id}`,
      method: 'get',
    })
  }
}
