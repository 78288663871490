import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "p-d-flex",
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarMenu = _resolveComponent("SidebarMenu")!
  const _component_UpperMenu = _resolveComponent("UpperMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SidebarMenu, {
      isOpened: _ctx.isOpened,
      "onUpdate:isOpened": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpened) = $event))
    }, null, 8, ["isOpened"]),
    _createElementVNode("div", {
      class: _normalizeClass(["main-content-container", { opened: _ctx.isOpened }])
    }, [
      _createVNode(_component_a_config_provider, { locale: _ctx.locale }, {
        default: _withCtx(() => [
          _createVNode(_component_UpperMenu, {
            title: _ctx.title,
            hasBackButton: _ctx.hasBackButton,
            class: "mb-4"
          }, null, 8, ["title", "hasBackButton"]),
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["locale"])
    ], 2)
  ]))
}