import {send, url} from "@/helpers/api";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";
import axios from "axios";


export const MetricApi = {
    applyFilter(data: any, size = 10, page = 0) {
        return send({
            url: `${url}` + `/metrics/filter`,
            method: 'post',
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        })
    },
    applyFilter2(data: any, size = 10, page = 0): Observable<any> {
        return Axios.request({
            method: 'post',
            url: `${url}` + `/metrics/filter`,
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/metrics/metric/${id}`,
            method: "get",
        });
    },
    save(data: any) {
        return send({
            url: `${url}` + `/metrics`,
            method: "post",
            data,
        });
    },
    edit(data: any) {
        return send({
            url: `${url}` + `/metrics/metric`,
            method: "post",
            data,
        });
    },
    delete(id: string) {
        return send({
            url: `${url}` + `/metrics/${id}?id=${id}`,
            method: "delete"
        });
    },
    downloadExcel(request) {
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        return axios({
            method: "post",
            url: `${url}/metrics/download?zoneId=${zoneId}`,
            data: request,
            responseType: "arraybuffer",
            headers: {
                "Accept": "application/octet-stream",
            }
        });
    },
    getEquipmentsMetric(data: any) {
        return send({
            url: `${url}` + `/metrics/equipment/filter`,
            method: "post",
            data,
        });
    },
    relateToMetricGroup(relatedId, selectedList) {
        return send({
            url: `${url}` + `/metric-groups/metric`,
            method: "post",
            data: {
                metricGroupId: relatedId,
                metrics: selectedList
            }
        })
    },
    addMetricTypeToMonitoringObjectType(metricTypeId: number, monitoringObjectTypes: string[]) {
        return send({
            url: `${url}` + `/metrics/monitoring-object-type-metric-type`,
            method: "post",
            data: {
                metricTypeId,
                monitoringObjectTypes
            },
        });
    },
    deleteMetricTypeFromMonitoringObjectType(metricTypeId , monitoringObjectTypeId ) {
        return send({
            url: `${url}` + `/metrics/${metricTypeId}/monitoring-object-type-metric-type/${monitoringObjectTypeId}`,
            method: "delete",
        });
    },

}
