
import {defineComponent} from "vue";
import ViewBase from "@/classes/ViewBase.vue";
import {MetricTagApi} from "@/api/metric-tag.api";
import MetricTagCharacteristics from "@/components/metric-tag/MetricTagCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {suffix} from "@/consts/nameSuff";

export default defineComponent({
  components: {MetricTagCharacteristics, EditForm, DeleteDialog},
  mixins: [ViewBase],
  data: () => ({
    body: {},
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm(
        "Закрыть сайт? Возможно внесенные изменения не сохранятся!"
    );
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    getById() {
      MetricTagApi.getById({id: this.id})
          .then((res) => {
            this.body = res.data[0];
            suffix.next(' ' + res.data[0].name)
          })
          .finally(() => {
            this.$refs.characteristics.cleanWarning();
          });
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog(
          "категории метрик",
          `категорию метрик ${this.body.name}`
      );
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = Object.assign(
          {},
          this.$refs.characteristics.data
      );
      MetricTagApi.save(characteristics).then(() => {
        this.getById();
      });
    },
    deleteObject() {
      MetricTagApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  }
})
