
import {Options, Vue} from "vue-class-component";
import CalculationRulesList from "@/components/calculation-rules/CalculationRulesList.vue";
import {CalculationRuleApi} from "@/api/calculationRule.api";
import {Field} from "@/types/fields";
import TableSettings from "@/components/base/TableSettings.vue";
import {CalculationRulesFilterImp} from "@/components/classes/CalculationRulesFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {CalculationRuleListData} from "@/types/сalculation-rule";
import {calculationRulesFields, getCalculationRuleData} from "@/consts/fields/calculation-rule.fields";
import CalculationRulesFilter from "@/components/calculation-rules/CalculationRulesFilter.vue";
import {calculationRuleDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";

@Options({
  components: {
    CalculationRulesList,
    TableSettings,
    CalculationRulesFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      filter: new CalculationRulesFilterImp(),
      totalCount: 0,
      size: 20,
      page: 1,
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      fields: null,
      list: [] as CalculationRuleListData[],
      prepareData: getCalculationRuleData()
    };
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    routeToAddCalculationRule() {
      this.$router.push("/directory/calculation-rules/add");
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      CalculationRuleApi
              .downloadExcel(filter)
              .then((res) => {
                this.$notification.open({
                  message: new TextDecoder().decode(res.data),
                  style: {
                    width: '600px',
                    marginLeft: `${400 - 600}px`,
                    fontSize: "14px",
                  },
                  placement: 'bottomRight',
                  duration: 3,
                });
              })
              .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFilter() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    updateList(value) {
      let column = '';
      if(value.columnKey === 'incidentNumber') {
        column = 'id'
      } else {
        column = value.columnKey;
      }

      let order = '';
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    searchMethod(_) {
      return CalculationRuleApi
          .applyFilter2(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    calculationRuleDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields =
              setVisibleToFieldsInTable(calculationRulesFields, settings.fields) as Field<CalculationRuleListData>[];
        });
  },
})
export default class CalculationRules extends Vue {
  search: string;
  list!: CalculationRuleListData;
  filter: CalculationRulesFilterImp;
}
