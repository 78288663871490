import {Field} from "@/types/fields";
import {UnitListData} from "@/types/unit";

export const unitFields: Field<UnitListData>[] = [
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""}
];


export const getUnitData = () => ({
    name: ""
});
