
import {defineComponent} from "vue";
import MonitoringObjectCharacteristics from "@/components/monitoring-objects/MonitoringObjectCharacteristics.vue";
import Graphic from "@/components/base/Graphic.vue";
import moment from "moment";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import {ClickhouseApi} from "@/api/clickhouse";
import MonitoringBase from "@/classes/MonitoringBase.vue";
import MetricsIndicatorsFilter from "@/components/metrics-indicators/MetricsIndicatorsFilter.vue";
import MetricIndicatorList from "@/components/metrics-indicators/MetricIndicatorList.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import ServiceList from "@/components/services/ServiceList.vue";
import ServicesFilter from "@/components/services/ServicesFilter.vue";
import MonitoringObjectsList from "@/components/monitoring-objects/MonitoringObjectsList.vue";
import MonitoringObjectsFilter from "@/components/monitoring-objects/MonitoringObjectsFilter.vue";
import IncidentsFilter from "@/components/incidents/IncidentsFilter.vue";
import IncidentList from "@/components/incidents/IncidentList.vue";
import MetricList from "@/components/metrics/MetricList.vue";
import MetricsFilter from "@/components/metrics/MetricsFilter.vue";
import ArchiveDialog from "@/components/dialogs/ArchiveDialog.vue";
import {ServiceApi} from "@/api/service.api";
import UpperMenu from "@/components/ui/UpperMenu.vue";
import {suffix} from "@/consts/nameSuff";
import MonitoringObjectServiceInformation from "@/components/monitoring-objects/MonitoringObjectServiceInformation.vue";
import {currentUser} from "@/consts/currentUser";

export type Period = "day" | "month" | "week" | "year" | "custom";
export default defineComponent({
  name: "MonitoringObjectsView",
  mixins: [ViewBase, MonitoringBase],
  components: {
    MonitoringObjectServiceInformation,
    MonitoringObjectCharacteristics,
    Graphic,
    DeleteDialog,
    MetricsIndicatorsFilter,
    MetricIndicatorList,
    MetricList,
    MetricsFilter,
    ServicesFilter,
    ServiceList,
    MonitoringObjectsList,
    MonitoringObjectsFilter,
    IncidentList,
    IncidentsFilter,
    TableSettings,
    ArchiveDialog,


  },
  data: () => ({
    body: {},
    id: 0,
    currentUser,
    selectorData: [
      {value: "Типы метрик", menuCode: null , childMenuCode: null},
      {value: "Услуги", menuCode: 'monitoring' , childMenuCode: 'service'},
      {value: "Заявки", menuCode: 'incidents' , childMenuCode: null},
      {value: "Объекты", menuCode: 'monitoring' , childMenuCode: 'object'},
    ],
    currentSelect: "Типы метрик",
    type: 'monitoring-objects'
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
    this.getMetricGroup();

    const accessParams = { module: 'monitoring', object: 'object' };
    const tabMap = {
      'tab_common_information_object': '1',
      'tab_service_information_object': '2',
      'tab_monitoring_object': '3',
      'tab_relation_object': '4'
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null)
    if (!this.$refs?.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openArchiveDialog(){
      if (this.body.deleted) {
        this.$refs.archive.openDialog("Восстановление объекта",
            `восстановить из архива  объект ${this.body.name}` , "Восстановить");
      }
      else {
        this.$refs.archive.openDialog("Архивирование объекта",
            `архивировать  объект ${this.body.name} `, "В архив");
      }
    },
    formatValue(value) {
      return  Number(value).toFixed(2)
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    archiveObject() {
      if (this.body.deleted) {
        MonitoringObjectsApi.unArchive(this.id).then(() => {
          this.$notification.open({
            message: "Восстановлен из архива",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
          this.getById();
        });
      }
      else {
        MonitoringObjectsApi.archive(this.id).then(() => {
          this.$notification.open({
            message: "Архивирован",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
          this.getById();
        });
      }
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("объекта", `объект ${this.body.name}`);
    },
    getById() {
      MonitoringObjectsApi.getById(this.id).then((res) => {
        this.body = res;
        suffix.next(' ' + res.name)
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      characteristics.customAttributes = this.$refs.characteristics.data.customAttributes.map((res) => {
        if (res.type === "date" && res.value === null) {
          res.value = ''
        }
        return res
      })
      characteristics.commonAttributes = this.$refs.characteristics.data.commonAttributes.map((res) => {
        if (res.type === "date" && res.value === null) {
          res.value = ''
        }
        return res
      })
      MonitoringObjectsApi.save(characteristics)
        .then(() => {
          this.getById();
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    deleteObject() {
      MonitoringObjectsApi.delete(this.id).then((res) => {
        this.$router.go(-1);
      });
    },
    getMetricGroup() {
      MonitoringObjectsApi.getMetrics(this.id).then((res) => {
        this.metrics = this.sourceMetrics = res;
        this.setDefaultMetrics();
      });
    },
    getMonitoringData({ id, name, instanceName, monitoringObjectId }) {
      this.isLoadingGraphic = true;
      if (instanceName) {
        ClickhouseApi.getIndicatorData(
            instanceName,
            "day",
            this.period[0].format("DD.MM.yyyy HH:mm:ss"),
            this.period[1].format("DD.MM.yyyy HH:mm:ss"),
            ""
        ).then((res) => this.computeMonitoringData({ res, id, name }));
        return;
      }
      ClickhouseApi.getMonitoringData(
          id,
          "day",
          this.period[0].format("DD.MM.yyyy HH:mm:ss"),
          this.period[1].format("DD.MM.yyyy HH:mm:ss"),
          monitoringObjectId.toString()
      ).then((res) => this.computeMonitoringData({ res, id, name }));
    },
    findDefaultMetric() {
      const defaultMetric =
          this.metrics.reduce(
              (prev, current) => prev.concat(current.metrics),
              []).find((metric) => metric.name === "Доступность");
      if(!defaultMetric) {
        return;
      }
      this.selectedMetrics.push(defaultMetric)
    },
  },
});
