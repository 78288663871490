import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83a60570"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "p-input-icon-left mb-3 large-input" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "cell-routing-container" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "inner-table-cell-skeleton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_table_column = _resolveComponent("a-table-column")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_a_input, {
          value: _ctx.search,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          type: "text",
          class: "input-base clear-icon-v",
          placeholder: "Поиск по наименованию",
          "allow-clear": ""
        }, null, 8, ["value"])
      ])
    ]),
    _createElementVNode("div", {
      id: 'list_' + _ctx.id
    }, [
      _createVNode(_component_a_table, {
        scroll: { x: 'calc(100vw - 300px)', y: 'calc(100vh - 300px)' },
        "data-source": _ctx.list,
        pagination: false,
        onChange: _ctx.handleTableChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
            return (_openBlock(), _createBlock(_component_a_table_column, {
              key: field.field,
              "sort-directions": field.sortDirections ? field.sortDirections : [],
              "default-sort-order": field.field === 'availability' ? 'ascend' : null,
              sorter: (a, b) => _ctx.sort(a, b),
              title: field.name,
              minWidth: field.minWidth ? field.minWidth : 'auto',
              class: "table-column"
            }, {
              default: _withCtx(({ record }) => [
                (_ctx.isLoad)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        (!!field.compute)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["inner-table-cell", 
                    !!field.getClass
                      ? { [field?.getClass(record)]: true }
                      : null
                  ])
                            }, _toDisplayString(field.compute(record[field.field])), 3))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: _normalizeClass(["inner-table-cell", 
                    !!field.getClass
                      ? { [field?.getClass(record)]: true }
                      : null
                  ])
                            }, _toDisplayString(record[field.field]), 3))
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_a_skeleton, {
                          active: "",
                          paragraph: { rows: 0 }
                        })
                      ])
                    ]))
              ]),
              _: 2
            }, 1032, ["sort-directions", "default-sort-order", "sorter", "title", "minWidth"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["scroll", "data-source", "onChange"]),
      _createElementVNode("button", {
        class: "primary mt-4 pt-2",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeRegionSize()))
      }, _toDisplayString(_ctx.textShowRegionsButton), 1)
    ], 8, _hoisted_3)
  ]))
}