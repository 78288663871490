import {MetricTagListData} from "@/types/metric-tag";
import {Field} from "@/types/fields";

export const metricTagFields: Field<MetricTagListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "50px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "140px"},
]
export const getMetricTagData = () => ({
    id: null,
    name: '',
})