import { Field } from "@/types/fields";
import { defineTextColorByStatus } from "@/helpers/define-status-color";
import {MonitoringObjectListData} from "@/types/monitoring-object";
import {formatDate} from "@/filters/date";

export const monitoringObjectsFields: Field<MonitoringObjectListData>[] = [
  {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "110px"},
  {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "150px"},
  {field: "area", sortDirections: ['descend', 'ascend'], name: "Местоположение", value: true, minWidth: "175px",
    compute: (val) => val ? val.name : ''},
  {field: "type", sortDirections: ['descend', 'ascend'], name: "Тип объекта", value: true, minWidth: "175px",
    compute: (val) => val ? val.name : ''},
  {field: "isActive", sortDirections: ['descend', 'ascend'], name: "Активность", value: true, minWidth: "150px",
    compute: (val) => val === true ? 'Да' : 'Нет'},
  {field: "ipAddress", sortDirections: ['descend', 'ascend'], name: "IP-адрес", value: false, minWidth: "140px"},
  {field: "systemNum", sortDirections: ['descend', 'ascend'], name: "Серийный номер", value: true, minWidth: "200px"},
  {field: "brand", sortDirections: ['descend', 'ascend'], name: "Производитель", value: true, minWidth: "150px", compute: (val) => val ? val.name: ''},
  {field: "contractName", sortDirections: ['descend', 'ascend'], name: "Договор", value: true, minWidth: "150px"},
  {field: "contractStart", sortDirections: ['descend', 'ascend'], name: "Дата начала договора",    compute: (value = null, format = "DD.MM.yyyy") =>
    formatDate(value, format) ,value: true, minWidth: "150px"},
  {field: "contractEnd", sortDirections: ['descend', 'ascend'], name: "Дата окончания договора", value: true, minWidth: "150px",
    compute: (value = null, format = "DD.MM.yyyy") =>
    formatDate(value, format)},
  {field: "model", sortDirections: ['descend', 'ascend'], name: "Модель", value: true, minWidth: "120px",
    compute: (val) => val ? val.name : ''},
  {field: "status", sortDirections: ['descend', 'ascend'], name: "Статус",
    getClass: (val) => defineTextColorByStatus(val), value: true, minWidth: "175px", compute: (val) => val ? val.name : ''},
  {field: "client", sortDirections: ['descend', 'ascend'], name: "Клиент", value: true,
    minWidth: "150px", compute: (val) => val ? val.name : ''},
  {field: "parent", sortDirections: ['descend', 'ascend'], name: "Родитель", value: true,
    minWidth: "150px", compute: (val) => val ? val.name : ''},
  {field: "deleted",sortDirections: ['descend', 'ascend'],  name: "Архивирован", value: true, minWidth: "150px",
    compute: (val) => val === true ? 'Да' : 'Нет'},
  {field: "healthModelTemplate",sortDirections: ['descend', 'ascend'],  name: "Шаблон модели здоровья", value: true, minWidth: "150px",
    compute: (val) => val?.name},
];

export const getMonitoringObjectsData = () => ({
  id: null,
  // service: { id: null, orderN: "", channelType: "" , value: "" },
  name: "",
  model: { id: null, name: "" },
  brand: { id: null, name: "" },
  status: { id: null, name: "" },
  systemNum: "",
  inventoryNum: null,
  area: {
    id: null,
    name: "",
    address: "",
    code: "",
  },
  ipAddress: "",
  type: { id: null, name: "" },
  isActive: false,
  client: {
    id: null,
    name: ""
  },
  parent: {
    id: null,
    name: ""
  },
  deleted: "",
  healthModelTemplate: {
    name: '',
    id: null
  },
  customAttributes: [],
  commonAttributes: [],
  contract: {
    id: null,
    name: "",

  }
});
