
import {reactive} from 'vue'
import {Field} from "@/types/fields";
import {Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import ProcessTemplatesList from "@/components/process-templates/ProcessTemplatesList.vue";
// import ProcessFilter from "@/components/processes/ProcessFilter.vue";
import {ProcessFilterImp} from "@/components/classes/ProcessFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {ProcessTemplatesListData} from "@/types/process-templates";
import {getProcessesTemplatesData, processTemplatesFields} from "@/consts/fields/processes-templates.fields";
import {ProcessTemplatesApi} from "@/api/process-teplates.api";

@Options({
  components: {
    TableSettings,
    Accordion,
    ProcessTemplatesList,
    // ProcessFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as ProcessTemplatesListData[],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof ProcessTemplatesListData,
      fields: reactive(processTemplatesFields.slice() as Field<ProcessTemplatesListData>[]),
      filter: new ProcessFilterImp(),
      prepareData: getProcessesTemplatesData()
    };
  },
 methods: {
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    searchMethod(_) {
      return ProcessTemplatesApi
          .applyFilter2(this.filter, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data;
      this.totalCount = res.data.length;
      this.isLoad = true;
    },
   routeToAddProcessTemplate() {
      this.$router.push("/settings/process-templates/add");
    },
  },
  mounted() {
    this.applyFilter();
  },
})
export default class ProcessTemplates extends Vue {
  list!: ProcessTemplatesListData[];
  filter: ProcessFilterImp;
}
