
import {defineComponent} from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {FileManagerApi} from "@/api/file-manager.api";

export default defineComponent({
  components: {DeleteDialog},
  mixins: [ListBase],
  data: () => ({
    id: '',
  }),
  mounted() {
  },
  methods: {
    openDeleteDialog(id, params,t) {
      console.log(t)
      this.id = id;
      this.$refs.delete.openDialog("файла", `файл ${params}`);
    },
    deleteFile() {
      FileManagerApi.delete(this.id).then(() => {
        this.onChange(this.currentPage, this.currentSize);
      });
    }
  }
})
