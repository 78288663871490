import {Field} from "@/types/fields";
import {BrandsListData} from "@/types/brands";

export const brandsFields: Field<BrandsListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "150px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
];

export const getBrandsData = () => ({
    id: null,
    name: null,
});
