import Axios from 'axios-observable';
import {Observable} from "rxjs/dist/types";
import {engineRestUrl, send, url} from "@/helpers/api";

export const ProcessTemplatesApi = {
  applyFilter2(data: any, size = 10, page = 0): Observable<any> {
    return Axios.request({
      method: 'get',
      url: `${engineRestUrl}/process-definition?latestVersion=true`,
      data,
      // params: {
      //   size: size.toString(),
      //   page: page.toString(),
      // },
    });
  },
  getProcessDefinition(id) {
    return send({
      url: `${engineRestUrl}/process-definition/key/${id}`,
      method: "get",
    })
  },
  getProcessDefinitionByID(id) {
    return send({
      url: `${engineRestUrl}/process-definition/${id}/xml`,
      method: "get",
    })
  },
  deploymentCreate(data): any {
    return send({
      url: `${engineRestUrl}/deployment/create`,
      method: "post",
      data
    });
  },
};
