import {Field} from "@/types/fields";
import {AreasResponsibilityListData} from "@/types/areas-responsibility";

export const areasResponsibilityFields: Field<AreasResponsibilityListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "120px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
    {field: "defaultName", name: "По умолчанию", value: true, minWidth: "",
        compute: (val) => val === true ? 'Да' : 'Нет'},
];

export const getAreasResponsibilityData = () =>  ({
    id: null,
    name: "",
    defaultName: false,
});
