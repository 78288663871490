import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f893fed2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncidentCharacteristics = _resolveComponent("IncidentCharacteristics")!
  const _component_EditForm = _resolveComponent("EditForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EditForm, {
      data: {addButtonDisabled: _ctx.addButtonDidabled, id: null},
      onAdd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.add()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_IncidentCharacteristics, { ref: "characteristics" }, null, 512)
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}