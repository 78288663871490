
import {reactive} from 'vue'
import {Field} from "@/types/fields";
import {Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import RepresentationsList from "@/components/representations/RepresentationsList.vue";
import {RepresentationsFilterImp} from "@/components/classes/RepresentationsFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {RepresentationsListData} from "@/types/representations";
import {SettingsApi} from "@/api/settings.api";
import RepresentationsFilter from "@/components/representations/RepresentationsFilter.vue";
import {getRepresentationsData, representationsFields} from "@/consts/fields/representations.fields";

@Options({
  components: {
    TableSettings,
    Accordion,
    RepresentationsList,
    RepresentationsFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as RepresentationsListData[],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      search: "",
      incidentKeys: "" as keyof RepresentationsListData,
      fields: reactive(representationsFields.slice() as Field<RepresentationsListData>[]),
      filter: new RepresentationsFilterImp(),
      prepareData: getRepresentationsData()
    };
  },
 methods: {
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    searchMethod(_) {
      return SettingsApi
          .applyFilter2(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    this.applyFilter();
    if(localStorage.getItem('ViewSettingsDto')) {
      this.fields = JSON.parse(localStorage.getItem('ViewSettingsDto'));
    }
  },
})
export default class Representations extends Vue {
  list!: RepresentationsListData[];
  search: string;
  filter: RepresentationsFilterImp;
}
