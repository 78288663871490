
import {Options, Vue} from "vue-class-component";
import {AreaApi} from "@/api/area.api";
import AreaCharacteristics from "@/components/areas/AreaCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
interface Option {
  value: string;
}
@Options({
  components: {AreaCharacteristics, EditForm },
  data: () => {
    return {
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    addPlayground() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      delete characteristics.services;
      AreaApi.getAreaEditSave(characteristics)
          .then((res) => {
            this.$notification.open({
                message: "Сохранено",
                style: {
                    width: "330px",
                    marginLeft: `${400 - 330}px`,
                    fontSize: "14px",
                },
                placement: 'bottomRight',
                duration: 3,
            });
            this.routeToMonitoring(res.id);
          })
    },
    routeToMonitoring(id) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace("/directory/areas/" + id);
    }

  }
})
export default class AddPlayground extends Vue {}
