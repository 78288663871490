import {send, url, withoutAPIUrl} from "@/helpers/api";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";

export const RolesApi = {
  applyFilter(data: any, size = 10, page = 0): any {
    return send({
      url: `/skyv-mon/users/roles/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilter2(data: any, size = 10, page = 0): Observable<any>  {
    return Axios.request({
      url: `/skyv-mon/users/roles/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  save(data: any) {
    return send({
      url: `${url}` + `/role/edit`,
      method: "post",
      data,
    });
  },
  getObjects(data: any, size = 10, page = 0) {
    if(data.roleId) {
      return send({
        url: `${url}` + `/role/objects?roleId=${data.roleId}&isList=true`,
        method: "get",
      });
    } else {
      return send({
        url: `${url}` + `/role/objects?roleId=${data.undefined}`,
        method: "get",
      });
    }
  },
  deleteRoleObjectRelation(roleId, objectId) {
    let objects = []
    objects.push(objectId);
    return send({
      url: `${url}` + `/role/objects/relation`,
      method: "delete",
      params: {
        relateId: roleId,
        objectId: objectId
      }
    });
  },
  addRelatedObjects(relateId, selectedObjects) {
    return send({
      url: `${url}` + `/role/objects/relation`,
      method: "post",
    data: {relateId, selectedObjects},
    });
  },
  create(data: any) {
    return send({
      url: `${url}` + `/role/create`,
      method: "post",
      data,
    });
  },
  getById(id) {
    return send({
      url: `${url}` + `/adminapi/roles/${id}`,
      method: "get",
    });
  },
  delete(name) {
    return send({
      url: `${url}` + `/role`,
      method: "delete",
      params: {
        name: name
      }
    });
  },
  addUserRoles(id: number, roles: string[]) {
    const data = {
      userId: id,
      roles: roles
    };
    return send({
      url: `${withoutAPIUrl}` + `/users/roles`,
      method: "post",
      data,
    });
  },
  addGroupRoles(id: number, data: string[]) {
    return send({
      url: `${url}` + `/adminapi/groups/${id}/roles`,
      method: "post",
      data,
    });
  },
    deleteRoleUser(userId, roleId) {
    return send({
      url: `${withoutAPIUrl}` + `/users/${userId}/roles/${roleId}`,
      method: "delete",
    });
  },
  deleteRoleGroup(groupId, roleId) {
    return send({
      url: `${url}` + `/adminapi/groups/${groupId}/roles/${roleId}`,
      method: "delete",
    });
  },
  getMenuItems() {
    return send({
      url: `${url}` + `/role/menu-items`,
      method: "get",
    });
  },
  getRoleInfoCRUD(roleName) {
    return send({
      url: `${url}` + `/role/info/menu`,
      method: "get",
      params: {
        roleName: roleName
      }
    });
  },
  saveFieldsCRUD(data) {
    return send({
      url: `${url}` + `/role/fields/create`,
      method: "post",
      data,
    });
  },
  getFieldsCRUD(id) {
    return send({
      url: `${url}` + `/role/fields`,
      method: "get",
      params: {
        id: id
      }
    });
  }
};
