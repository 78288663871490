
import {defineComponent} from "vue";
import ViewBase from "@/classes/ViewBase.vue";

export default defineComponent({
  mixins: [ViewBase],
  mounted() {
    this.info = localStorage.getItem('info');
    this.innerHTMLInfo = this.info;
  },
  data: () => ({
    body: null,
    innerHTMLInfo: '',
  }),
  methods: {
  },
  computed: {
  }
});
