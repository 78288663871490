
import {reactive} from 'vue'
import {Field} from "@/types/fields";
import {Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import GroupsList from "@/components/groups/GroupsList.vue";
import GroupsFilter from "@/components/groups/GroupsFilter.vue";
import {GroupsFilterImp} from "@/components/classes/GroupsFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {GroupsListData} from "@/types/groups";
import {getGroupsData, groupsFields} from "@/consts/fields/groups.fields";
import {RolesApi} from "@/api/roles.api";
import { groupDtoSettings$, serviceListDtoSettings$, setVisibleToFieldsInTable } from "@/consts/settings";
import { takeWhile } from "rxjs/operators";
import { serviceFields } from "@/consts/fields/services.fields";
import { ServiceListData } from "@/types/service";
import RoleObjectsList from "@/components/role-objects/RoleObjectsList.vue";
import RoleObjectsFilter from "@/components/role-objects/RoleObjectsFilter.vue";

@Options({
  components: {
    TableSettings,
    Accordion,
    RoleObjectsList,
    RoleObjectsFilter,
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as GroupsListData[],
      search: "",
      selectedList: [],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      userId: null,
      incidentKeys: "" as keyof GroupsListData,
      fields: null,
      filter: new GroupsFilterImp(),
      prepareData: getGroupsData()
    };
  },
  mounted() {
    this.applyFilter();
    groupDtoSettings$.pipe(
      takeWhile(() => !this.fields),
    )
      .subscribe(settings => {
        if(!settings) {
          return;
        }
        if(!settings.fields) {
          this.fields = settings;
          return;
        }
        this.fields
          = setVisibleToFieldsInTable(groupsFields, settings.fields) as Field<GroupsListData>[];
      })
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    getSelectedEquipment(e) {
      this.selectedList = e;
    },
    handleClickOnRelateButton() {
      this.relateGroups();
      return;
      if(this.exceptKey === 'exceptUserId') {
        return;
      }
    },
    relateGroups() {
      let data: string[] = [];
      this.selectedList.forEach(role => data.push(role+''));
      return RolesApi.addRelatedObjects(this.$route.query.relatedId, data).then(() => {
        this.$router.go(-1);
      })
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
    },
    applyFilter(from?: 'filter' | unknown) {
      const filter  = this.defineFilterData
      filter.roleId = this.$route.query.relatedId
      // filter.filter.undefined = this.$route.query.relatedId
      filter[this.exceptKey] = +this.$route.query.relatedId;
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddProcesses() {
      this.$router.push("/security/groups/add");
    },
    searchMethod(_) {
      const filter  = this.defineFilterData
      filter[this.exceptKey] = +this.$route.query.relatedId;
      return RolesApi
          .getObjects(_ ? {containsValue: this.search, sortParams: this.filter.sortParams}
          : this.filter, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
})
export default class Processes extends Vue {
  list!: GroupsListData[];
  search: string;
  filter: GroupsFilterImp;
}
