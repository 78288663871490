import {send, url} from "@/helpers/api";

export const ClientsApi = {
 applyFilter(data: any , size = 10 , page = 0): any {
     return send({
         url: `${url}` + `/clients/filter`,
         method: "post",
         data: data,
         params: {
             page: page.toString(),
             size: size.toString(),
         },
     })
 }
}