
import { defineComponent } from "vue";
import {OperationQueueApi} from "../../api/operation-queue.api";
import OperationQueueCharacteristics from "@/components/operation-queue/OperationQueueCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";
import ViewBase from "@/classes/ViewBase.vue";

export default defineComponent({
  name: "OperationQueueView",
  components: { OperationQueueCharacteristics, EditForm,  DeleteDialog },
  data: () => ({
    body: {},
    currentUser
  }),
  mixins: [ViewBase],
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("этапа внедрения", `этап внедрения ${this.body.name}`);
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    getById() {
      OperationQueueApi.getById(this.id).then((res) => {
        this.body = res;
        suffix.next(' ' + res.name);
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristics();
      OperationQueueApi.edit(
          characteristics
      ).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.getById();
      });
    },
    deleteObject() {
      OperationQueueApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  },
});
