
import {defineComponent} from "vue";
import MailCharacteristics from "@/components/mail/MailCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import {MailApi} from "@/api/mail.api";

export default defineComponent ({
  name: "AddMail",
  components: {MailCharacteristics, EditForm},
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      if (characteristics.password === '********') {
        delete characteristics.password
      }

      MailApi.save(
          characteristics
      ).then((res) => {
        this.route(res.id);
      });
    },
    route(id) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace("/settings/mail/" + id);
    },
  },
})
