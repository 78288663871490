import {send, url} from "@/helpers/api";
import {Observable} from "rxjs";
import Axios from "axios-observable";

export const DepartmentsApi = {
    applyFilter(data: any, size = 25, page = 0) {
        return send({
            url: `${url}` + `/departments/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    applyFilter2(data: any, size = 25, page = 0): Observable<any> {
        return Axios.request({
            url: `${url}` + `/departments/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/departments/${id}`,
            method: "get",
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/departments/${id}`,
            method: "delete",
        });
    },
    save(data) {
        return send({
            url: `${url}` + `/departments/save`,
            method: "post",
            data,
        });
    },
    relateUserToDepartment(departmentId: number, users: string[]) {
        return send({
            url: `${url}` + `/departments/user`,
            method: "post",
            data: {
                departmentId:departmentId,
                users:users
            },
        });
    },
    deleteUsersFromDepartments(departmentsId, usersId) {
        return send({
            url: `${url}` + `/departments/${departmentsId}/user/${usersId}`,
            method: "delete",
        });
    },
}