
import { defineComponent } from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import { getExecutorUsersData } from "@/consts/fields/executor-users.fields";
import { ExecutorUsersApi } from "@/api/executor-users.api";
import { ClientsApi } from "@/api/clients.api";
import { getContacts } from "@/components/utils/UtilInfo";

export default defineComponent({
  name: "ExecutorUsersCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    userTypeList: [],
    organizationList: [],
    organizationFilterPageSize: 25,
    organizationListTotalCount: 0,
    data: getExecutorUsersData(),
    validationFields: [
      { field: "lastName", validators: ["required"] },
      { field: "firstName", validators: ["required"] },
      { field: "login", validators: ["required"] },
      { field: "userType.name", validators: ["required"] }
    ]
  }),
  emits: ["select"],
  mounted() {
    this.data.contacts = getContacts();
    this.setContactsValidationFields();
    this.setValidationFields();
    this.getUserType();
    this.getOrganizationList();
    this.getCRUDSettings(89)
  },
  methods: {
    valid(name, $event) {
      if (this.data.userType.id !== 3) {
        this.validateField(name, $event);
      }
    },
    setValidationFields() {
      this.validationFields = this.validationFields.filter(field => field.field !== "organization.name");
      if (this.data.userType.id != 3) {
        this.validationFields.push({ field: "organization.name", validators: ["required"] });
      }
    },
    getUserType() {
      ExecutorUsersApi.getUserType()
        .then(res => {
          this.userTypeList = res;
        });
    },
    setContactsValidationFields() {
      this.data.contacts.forEach((contact, i) => {
        if (contact.contactType.id === 2) {
          this.validationFields.push({ field: `contacts.${i}.contactInfo`, validators: ["required", "email"] });
        }
      });
    },
    getOrganizationList() {
      console.log(this.data);
      if (this.data.userType.id === 1) {
        ClientsApi.applyFilter({ name: this.data.organization.name, isActive: true }, this.organizationFilterPageSize, 0)
          .then((res) => {
            this.organizationList = res.data;
            this.organizationListTotalCount = res.totalCount;
            this.organizationFilterPageSize += 25;
          });
      }
      if (this.data.userType.id === 2) {
        ExecutorUsersApi.applyFilterExecutor({ name: this.data.organization.name, isActive: true }, this.organizationFilterPageSize, 0)
          .then((res) => {
            this.organizationList = res.data;
            this.organizationListTotalCount = res.totalCount;
            this.organizationFilterPageSize += 25;
          });
      }
    },
    selectOrganization(value, option) {
      this.onSelect(value, option);
    },
    onSelect(value, option) {
      let key = "id";
      if (option.field === "userType") {
        this.data["organization"] = { id: 0, name: "" };
        this.organizationList = [];
        this.data[option.field][key] = option[key];
        this.$emit("select", { option });
        this.getOrganizationList();
        return;
      }
      if(!this.data[option.field]) {
        this.data["organization"] = { id: option.key, name: value }
      } else {
        this.data[option.field][key] = option[key];
      }
      this.$emit("select", { option });
    },
    selectUserType(value, option) {
      this.onSelect(value, option);
      this.setValidationFields();
    },
    setUserType(id) {
      this.data.userType.id = this.userTypeList.filter(u => u.id === id)[0].id;
    }
  }
});
