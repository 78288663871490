import {SupportTeamListData} from "@/types/supportTeam";
import {Field} from "@/types/fields";

export const supportTeamFields: Field<SupportTeamListData>[] = [
    {field: "id", sortDirections: ['descend','ascend'], name: "ID", value: true, minWidth: "100px"},
    {field: "name", sortDirections: ['descend','ascend'], name: "Наименование", value: true, minWidth: "180px"},
    {field: "status", sortDirections: ['descend','ascend'], name: "Статус", value: true, minWidth: "120px",
        compute: (val) => val?.name},
    {field: "leaderFio", sortDirections: ['descend','ascend'], name: "ФИО лидера", value: true, minWidth: "120px"},
    // {field: "isLeader", sortDirections: ['descend','ascend'], name: "Лидер", value: true, minWidth: "100px",
    //     compute: (val) => val === true ? 'Да' : 'Нет'},
    {field: "isDefault", sortDirections: ['descend','ascend'], name: "По умолчанию", value: true, minWidth: "100px",
        compute: (val) => val === true ? 'Да' : 'Нет'},
    {field: "contacts", sortDirections: ['descend','ascend'], name: "Контакты", value: true, minWidth: "100px",
        compute: val =>
        val.map(res => {
            return res.contactType.description +  `: ` + res.contactInfo;
        }).join(', ')
    }
];

export const getSupportTeamData = () => ({
    id: null,
    name: "",
    // leaderFio: "",
    isDefault: false,
    isLeader: false,
    contacts: [],
    clientId: null,
    leader: {fio: "", id: 0, name: ""},
    position: "",
    status: {name: "Активен", id: 0}
});
