import {send, url} from "@/helpers/api";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";
import axios from "axios";


export const MetricLimitApi = {
    applyFilter(data: any, size = 10, page = 0) {
        return send({
            url: `${url}` + `/metrics/metric_name_color/filter`,
            method: 'post',
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        })
    },
    applyFilter2(data: any, size = 25, page = 0): Observable<any> {
        return Axios.request({
            url: `${url}` + `/metrics/metric_name_color/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    save(data: any) {
        return send({
            url: `${url}` + `/metrics/metric_name_color/save`,
            method: "post",
            data,
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/metrics/metric_name_color/${id}`,
            method: "delete",
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/metrics/metric_name_color/${id}`,
            method: "get"
        })
    },
    downloadExcel(request) {
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        return axios({
            method: "post",
            url: `${url}/metrics/metric_name_color/download?zoneId=${zoneId}`,
            data: request,
            responseType: "arraybuffer",
            headers: {
                "Accept": "application/octet-stream",
            }
        });
    },
}
