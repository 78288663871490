import {send, url} from "@/helpers/api";

export const CalculationRuleHistoryApi = {
    applyFilter(data:any, size = 10, page = 0): any {
        return send({
            url: `${url}` + `/classifiers/calculation-rule/changelog`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    }
}