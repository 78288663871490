import { Field } from "@/types/fields";
import {MetricGroupListData} from "@/types/metric-group";

export const metricGroupsFields: Field<MetricGroupListData>[] = [
  {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "120px"},
  {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
  {field: "description", sortDirections: ['descend', 'ascend'], name: "Описание", value: true, minWidth: ""},
  {field: "active", sortDirections: ['descend', 'ascend'], name: "Активность", value: true, minWidth: "",
    compute: (val) => val === true ? 'Да' : 'Нет'},
];

export const getMetricGroupData = () => ({
  id: null,
  active: false,
  name: "",
  description: ""
});
