
import { defineComponent } from "vue";
import moment from "moment/moment";
import VChart, { THEME_KEY } from "vue-echarts";

export default defineComponent({
  name: "Graphic",
  props: {
    period: {
      default: [],
    },
    isLoading: Boolean,
  },
  watch: {
    period: {
      handler(newValue) {
        this.updateDate(newValue);
      },
      deep:true
    },
  },
  components: {
    VChart
  },
  mounted() {
    this.handleTimePeriod(this.$refs.chart.option.dataZoom[0])
    setTimeout(() => {
      console.log(this.$refs.chart);
    }, 300)
  },
  methods: {
    updateDate(val) {
      this.startDate = moment(val[0]).format('DD.MM.yyyy HH:mm:ss')
      this.endDate = moment(val[1]).format('DD.MM.yyyy HH:mm:ss')
    },
    handleTimePeriod(ev) {
      const startDate = this.period[0].valueOf();
      const endDate = this.period[1].valueOf();
      const diff = (endDate - startDate)/100;
      const period = ev.batch ? [startDate - diff*ev.batch[0].start, startDate + diff*ev.batch[0].end] : [startDate + diff*ev.start, startDate + diff*ev.end]
      this.updateDate(period);
    },
    updateSeries(value, isCustom) {
      this.$refs.chart.clear();
      this.option.series = value.map((metric, i) => {
        let arrayValues =[];
        metric.source.data.forEach((_, i) => {
          if((_.unixTimestamp - metric.source.data[i-1]?.unixTimestamp) > 1000) {
            const steps = (_.unixTimestamp - metric.source.data[i-1].unixTimestamp)/60;
            const initialUnixTimestamp = +metric.source.data[i-1].unixTimestamp;
            const currentUnixTimestamp = +_.unixTimestamp;
            for(let j = 1; j<steps-1; j++){
              if(initialUnixTimestamp+(j*60) > currentUnixTimestamp) {
                return;
              }
              arrayValues.push(Object.freeze([
                (initialUnixTimestamp+(j*60)) * 1000,
                0,
                true
              ]))
            }
          }
          arrayValues.push(Object.freeze([
            _.unixTimestamp * 1000,
            _.value,
          ]))
        });

        return Object.freeze({
          type: 'line',
          smooth: false,
          symbol: 'circle',
          symbolSize: 10,
          showSymbol: false,
          name: metric.name,
          lineStyle: {
            width: 3,
          },
          color: this.setColorToMetric(metric),
          emphasis: { focus: 'series' },
          data: arrayValues
        });
      });
      if(isCustom) {
        this.option.dataZoom[0].start = 0;
        return;
      }
      this.option.dataZoom[0].start = 80;
    },
    setColorToMetric({name}) {
      if(this.metricColors[name]) {
        return this.metricColors[name];
      }
      let color;
      const metricColors = Object.keys(this.metricColors).map(_ => this.metricColors[_]);
      let colorExcludes = this.colors.filter(_ => !metricColors.includes(_));
      color = colorExcludes[0];
      this.metricColors[name] = color;
      return color;
    }
  },
  data: () => ({
    isLoadingGraphic: false,
    startDate: null,
    endDate: null,
    colors: ['#FF4F12', '#7700FF', '#607880', '#EEA81B', '#008c73'],
    metricColors: {} as any,
    loadingOptions: {
      text: '',
      color: '#FF4F12',
      textColor: '#000',
      zlevel: 0,

      // Font size. Available since `v4.8.0`.
      fontSize: 12,
      // Show an animated "spinner" or not. Available since `v4.8.0`.
      showSpinner: true,
      // Radius of the "spinner". Available since `v4.8.0`.
      spinnerRadius: 20,
      // Line width of the "spinner". Available since `v4.8.0`.
      lineWidth: 5,
      // Font thick weight. Available since `v5.0.1`.
      fontWeight: 'normal',
      // Font style. Available since `v5.0.1`.
      fontStyle: 'normal',
      // Font family. Available since `v5.0.1`.
      fontFamily: 'Inter, sans-serif',
    },
    option: {
      tooltip: {
        trigger: 'axis',
        position: function (pt,params, dom, rect, size) {
          return [pt[0]+20, '10%'];
        },
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          },
          className: 'tooltip',
        },
        formatter: (params) => {
          // третье значение выставляется при выставлении значения, рассчитаного в функции   updateSeries
          let metrici = '';
          let time = ``;
          for (let i = 0; i < params.length; i++) {
            if(params[i].value[2]) {
              return
            }
            let icon0 = `<span data-tooltip="minimum" style="border-left: 2px solid #fff;display: inline-block;height: 12px;width: 20px;"><span style="background-color:${params[i]?.color};border-radius: 100%;display: block;height: 12px;width: 12px;"></span></span>`;
            time = `<span class="tooltip"> ${moment(params[0].value[0]).format('DD.MM.YY HH:mm')} </span> ` + '<br>';
            metrici +=
                ` ${params[i]?.seriesName === '' ? '' : `${icon0}` }` +
                params[i]?.seriesName +
                ` ${params[i]?.seriesName === '' ? '' : `:` }` +
                ` ${
                    params[i]?.seriesName === 'Доступность' && !!+params[i]?.value[1] ? ' Есть' : ''
                } ` +
                ` ${
                    params[i]?.seriesName === 'Доступность' && !+params[i]?.value[1] ? ' Нет' : ''
                } ` +
                ` ${
                    params[i]?.seriesName === 'Статус канала связи' && +params[i]?.value[1] === 2
                        ? 'Доступен'
                        : ''
                } ` +
                ` ${
                    params[i]?.seriesName === 'Статус канала связи' && +params[i]?.value[1] === 1
                        ? 'Предупреждение'
                        : ''
                } ` +
                ` ${
                    params[i]?.seriesName === 'Статус канала связи' && +params[i]?.value[1] === 0
                        ? 'Недоступен'
                        : ''
                } `  +
                ` ${
                    params[i]?.seriesName === 'Статус объекта' && +params[i]?.value[1] === 1
                        ? 'Доступен'
                        : ''
                } ` +
                ` ${
                    params[i]?.seriesName === 'Статус объекта' && +params[i]?.value[1] === 0
                        ? 'Недоступен'
                        : ''
                } ` +
                ` ${
                    params[i]?.seriesName !== 'Доступность' &&
                    params[i]?.seriesName !== 'Статус объекта' &&
                    params[i]?.seriesName !== 'Статус канала связи'
                        && (!!params[i].value || params[i].value === 0)
                        ? params[i].value[1]
                        : ''
                } ` + `<br>`
          }
          return `<div class="tooltip" style="padding: 0  !important;">
                    <p class="tooltip-title" style=" font-family: 'Inter', sans-serif;background-color: rgba(0,0,0,0.05);
                     margin: -12px -12px -3px -12px; padding: 12px 12px 3px 12px; border-bottom: 1px solid gray;
                     font-weight: bold; font-size: 14px;"> ${time} </p>
                 <div style="padding-top: 5px"><span class="tooltip-content" style="  font-family: 'Inter', sans-serif; font-size: 14px;">${metrici}</span></div> </div>`;
        },
      },
      xAxis: [
        {
          type: 'time',
          boundaryGap: false,
          axisLabel: {
            formatter: {
              year: '{dd}.{MM}.{yyyy}',
              month: '{dd}.{MM}.{yyyy}',
              day: '{dd}.{MM}.{yy}',
              hour: '{HH}:{mm}',
              minute: '{HH}:{mm}',
              second: '',
            },
            fontFamily: "Inter, sans-serif",
            fontSize: 14,
            color: `rgb(116,124,132)`
          },
        },
      ],
      yAxis: {
        type: 'value',
        boundaryGap: [0, '50%'],
        splitNumber: 10,
        axisLabel: {
          fontFamily: `Inter, sans-serif`,
          fontSize: 14,
          color: `rgb(116,124,132)`
        }
      },
      legend: {
        show: true,
        icon: 'circle',
        textStyle: {
          fontFamily: "Inter, sans-serif",
          fontSize: 14,
          fontWeight: "bold",
          color: `rgb(116,124,132)`
        },
        width: "80%"
      },
      toolbox: {
        orient: 'horizontal',
        right: '9.2%',
        feature: {
          myTool1: {
            show: true,
            icon: ('image://'+require("@/assets/icons/ui/graphic-icon/zoomout.svg")+''),
            onclick: (ev, ev2) => {
              ev2.dispatchAction({
                type: 'dataZoom',
                start: ev.option.dataZoom[0].start-10,
                end: ev.option.dataZoom[0].end,
              })
            },
          },
          myTool2: {
            show: true,
            icon: ('image://'+require("@/assets/icons/ui/graphic-icon/zoomin.svg")+''),
            onclick: (ev, ev2) => {
              if(ev.option.dataZoom[0].end-ev.option.dataZoom[0].start < 10) {
                return;
              }
              ev2.dispatchAction({
                type: 'dataZoom',
                start: ev.option.dataZoom[0].start+10,
                end: ev.option.dataZoom[0].end,
              })
            },
          },
        },
        itemSize: 20,
        itemGap: 10
      },
      dataZoom: [
        {
          type: 'slider',
          start: 80,
          scale: false,
          end: 100,
          height: 50,
          showDetail: false,
          handleSize: '30%',
          fillerColor: 'rgba(47,69,84,0.25)',
          handleIcon: 'roundRect',
          dataBackground: {
            lineStyle: {
              color: 'rgba(5, 33, 247, 1)'
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(0,143,251,0.11)' // color at 0%
                },
                  {
                    offset: 0.3, color: 'rgba(0,143,251,0.31)' // color at 100%
                  },
                  {
                    offset: 0.5, color: 'rgba(0,143,251,0.51)' // color at 100%
                  },
                  {
                    offset: 0.7, color: 'rgba(0,143,251,0.71)' // color at 100%
                  },
                  {
                    offset: 0.9, color: 'rgba(0,143,251,0.81)' // color at 100%
                  },
                  {
                  offset: 1, color: 'rgba(0,143,251,0.91)' // color at 100%
                }],
                global: false // default is false
              }
            }
          },
          moveHandleSize: 3
        },
        {
          showDetail: false,
          type: 'inside' // Включить колесо прокрутки, когда мышь находится на графике
        }
      ],
      animationEasing: 'elasticOut',
      series: [],
      textStyle: {
        // Font thick weight. Available since `v5.0.1`.
        fontWeight: 'normal',
        // Font style. Available since `v5.0.1`.
        fontStyle: 'normal',
        // Font family. Available since `v5.0.1`.
        fontFamily: 'Inter, sans-serif',
      }
    },
  }),
});
