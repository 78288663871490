
import {Field} from "@/types/fields";
import {Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import {ProcessApi} from "@/api/process.api";
import TableSettings from "@/components/base/TableSettings.vue";
import ProcessList from "@/components/processes/ProcessList.vue";
import ProcessFilter from "@/components/processes/ProcessFilter.vue";
import {ProcessFilterImp} from "@/components/classes/ProcessFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {ProcessListData} from "@/types/process";
import {getProcessesData, processFields} from "@/consts/fields/processes.fields";
import {processDefinitionDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";

@Options({
  components: {
    TableSettings,
    Accordion,
    ProcessList,
    ProcessFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as ProcessListData[],
      // search: "",
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof ProcessListData,
      fields: null,
      filter: new ProcessFilterImp(),
      prepareData: getProcessesData()
    };
  },
  // watch: {
  //   search: {
  //     handler() {
  //       this.commonSearch();
  //     },
  //     deep: true
  //   },
  // },
  methods: {
    // downloadExcel() {
    //   console.log();
    // },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    // toggleSuffix() {
    //   // this.search = '';
    // },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        // if (this.search !== '')
        //   this.applyFilterRequest$.next('containsValue');
        // else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddProcesses() {
      this.$router.push("/settings/processes/add");
    },
    searchMethod(_) {
      return ProcessApi
          .applyFilter2(this.filter, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    processDefinitionDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields = setVisibleToFieldsInTable(processFields, settings.fields) as Field<ProcessListData>[];
        })
  },
})
export default class Processes extends Vue {
  list!: ProcessListData[];
  // search: string;
  filter: ProcessFilterImp;
}
