
import { defineComponent } from "vue";

import DmnModeler from 'dmn-js/lib/Modeler';
import {
  DmnPropertiesPanelModule,
  DmnPropertiesProviderModule,
  CamundaPropertiesProviderModule
} from 'dmn-js-properties-panel';

import CamundaModdle from "camunda-dmn-moddle/resources/camunda.json";

import ProcessesImportDialog from "@/components/processes/ProcessesImportDialog.vue";
import * as FileSaver from 'file-saver';
import {DecisionDefinitionsApi} from "@/api/decision-definitions.api";
import {suffix} from "@/consts/nameSuff";
import {DecisionDefinitionsFilterImp} from "@/components/classes/DecisionDefinitionsFilterImp";

export default defineComponent({
  name: "EditDecisionDefinitions",
  components: {ProcessesImportDialog},
  data: () => ({
    id: null,
    processScIncidentID: null,
    isChange: false,
    statusesXML: '',
    xmlClone: '',
    modeler: null,
    viewer: null,
  }),
  emits: ["select"],
  mounted() {
    const filter = new DecisionDefinitionsFilterImp();
    DecisionDefinitionsApi
        .applyFilter(filter, 100, 0).then(data => {
      this.id = data[0].key;
      suffix.next(` - ${this.id}`);
      this.addHandler();
      this.prepareData();
    });
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    addHandler() {
      let handler = document.querySelector('.handler');
      let wrapper = handler.closest<HTMLElement>('.wrapper');
      let boxA = wrapper.querySelector<HTMLElement>('.box');
      let isHandlerDragging = false;

      document.addEventListener('mousedown', function(e) {
        if (e.target === handler) {
          isHandlerDragging = true;
        }
      });

      document.addEventListener('mousemove', function(e) {
        if (!isHandlerDragging) {
          return false;
        }

        let containerOffsetLeft = wrapper.offsetLeft;
        let pointerRelativeXpos = e.clientX - containerOffsetLeft;
        let boxAminWidth = 60;

        boxA.style.width = (Math.max(boxAminWidth, pointerRelativeXpos - 8)) + 'px';
        boxA.style.flexGrow = '0';
      });

      document.addEventListener('mouseup', function(e) {
        isHandlerDragging = false;
      });
    },
    importFile() {
      this.$refs.processesImport.openDialog('Импорт');
    },
    exportFile() {
      this.modeler.saveXML({ format: true }, (err: any, xml: any) => {
        if (err) {
          this.$notification.open({
            message: "Ошибка экспорта",
            description: err,
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        }
        this.xmlClone = xml;
        const file = new Blob([this.xmlClone], {type: 'text/xml'});
        FileSaver.saveAs(file, 'process.xml');
      });
    },
    importFileDialog(file) {
      const fileForImport = new Blob([file.originFileObj], {type: 'text/xml'});
      let modeler = this.modeler;
      let changeWarning = this.changeWarning;
      fileForImport.text().then(res => {
        this.modeler.importXML(res, function(err) {
          if (!err) {
            modeler.getActiveViewer().get('canvas').zoom('fit-viewport');
            let djsContainer = document.querySelector('.djs-container');
            let svg = djsContainer.querySelector('svg');
            svg.addEventListener("mousedown",
                function () {
                  changeWarning();
                })
          } else {
            console.log('something went wrong:', err);
          }
        });
      })
    },
    changeWarning() {
      this.isChange = true;
      window.onbeforeunload = function() { return "Message" };
    },
    prepareData() {
      DecisionDefinitionsApi.getDecisionDefinition(this.id).then(res => {
        this.processScIncidentID = res.id;
        this.getProcessDefinition()
      })
    },
    createXML(changeWarning) {
      this.modeler = null;
      let canvas = document.querySelector('#canvas');
      while (canvas.firstChild) {
        canvas.removeChild(canvas.firstChild);
      }
      let properties = document.querySelector('#properties');
      while (properties.firstChild) {
        properties.removeChild(properties.firstChild);
      }

      this.modeler = new DmnModeler({
        container: '#canvas',
        drd: {
          propertiesPanel: {
            parent: '#properties'
          },
          additionalModules: [
            DmnPropertiesPanelModule,
            DmnPropertiesProviderModule,
            CamundaPropertiesProviderModule
          ]
        },
        moddleExtensions: {
          camunda: CamundaModdle
        }
      });

      let mod = this.modeler;

      this.modeler.importXML(this.statusesXML, function(err) {
        if (!err) {
          console.log('success!');
          mod.getActiveViewer().get('canvas').zoom('fit-viewport');
          let djsContainer = document.querySelector('.djs-container');
          let svg = djsContainer.querySelector('svg');
          svg.addEventListener("mousedown",
              function () {
                changeWarning();
              });
          const el = document.querySelector<HTMLElement>('.dmn-icon-decision-table')
          el.click();
        } else {
          console.log('something went wrong:', err);
        }
      });
    },
    getProcessDefinition() {
      DecisionDefinitionsApi.getDecisionDefinitionByID(this.processScIncidentID)
          .then(res => {
            this.statusesXML = res.dmnXml;
            this.createXML(this.changeWarning);
          })
    },
    save() {
      this.modeler.saveXML({ format: true }, (err: any, xml: any) => {
        if (err) {
          return console.error('save could not export DMN diagram xml', err);
        }
        this.xmlClone = xml;
        const file = new Blob([ this.xmlClone ], { type: 'dmn' });
        const formData = new FormData();
        formData.append('data', file, 'process.dmn');
        // formData.append('tenant-id', null);
        // formData.append('deployment-source', null);
        formData.append('deploy-changed-only', 'true');
        formData.append('enable-duplicate-filtering', 'true');
        formData.append('deployment-name', '');
        formData.append('deployment-activation-time', '');
        DecisionDefinitionsApi.deploymentCreate(formData)
            .then(() => {
              this.$notification.open({
                message: "Сохранено",
                style: {
                  width: "330px",
                  marginLeft: `${400 - 330}px`,
                  fontSize: "14px",
                },
                placement: 'bottomRight',
                duration: 3,
              });
            });
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isChange) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  beforeUnmount() {
    this.modeler = null;
    this.statusesXML = '';
    this.xmlClone = '';
    this.isChange = false;
    suffix.next('');
  }
});
