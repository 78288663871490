
import { defineComponent } from "vue";
import {ProcessApi} from "../../api/process.api";
import ProcessCharacteristics from "@/components/processes/ProcessCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";

export default defineComponent({
  name: "ProcessView",
  components: { ProcessCharacteristics, EditForm, DeleteDialog},
  mixins: [ViewBase],
  data: () => ({
    body: {},
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("процесса", `процесс ${this.body.id}`);
    },
    getById() {
      ProcessApi.getById(this.id).then((res) => {
        this.body = res;
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      ProcessApi.save(
          characteristics
      ).then(() => {
        this.getById();
      });
    },
    deleteObject() {
      ProcessApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  },
});
