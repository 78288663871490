
import { defineComponent } from "vue";
import MetricsIndicatorsCharacteristics from "@/components/metrics-indicators/MetricsIndicatorsCharacteristics.vue";
import { IndicatorApi } from "../../api/indicator.api";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase, {
  GET_METRIC_GROUP_RELATED_OBJECT,
} from "@/classes/ViewBase.vue";
import MetricGroupList from "@/components/metrics-groups/MetricGroupList.vue";
import MetricsGroupsFilter from "@/components/metrics-groups/MetricsGroupsFilter.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import { MetricGroupApi } from "@/api/metric-group.api";

export default defineComponent({
  name: "MetricsIndicatorsView",
  mixins: [ViewBase],
  components: {
    MetricsIndicatorsCharacteristics,
    EditForm,
    DeleteDialog,
    TableSettings,
    MetricsGroupsFilter,
    MetricGroupList,
  },
  data: () => ({
    body: {},
    relatedTitle: 'типа показателя',
    selectorData: [{ value: "Группы метрик" }],
    currentSelect: "Группы метрик",
    type: "metric-indicator",
    relatedObjects: {
      "metric-groups": {
        ...GET_METRIC_GROUP_RELATED_OBJECT(),
      },
    },
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm(
      "Закрыть сайт? Возможно внесенные изменения не сохранятся!"
    );
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    handleDeleteRelatedObject(id) {
      if (this.currentSelect === 'Группы метрик') {
        MetricGroupApi.deleteMetricIndicator(id, this.id)
          .then(res => {
            this.applyCurrentRelatedFilter();
          })
      }
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog(
        "типа показателей",
        `тип показателей ${this.body.name}`
      );
    },
    getById() {
      IndicatorApi.getById(this.id)
        .then((res) => {
          this.body = res;
        })
        .finally(() => {
          this.$refs.characteristics.cleanWarning();
        });
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics =
        this.$refs.characteristics.formatCharacteristics();

      IndicatorApi.save(characteristics).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: "bottomRight",
          duration: 3,
        });
        this.getById();
      });
    },
    cancel() {
      this.getById();
    },
    deleteObject() {
      IndicatorApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  },
});
