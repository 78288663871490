import {Field} from "@/types/fields";
import {NoticeTemplateListData} from "@/types/notice-template";

export const noticeTemplateFields: Field<NoticeTemplateListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "110px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
]

export const getNoticeTemplateData = () => ({
    id: null,
    name: '',
    text: '',
    subject: ''
})