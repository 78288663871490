
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import {defineComponent} from "vue";
import EditForm from "@/components/base/EditForm.vue";
import MonitoringObjectCharacteristics from "@/components/monitoring-objects/MonitoringObjectCharacteristics.vue";

export default defineComponent({
  name: "MonitoringObjectAdd",
  components: { MonitoringObjectCharacteristics, EditForm },
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      MonitoringObjectsApi.save(characteristics)
        .then((res) => {
        this.route(res.id);
      });
    },
    route(id?) {
      this.$refs.characteristics.cleanWarning();
      if (!id) {
        this.$router.replace("/monitoring/objects/");
        return;
      }
      this.$router.replace("/monitoring/objects/" + id);
    },
    deleteObject() {
      MonitoringObjectsApi.delete(this.$route.params.id).then((res) => {
        this.$router.go(-1);
      });
    },
  },
});
