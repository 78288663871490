import {send, url} from "@/helpers/api";

export const MonitoringTemplateApi = {
    applyFilter(data: any, size = 25, page = 0) {
        return send({
            url: `${url}` + `/snmp/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/snmp/${id}`,
            method: "get",
        });
    },
    getDataById(id) {
        return send({
            url: `${url}` + `/snmp/data/${id}`,
            method: "get",
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/snmp/${id}`,
            method: "delete",
        });
    },
    uploadAndReadMibAndSave(data) {
        return send({
            url: `${url}` + `/reports/uploadAndReadMibAndSave`,
            method: "post",
            data
        });
    },
    save(data) {
        return send({
            url: `${url}` + `/snmp/bound`,
            method: "post",
            data
        });
    },
    editSnmp(data) {
        return send({
            url: `${url}` + `/snmp/data/edit`,
            method: "post",
            data
        });
    },
    deleteSnmp(id) {
        return send({
            url: `${url}` + `/snmp/data/delete/${id}`,
            method: "delete",
        });
    },
}
