
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getSupportTeamData} from "@/consts/fields/support-team.fields";
import {getContacts} from "@/components/utils/UtilInfo"
import {ExecutorUsersApi} from "@/api/executor-users.api";

export default defineComponent({
  name: "SupportTeamCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getSupportTeamData(),
    leaderList: [],
    validationFields:[
      {field: "name", validators: ["required"]},
      // {field: "leader.name", validators: ["required"]},
    ],
  }),
  mounted() {
    this.data.contacts = getContacts();
    this.getLeaderList();
    this.setContactsValidationFields();
    this.getCRUDSettings(69)
  },
  methods: {
    changeAuto(event?){
      if(!event) {
        this.clean();
      }
    },
    clean() {
      this.data.leader = {fio: "", id: 0, name: ""};
    },
    getLeaderList() {
      ExecutorUsersApi.applyFilter({fio: this.data.leader.name}, 25, 0)
          .then((res) => {
            this.leaderList = res.data;
          });
    },
    setContactsValidationFields() {
      this.data.contacts.forEach((contact, i) => {
        if(contact.contactType.id === 2) {
          // this.validationFields.push({field: `contacts.${i}.contactInfo`, validators: ["required", "email"]})
        }
      });
    },
    selectLeader(value, option) {
      this.onSelect(value, option);
    },
    onSelect(value, option) {
      const key = "id";
      this.data[option.field][key] = option[key];
    },
  }
})
