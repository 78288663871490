import {Field} from "@/types/fields";
import {ChangelogListData} from "@/types/changelog";
import {formatDate} from "@/filters/date";

export const changelogFields: Field<ChangelogListData>[] = [
    {field: "changedObjectTypeRus", sortDirections: ['descend', 'ascend'], name: "Сущность", value: true, minWidth: "150px"},
    {field: "changedObject", sortDirections: ['descend', 'ascend'], name: "ID", value: true,compute: val => val.id, minWidth: "150px"},
        {field: "commitDate", sortDirections: ['descend', 'ascend'], name: "Дата изменения", value: true, minWidth: "150px",        compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
            {
                return formatDate(new Date(new Date(value).getTime() + 10800000).toString(), format)
            }
            ,},
    {field: "author", sortDirections: ['descend', 'ascend'], name: "Автор", value: true, minWidth: "150px"},
    {field: "changeType", sortDirections: ['descend', 'ascend'], name: "Тип изменения", value: true, minWidth: "150px"},
    {field: "changedFields", sortDirections: ['descend', 'ascend'], name: "Атрибут", value: true, minWidth: "150px", compute: val => val.map(res => {
        return res.fieldNameRus
        }).join(', ') },
    // {field: "changedFields", sortDirections: ['descend', 'ascend'], name: "Значение", value: true, minWidth: "350px", compute: val => val.map(res => {
    //        if (typeof res.prevValue === "object") {
    //            return res.prevValue?.name + ' => ' + res.currentValue?.name
    //        }
    //        else {
    //            return res.prevValue||'-' + ' => ' + res.currentValue
    //        }
    //     }).join(' <br/> '),
    // },
];

export const getChangelogData = () => ({
    changedObjectTypeRus: "",
})