
import {Options, Vue} from "vue-class-component";
import NSIBase from "@/classes/NSIBase.vue";
import {NoticeTemplateListData} from "@/types/notice-template";
import {NoticeTemplateFilterImp} from "@/components/classes/NoticeTemplateFilterImp";
import {getNoticeTemplateData, noticeTemplateFields} from "@/consts/fields/notice-template.fields";
import {ServiceFilterImp} from "@/components/classes/ServiceFilterImp";
import NoticeTemplateList from "@/components/notice-template/NoticeTemplateList.vue";
import NoticeTemplateFilter from "@/components/notice-template/NoticeTemplateFilter.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import Accordion from "@/components/ui/Accordion.vue";
import {NoticeTemplateApi} from "@/api/notice-template.api";
import {metricTagDtoSettings$, noticeTemplateDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";
import {metricTagFields} from "@/consts/fields/metric-tag.fields";
import {Field} from "@/types/fields";
import {MetricTagListData} from "@/types/metric-tag";

@Options({
  components: {NoticeTemplateFilter, NoticeTemplateList , TableSettings, Accordion},
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as NoticeTemplateListData[],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof NoticeTemplateListData,
      fields: null,
      filter: new NoticeTemplateFilterImp(),
      prepareData: getNoticeTemplateData(),
      selectedList: []
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      // deep: true
    }
  },
  mounted() {
    noticeTemplateDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields = setVisibleToFieldsInTable(noticeTemplateFields, settings.fields) as Field<NoticeTemplateListData>[];
        });
  },
  methods: {
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix(el) {
      this.search = '';
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if (from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let column = value.columnKey;
      let order = '';
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    resultMethod(res) {
      this.list = res.data;
      this.totalCount = res.totalCount;
      this.isLoad = true;
    },
    routeToAddNoticeTemplate() {
      this.$router.push("/directory/notice-template/add");
    },
    searchMethod(_) {
      console.log(this.defineFilterData)
      return NoticeTemplateApi
          .applyFilter(this.defineFilterData, this.size, this.page);
    },
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      // ServiceApi
      //     .downloadExcel(filter)
      //     .then((res) => {
      //       this.$notification.open({
      //         message: new TextDecoder().decode(res.data),
      //         style: {
      //           width: '600px',
      //           marginLeft: `${400 - 600}px`,
      //           fontSize: "14px",
      //         },
      //         placement: 'bottomRight',
      //         duration: 3,
      //       });
      //     })
      //     .finally(() => (this.isLoadingExcel = false));
    },
  },
})
export default class NoticeTemplate extends Vue {
  list!: NoticeTemplateListData[];
  search: string;
  filter: ServiceFilterImp;
}
