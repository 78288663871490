
import {defineComponent} from "vue";
import ViewBase from "@/classes/ViewBase.vue";
import {IncidentTypeApi} from "@/api/incident-type.api";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import EditForm from "@/components/base/EditForm.vue";
import IncidentTypeCharacteristics from "@/components/incident-type/IncidentTypeCharacteristics.vue";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";

export default defineComponent ({
  name: "IncidentTypeView",
  components: {IncidentTypeCharacteristics, DeleteDialog,EditForm,},
  mixins: [ViewBase],
  data: () => ({
    body: {},
    currentUser
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("типа заявки", `тип заявки ${this.body.name}`);
    },
    getById() {
      IncidentTypeApi.getById(this.id)
          .then(res => {
            this.body = res;
            suffix.next(' ' + res.name);
            // this.vGroupKey = [this.body.name];
          }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristics();
      IncidentTypeApi.save(
          characteristics
      ).then(() => {

        this.getById();
      });
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    deleteObject() {
      IncidentTypeApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  }
})
