
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getDepartmentsData} from "@/consts/fields/departments.field";
import {CounterpartyApi} from "@/api/counterparty.api";
import {DepartmentsApi} from "@/api/departments.api";

export default defineComponent ({
  name: "DepartmentsCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getDepartmentsData(),
    validationFields:[
      {field: "name", validators: ["required"]},
      {field: "counterparty.name", validators: ["required"]}
    ],
    parentDepartmentsList: [],
    counterpartyList: []
  }),
  mounted() {
    this.onSearchCounterParty('');
    this.onSearchParentDepartments('');
    this.getCRUDSettings(75)
  },
  methods: {
    onSearchCounterParty(value?) {
      CounterpartyApi.applyFilter({name: value}).then((res) => {
        this.counterpartyList = res.data;
      });
    },
    onSearchParentDepartments(value?) {
      DepartmentsApi.applyFilter({name: value, hierarchy: false}).then((res) => {
        this.parentDepartmentsList = res.data;
      })
    },
    onSelectCounterparty(value, option) {
      const key = "id";
      this.data.counterpartyId = option[key];
      this.data.counterparty.id = option[key];
    },
    onSelectParentDepartments(value, option) {
      const key = "id";
      this.data.parentId = option[key];
      this.data.parent.id = option[key];
    },
    clearCounterparty(e) {
      if (e == undefined) {
        this.data.counterpartyId = null;
        this.data.counterparty.id = null;
        this.onSearchCounterParty('')
      }
    },
    clearParentDepartments(e) {
      if (e == undefined) {
        this.data.parentId = null;
        this.data.parent.id = null;
        this.onSearchParentDepartments('')
      }
    }
  }
})
