import { Field } from "@/types/fields";
import { CalculatedStatusRuleListData } from "@/types/calculated-status-rule";

export const calculatedStatusRuleFields: Field<CalculatedStatusRuleListData>[] = [
  {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "100px"},
  {field: "metricInstance", sortDirections: ['descend', 'ascend'], name: "Экземпляр метрики", value: true, minWidth: "140px"},
  {field: "counterparty", sortDirections: ['descend', 'ascend'], name: "Клиент", value: true, minWidth: "120px"},
  {field: "minValue", sortDirections: ['descend', 'ascend'], name: "Минимальное значение", value: false, minWidth: ""},
  {field: "minValueOperator", sortDirections: ['descend', 'ascend'], name: "Оператор", value: false, minWidth: ""},
  {field: "maxValue", sortDirections: ['descend', 'ascend'], name: "Максимальное значение", value: true, minWidth: "120px"},
  {field: "maxValueOperator", sortDirections: ['descend', 'ascend'], name: "Оператор", value: false, minWidth: "120px"},
  {field: "objectType", sortDirections: ['descend', 'ascend'], name: "Тип объекта", value: false, minWidth: "120px"},
  {field: "status", sortDirections: ['descend', 'ascend'], name: "Статус", value: true, minWidth: "120px"},
  {field: "colorCode", sortDirections: ['descend', 'ascend'], name: "Цвет", value: true, minWidth: "120px"},
];
export const getCalculatedStatusRuleData = () => ({
  id: null,
  metricInstance: {
    id: null,
    name: '',
    itemId: null,
  },
  counterparty:{
    id: null,
    name: '',
  },
  objectType: {
    id: null,
    name: '',
  },
  minValue: null,
  minValueOperator: "",
  maxValue: null,
  maxValueOperator: "",
  status: {id: null, name: ''},
  colorCode: "",
});
