
import { defineComponent } from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {GroupsApi} from "@/api/groups.api";
import {ExecutorUsersApi} from "@/api/executor-users.api";
import {SupportTeamApi} from "@/api/supportTeam.api";

export default defineComponent({
  components: {DeleteDialog},
  mixins: [ListBase],
  props: {
    hierarchy: {
      default: false
    },
  },
  watch: {
    hierarchy: {
      handler() {
        this.openPages = [];
        if (this.hierarchy) {
          this.setOpenPages(this.list);
        }
      }
    },
    list: {
      handler() {
        if(this.hierarchy) {
          this.nestedCount = this.count(this.$props.list[0].childMonitoringObjectTypes)
        }
      },
      deep: true
    }
  },
  data: () => ({
    openPages: [],
    nestedCount: 0
  }),
  methods: {
    expand(expanded, record) {
      const id = record.id;
      if (expanded) {
        if (!this.openPages.includes(id)) {
          const set = new Set(this.openPages);
          set.add(id);
          this.openPages = [...set];
        }
      } else {
        this.openPages.splice(this.openPages.indexOf(id), 1);
      }
    },
    setOpenPages(list) {
      list.forEach(l => {
        this.openPages.push(l.id);
        if (l.childMonitoringObjectTypes) {
          this.setOpenPages(l.childMonitoringObjectTypes);
        }
      });
    },
    count(children) {
      return children.reduce((depth, child) => {
        return Math.max(depth, 1 + this.count(child.childMonitoringObjectTypes))
      }, 1);
    },
    openDeleteDialog(id, name) {
      this.selectedId = id;
      this.$refs.delete.openDialog("связи", `связь с типом объекта ${name}`);
    },
    deleteRelateType() {
      console.log(this.typeRelate)
      console.log(this.relatedId)
      console.log(this.selectedId)
      console.log(this.selectedArray)
      if(this.typeRelate === 'support-teams') {
        SupportTeamApi.deleteMonitoringObjectType(this.selectedId).then(() => {
          this.onChange(this.currentPage, this.currentSize);
        });
      }
    },
  }
});
