
import {Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import {OperationQueueApi} from "@/api/operation-queue.api";
import TableSettings from "@/components/base/TableSettings.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {OperationQueueListData} from "@/types/operation-queue";
import OperationQueueList from "@/components/operation-queue/OperationQueueList.vue";
import OperationQueueFilter from "@/components/operation-queue/OperationQueueFilter.vue";
import {OperationQueueFilterImp} from "@/components/classes/OperationQueueFilterImp";
import {getOperationQueueData, operationQueueFields} from "@/consts/fields/operation-queues.fields";
import NSIBase from "@/classes/NSIBase.vue";
import { operationQueueSettings$, setVisibleToFieldsInTable } from "@/consts/settings";
import { takeWhile } from "rxjs/operators";
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {
    TableSettings,
    Accordion,
    OperationQueueList,
    OperationQueueFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as OperationQueueListData[],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof OperationQueueListData,
      fields: null,
      filter: new OperationQueueFilterImp(),
      prepareData: getOperationQueueData(),
      currentUser
    };
  },
  setup() {
    return {
      locale
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      OperationQueueApi
              .downloadExcel(filter)
              .then((res) => {
                this.$notification.open({
                  message: new TextDecoder().decode(res.data),
                  style: {
                    width: '600px',
                    marginLeft: `${400 - 600}px`,
                    fontSize: "14px",
                  },
                  placement: 'bottomRight',
                  duration: 3,
                });
              })
              .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix() {
      this.search = '';
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddService() {
      this.$router.push("/directory/operation-queue/add");
    },
    searchMethod(_) {
      return OperationQueueApi
          .applyFilter2(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    this.applyFilter();
    operationQueueSettings$
      .pipe(
        takeWhile(() => !this.fields),
      )
      .subscribe(operationQueueSettings => {
        if(!operationQueueSettings) {
          return;
        }
        if(!operationQueueSettings.fields) {
          this.fields = operationQueueSettings;
          return;
        }
        this.fields = setVisibleToFieldsInTable(operationQueueFields, operationQueueSettings.fields);
      })
  },
})
export default class OperationQueue extends Vue {
  list!: OperationQueueListData[];
  search: string;
  filter: OperationQueueFilterImp;
}
