
import {defineComponent} from "vue";
import EditForm from "@/components/base/EditForm.vue";
import CalculationRuleCharacteristics from "@/components/calculation-rules/CalculationRuleCharacteristics.vue";
import {CalculationRuleApi} from "@/api/calculationRule.api";
export default defineComponent({
  name: "CalculationRuleAdd",
  components: { CalculationRuleCharacteristics, EditForm },
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      CalculationRuleApi.save(
          characteristics
      ).then((res) => {
        this.route(characteristics.metricTypeId);
      });
    },
    route(id?) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace(`/directory/metrics-type/${id}?tab=3&sel=0`);
    },
  },
});
