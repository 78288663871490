import {send, url} from "@/helpers/api";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";
import axios from "axios";


export const MetricGroupApi = {
    applyFilter(data: any, size = 10, page = 0) {
        return send({
            url: `${url}` + `/metric-groups/filter`,
            method: 'post',
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        })
    },
    applyFilter2(data: any, size = 25, page = 0): Observable<any> {
        return Axios.request({
            url: `${url}` + `/metric-groups/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    save(data: any) {
        return send({
            url: `${url}` + `/metric-groups`,
            method: "post",
            data,
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/metric-groups/${id}`,
            method: "delete",
        });
    },
    deleteMetricIndicator(metricGroupId, indicatorId) {
        return send({
            url: `${url}` + `/metric-groups/${metricGroupId}/indicator/${indicatorId}`,
            method: "delete",
        });
    },
    deleteMetric(metricGroupId, metricId) {
        return send({
            url: `${url}` + `/metric-groups/${metricGroupId}/metric/${metricId}`,
            method: "delete",
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/metric-groups/${id}`,
            method: "get"
        })
    },
    downloadExcel(request) {
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        return axios({
            method: "post",
            url: `${url}/metric-groups/download?zoneId=${zoneId}`,
            data: request,
            responseType: "arraybuffer",
            headers: {
                "Accept": "application/octet-stream",
            }
        });
    },
    relateToIndicator(relatedId, selectedList) {
        return send({
            url: `${url}` + `/indicators/metric-groups`,
            method: "post",
            data: {
                indicatorId: relatedId,
                metricGroups: selectedList
            }
        })
    },
    relateToMetric(relatedId, selectedList) {
        return send({
            url: `${url}` + `/metrics/metric-groups`,
            method: "post",
            data: {
                metricId: relatedId,
                metricGroups: selectedList
            }
        })
    }
}
