import {Field} from "@/types/fields";
import {MetricLimitListData} from "@/types/metric-limit";
import { MonitoringObjectStatusesListData } from "@/types/monitoring-object-statuses";

export const monitoringObjectStatusesFields: Field<MonitoringObjectStatusesListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "50px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "140px"},
    {field: "color", sortDirections: ['descend', 'ascend'], name: "Цвет", value: false, minWidth: "100px"},
]
export const getMonitoringObjectStatusesData = () => ({
    id: null,
    name: '',
    color: '',
})
