import {send, url} from "@/helpers/api";
import axios from "axios";
import {Observable} from "rxjs/dist/types";
import Axios from "axios-observable";

export const AreaApi = {
    applyFilter(data: any,size = 10, page = 0 ): any {
        return send({
            url: `${url}` + `/areas/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        })
    },
    applyFilter2(data: any, size = 10, page = 0): Observable<any> {
        return Axios.request({
            method: 'post',
            url: `${url}` + `/areas/filter`,
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    applyFilterArea(data: any, size = 10, page = 0): any {
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        return send({
            url: `${url}` + `/equipments/areas/user?zoneId=${zoneId}`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getAreaType(): any {
        return send({
            url: `${url}` + `/classifiers/area-type`,
            method: "get",
        });
    },
    getStages(): any {
        return send({
            url: `${url}` + `/classifiers/area-stage`,
            method: "get",
        });
    },
    getStatus(): any {
        return send({
            url: `${url}` + `/classifiers/areas/statuses`,
            method: "get",
        });
    },
    getDivisions(name = ''): any {
        return send({
            url: `${url}` + `/classifiers/divisions`,
            method: "get",
            params: {
                name,
                page: '0',
                size: '100',
            },
        });
    },
    getRegionsList(name = '', page = 0, size = 100): any {
        return send({
            url: `${url}` + `/classifiers/regions`,
            method: "get",
            params: {
                name,
                page: page.toString(),
                size: size.toString(),
            },
        });
    },
    getDistrictsAsText(name = '', page = 0, size = 5): any {
        return send({
            url: `${url}` + `/classifiers/db/districts`,
            method: "get",
            params: {
                name,
                page: page.toString(),
                size: size.toString(),
            },
        });
    },
    getCitiesAsText(name = '', page = 0, size = 5): any {
        return send({
            url: `${url}` + `/classifiers/db/cities`,
            method: "get",
            params: {
                name,
                page: page.toString(),
                size: size.toString(),
            },
        });
    },
    getStreetsAsText(name = '', page = 0, size = 5): any {
        return send({
            url: `${url}` + `/classifiers/db/streets`,
            method: "get",
            params: {
                name,
                page: page.toString(),
                size: size.toString(),
            },
        });
    },
    getAreaEditSave(data: any) {
        return send({
            url: `${url}` + `/areas`,
            method: "post",
            data: data
        })
    },
    getById(id) {
        return send({
            url: `${url}` + `/areas/${id}`,
            method: "get",
        })
    },
    delete(id) {
        return send({
            url: `${url}` + `/areas/${id}`,
            method: "delete",
        })
    },
    getAreaIndicators(areaId) {
        return send({
            url: `${url}` + `/areas/indicators`,
            method: "get",
            params: {
                areaId
            }
        })
    },
    downloadExcel(request) {
        const zoneId = encodeURIComponent(
            "UTC+" +
            ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            }) +
            ":00"
        );
        return axios({
            method: "post",
            url: `${url}/areas/download?zoneId=${zoneId}`,
            data: request,
            responseType: "arraybuffer",
            headers: {
                "Accept": "application/octet-stream",
            }
        });
    },
    relateServiceToArea(areaId, services) {
        return send({
            url: `${url}` + `/areas/service`,
            method: "post",
            data: {areaId: areaId, services: services}
        })
    },
    archive(id) {
        return send({
            url: `${url}` + `/areas/archive?id=${id}`,
            method: "delete",
        });
    },
    unArchive(id) {
        return send({
            url: `${url}` + `/areas/unarchive/?id=${id}`,
            method: "post",
        });
    },
}
