import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pl-4 pr-4 pb-5 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OperationQueueCharacteristics = _resolveComponent("OperationQueueCharacteristics")!
  const _component_EditForm = _resolveComponent("EditForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EditForm, {
      onAdd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.add()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_OperationQueueCharacteristics, { ref: "characteristics" }, null, 512)
      ]),
      _: 1
    })
  ]))
}