import { Field } from "@/types/fields";
import {ServiceListData} from "@/types/service";
import {formatDate} from "@/filters/date";
import { defineTextColorByStatus } from "@/helpers/define-status-color";

export const serviceFields: Field<ServiceListData>[] = [
  {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "80px"},
  {field: "areaName", sortDirections: ['descend', 'ascend'], name: "Местоположение", value: true, minWidth: "150px"},
  {field: "areaCode", sortDirections: ['descend', 'ascend'], name: "Код местоположения", value: true, minWidth: "150px"},
  {field: "orderN", sortDirections: ['descend', 'ascend'], name: "Номер услуги", value: true, minWidth: "140px"},
  {field: "channelType", sortDirections: ['descend', 'ascend'], name: "Тип услуги", value: true, minWidth: "120px"},
  {field: "connectSpeed", sortDirections: ['descend', 'ascend'], name: "Скорость подключения", value: false, minWidth: "200px"},
  {field: "status", getClass: (val) => defineTextColorByStatus(val), sortDirections: ['descend', 'ascend'], name: "Статус услуги", value: true, minWidth: "100px", compute: (val) => val ? val.name : ''},
  {field: "orderNWfprocess", sortDirections: ['descend', 'ascend'], name: "Статус предоставления услуги", value: false, minWidth: "150px", compute: (val) => val ? val.name : ''},
  {field: "orderNWfstatus", sortDirections: ['descend', 'ascend'], name: "Статус заказа", value: false, minWidth: "150px", compute: (val) => val ? val.name : ''},
  {field: "contractNum", sortDirections: ['descend', 'ascend'], name: "Номер договора", value: false, minWidth: "200px"},
  {field: "dateStartService", sortDirections: ['descend', 'ascend'], name: "Дата ввода в действие", value: false,
    compute: (value = null, format = "DD.MM.yyyy, HH:mm") => formatDate(value, format), minWidth: "150px"},
  // {field: "mtu", name: "MTU", value: false, minWidth: "120px", compute: (val) => val ? val.name : ''},
  {field: "isActive", sortDirections: ['descend', 'ascend'], name: "Активность", value: true,
    minWidth: "120px", compute: (val) => val === true ?  'Да' : 'Нет'},
  {field: "fragmentation", sortDirections: ['descend', 'ascend'], name: "Фрагментация", value: false,
    minWidth: "120px", compute: (val) => val === true ? 'Да' : 'Нет'},
  {field: "measure", sortDirections: ['descend', 'ascend'],  name: "Размерность", value: false, minWidth: "200px", compute: (val) => val ? val.name : ''},
  // {field: "trafficSize", sortDirections: ['descend', 'ascend'], name: "Размер трафика", value: false, minWidth: "150px"},
  {field: "deleted",sortDirections: ['descend', 'ascend'],  name: "Архивирован", value: true, minWidth: "150px",
    compute: (val) => val === true ? 'Да' : 'Нет'},
];
export const getServiceData = () => ({
  id: null,
  serviceNum: null,
  dateStartContr: null,
  contract: {
    id: null,
    number: "",
    createDate: "",
  },
  interfaceType: null,
  connectSpeed: null,
  measure: {name: "", id: null},
  dateStartService: "",
  trafficSize: null,
  orderN: "",
  mtu: "",
  channelType: {name: "", id: null},
  lastMileOperator: null,
  status: {id: null, name: ""},
  area: { id: null, name: "", address: "", code: null },
  fragmentation: false,
  orderNWfprocessId: null,
  orderNWfstatusId: null,
  orderNWfstatus: { id: null, name: "" },
  orderNWfprocess: { id: null, name: "" },
  isActive: true,
  client: { id: null, name: "" },
  deleted: ""
});
