
import {Vue, Options} from "vue-class-component";
import {currentUser} from "@/consts/currentUser";
import {suffix} from "@/consts/nameSuff";

@Options({
  components: {},
  beforeRouteLeave() {
    suffix.next(null);
  },
  methods: {
    routeToUrl(url) {
      this.$router.push(url);
    },
    hasAccessToRead(cell) {
      if( !!cell && cell.menuCode) {
        return currentUser.value.access.items.find((res) => res.menuCode === cell?.menuCode).read
      } else  {
        return false
      }
    }
  },
  mounted() {
    console.log(localStorage.getItem('name'))
    suffix.next(' ' + localStorage.getItem('name'))
    if (currentUser.value.userRoles.includes('nav_contracts')) {
      this.menuData[0].url = "/monitoring/objects/"
      this.menuData[4].url = "/directory/contracts"

    }
  },
  data: () => {
    return {
      currentUser,
      menuData: [
        {title: 'Мониторинг' , url: '/services/' , menuCode: 'monitoring'},
        {title: 'Заявки' , url: '/incidents/', role: 'nav_incidents', menuCode: 'incidents',},
        {title: 'Отчеты' , url: '/reports/', role: 'nav_reports',  menuCode: 'reports',},
        {title: 'Аналитика' , url: '/dashboards/', role: 'nav_analitics',  menuCode: 'analytic',},
        {title: 'Справочники' , url: '/directory/menu', role: ['nav_dictionary', 'support_engineer_internal'],menuCode: 'dictionary',},
        {title: 'Настройки' , url: '/settings/processes', role: 'nav_settings',  menuCode: 'settings',},
        {title: 'Безопасность' , url: '/security/executor-users', role: 'nav_security', menuCode: 'security',},
      ]
    }
  }
})
export default class MainMenu extends Vue
{

}
