import {engineRestUrl, send, url, withoutAPIUrl} from "@/helpers/api";
import axios from "axios";
import Axios from  'axios-observable';
import {Observable} from "rxjs/dist/types";

export const IncidentsApi = {
  applyFilter(data: any, size = 10, page = 0): any {
    const zoneId = encodeURIComponent(
      "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return send({
      url: `${url}` + `/incidents/filter?zoneId=${zoneId}`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilter2(data: any, size = 10, page = 0): Observable<any> {
    const zoneId = encodeURIComponent(
      "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );

    return Axios.request({
      method: 'post',
      url: `${url}` + `/incidents/filter?zoneId=${zoneId}`,
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getNextStatusesById(incidentId) {
    return send({
        method: 'get',
        url: `${url}` + `/incidents/${incidentId}/next-statuses`,
      })
  },
  getIncidentFilterList(): any {
    return send({
      url: `${url}` + `/classifiers/incident`,
      method: "get",
    });
  },
  getHistoryList(data, size = 10, page = 0) {
    return send({
      url: `${url}` + `/incidents/status-history/filter`,
      method: "post",
      data,
      params: {
        size,
        page
      }
    })
  },
  getCommentList(data, size = 10, page = 0) {
    return send({
      url: `${url}` + `/incidents/comments/filter`,
      method: "post",
      data,
      params: {
        size,
        page
      }
    })
  },
  addCommentNotification(data) {
    return send({
      url: `${url}` + `/incidents/comment/notification`,
      method: "post",
      data,
      params: {
        commentId: data.commentId
      }
    })
  },
  deleteComment(commentId, fileId?) {
    return send({
      url: `${url}` + `/incidents/comment`,
      method: "delete",
      params: {
        commentId: commentId,
        fileId: fileId
      }
    })
  },
  deleteFile(fileId) {
    return send({
      url: `${url}` + `/incidents/comment`,
      method: "delete",
      params: {
        fileId: fileId
      }
    })
  },
  addComment(data, isNotification?) {
    if(isNotification) {
      return send({
        url: `${url}` + `/incidents/comment?isNotification=${isNotification}`,
        method: "post",
        data
      })
    } else {
      return send({
        url: `${url}` + `/incidents/comment`,
        method: "post",
        data
      })
    }
  },
  uploadCommentFiles(data) {
    return send({
          url: `${url}` + `/incidents/file`,
          method: "post",
          data
        })
  },
  getAreas(data: any, size = 20, page = 0) {
    return send({
      url: `${url}` + `/areas/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  addRequest(data: any) {
    return send({
      url: `${url}` + `/incidents`,
      method: "post",
      data,
    });
  },
  getById(id): any {
    return send({
      url: `${url}` + `/incidents/${id}`,
      method: "get"
    });
  },
  save(data): any {
    return send({
      url: `${url}` + `/incidents/edit`,
      method: "post",
      data
    });
  },
  delete(id): any {
    return send({
      url: `${url}` + `/incidents?id=${id}`,
      method: "delete",
    });
  },
  fileSaveProgress(id, fileSize): any {
    return send({
      url: `/files/progress/saved?fileId=${id}&fileSize=${fileSize}`,
      method: "get",
    });
  },
  downloadFile(id): any {
    return send({
      url: `${url}` + `/incidents/file/${id}`,
      method: "get",
      responseType: "arraybuffer",
      headers: {
        "Accept": "application/octet-stream",
      }
    });
  },
  downloadExcel(request, hideSupportTeam: boolean, exporttype: string) {
    const zoneId = encodeURIComponent(
      "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return axios({
      method: "post",
      url: `${url}/incidents/download?hideSupportTeam=${hideSupportTeam}&exporttype=${exporttype}&zoneId=${zoneId}`,
      data: request,
      responseType: "arraybuffer",
      headers: {
        "Accept": "application/octet-stream",
      }
    });
  },
  getProcessDefinition(instanceId = 'Process_sc-incident:21:cd5cdf18-bf2a-11ec-817f-164f8af77583') {
    return send({
      url: `${engineRestUrl}/process-definition/${instanceId}/xml`,
      method: "get",
    })
  },
  getActivityInstance(processInstanceId = '9594ecbe-c0a3-11ec-ac7a-0242ac110004') {
    return send({
      url: `${engineRestUrl}/history/activity-instance?processInstanceId=${processInstanceId}`,
      method: "get",
    })
  },
  getProcessInstanceId(id) {
    return send({
      url: `${url}/process/incident/${id}/process-instance-id`,
      method: "get",
    })
  },
  getNextStatuses() {
    return send({
          url: `${url}/process/incident/1227895/nextstatuses`,
          method: "get",
        })
  },
  processDefinition() {
    return send({
          url: `${engineRestUrl}/process-definition/key/Process_sc-incident`,
          method: "get",
        })
  },
  setStatus(data) {
    return send({
          url: `${url}/process/incident/${data.incidentId}/status`,
          method: "post",
          data
        })
  },
  archive(id) {
    return send({
      url: `${url}` + `/incidents/archive?id=${id}`,
      method: "delete",
    });
  },
  unArchive(id) {
    return send({
      url: `${url}` + `/incidents/unarchive/?id=${id}`,
      method: "post",
    });
  },
};
