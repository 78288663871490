import {Field} from "@/types/fields";
import {CustomAttributesListData} from "@/types/custom-attributes";

export const customAttributesFields: Field<CustomAttributesListData>[] = [
    {field: "id", sortDirections: ['descend','ascend'], name: "ID", value: true, minWidth: "100px"},
    {field: "name", sortDirections: ['descend','ascend'], name: "Наименование", value: true, minWidth: "180px"},
    {field: "listOrder", sortDirections: ['descend','ascend'], name: "Позиция в списке", value: true, minWidth: "180px"},
    {field: "entityName", sortDirections: ['descend','ascend'], name: "Сущность", value: true, minWidth: "180px",
        compute: (val) => val === 'incident' ? 'Заявки' : 'Объекты'
    },
    {field: "isShowInAttributes", sortDirections: ['descend','ascend'], name: "Отображать в карточке", value: true, minWidth: "180px",  compute: (val) => val === true ? 'Да' : 'Нет'},
    {field: "isSearcheable", sortDirections: ['descend','ascend'], name: "Отображать в фильтрах", value: true, minWidth: "180px",  compute: (val) => val === true ? 'Да' : 'Нет'},
]
export const getCustomAttributesData = () => ({
    id: null,
    name: '',
    entityName: '',
    isShowInAttributes: null,
    isSearcheable: false,
    listOrder: null,
    type: '',
    attributeName: ''
})
