export const DTO_FIELDS = {
  IncidentDto: 'Заявки',
  ExecutorListDto: 'Пользователи',
  ServiceDto: 'Услуги', // -
  AreaListDto: 'Местоположения',
  FileManagerFilterDto: 'Управление файлами',
  AreaStageDto: 'Этапы внедрения',
  BrandDto: 'Производители',
  EquipmentTypeDto: 'Производители',
  GroupDto: 'Группы',
  // IndicatorInstancesDto: 'Типы показателей',
  EntityProcessDefinitionDto: 'Процессы',
  IndicatorListDto: 'Типы показателей',
  MonitoringObjectListDto: 'Объекты',
  // MonitoringObjectListTreeDto: 'Объекты',
  ReportsExecDto: 'Отчеты',
  MetricGroupListDto: 'Группы метрик',
  ModelListDto: 'Модели',
  UrgencyDto: 'Срочность',
  ChannelTypeDto: 'Типы услуги',
  AreaTypeDto: 'Типы местоположений',
  ServiceListDto: 'Услуги',
  MetricListDtoWithMetricGroups: 'Типы метрик',
  CalculateStatusRuleDto: 'Правила расчета статусов',
  ResponseZoneDto: 'Зоны ответственности',
  MonitoringObjectTypeListDto: 'Типы объектов',
  DepartmentListDto: 'Подразделения',
  ContractListDto: 'Договоры',
  SupportTeamListDto: 'Команды поддержки',
  SystemLogoListDto: 'Система',
  UnitDto: 'Единицы измерения',
  UserRoleDto: 'Роли',
  MetricTagDto: 'Категории метрик',
  MeasureDto: 'Единицы измерения',
  CalculationRuleDto: 'Правила расчета',
  NoticeTemplateListDto: 'Шаблоны уведомлений',
  CounterpartyListDto: 'Контрагенты'
}
