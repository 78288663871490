
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {ClientsApi} from "@/api/clients.api";
import {getSystemData} from "@/consts/fields/system.fields";

export default defineComponent ({
  name: "SystemCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getSystemData(),
    clientList: [],

    validationFields:[
      {field: "client.name", validators: ["required"]},
    ]
  }),
  mounted() {
    this.getClients();
    this.getCRUDSettings(108)
  },
  methods: {
    getClients() {
      ClientsApi.applyFilter({}, 10, 0).then((res) => {
        this.clientList = res.data;
      });
    },
    onSelectClient(value, option) {
      this.data.client = {
        name: option.value,
        id: option.id,
      };
    },
    customRequest(ev) {
      console.log(ev);
    },
    handleChange(files) {
      files.fileList = files.fileList.map(_ => {_.status = 'done'; return _})
      this.bigLogo = files.fileList[0];
    },
    handleChanges(files) {
      console.log(files)
      files.fileList = files.fileList.map(_ => {_.status = 'done'; return _})
      this.smallLogo = files.fileList[0];
    },
    handleChangess(files) {
      console.log(files)
      files.fileList = files.fileList.map(_ => {_.status = 'done'; return _})
      this.mediumLogo = files.fileList[0];
    },
    getExtension (name) {
      if (name.slice(name.indexOf('.') + 1) === 'svg') {
        return 'svg+xml'
      }
      else {
        return  name.slice(name.indexOf('.') + 1)
      }
    },
  },
})
