import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22ca0b58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "incidents content-container" }
const _hoisted_2 = { class: "flex justify-content-between align-items-center" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"justify-content":"center","font-size":"22px","font-weight":"600","color":"#3D535C","margin-top":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_row, {
        class: "raw",
        style: {"justify-content":"center","width":"100%"}
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuData, (item) => {
            return (_openBlock(), _createBlock(_component_a_col, {
              span: 7,
              style: {"margin":"20px"},
              class: _normalizeClass([_ctx.hasAccessToRead(item) ? '' : 'display-none'])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "menu-cell",
                  onClick: ($event: any) => (_ctx.routeToUrl(item.url))
                }, [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(item.title), 1)
                ], 8, _hoisted_3)
              ]),
              _: 2
            }, 1032, ["class"]))
          }), 256))
        ]),
        _: 1
      })
    ])
  ]))
}