import {send, url} from "@/helpers/api";

export const NoticeTemplateApi = {
    applyFilter(data: any, size = 10, page = 0) {
        return send({
            url: `${url}` + `/classifiers/notice-template/filter`,
            method: 'post',
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        })
    },
    getById(id){
        return send({
            url: `${url}` + `/classifiers/notice-template/${id}`,
            method: "get",
        });
    },
    save(data: any) {
        return send({
            url: `${url}` + `/classifiers/notice-template`,
            method: "post",
            data,
        });
    },
    delete(id: string) {
        return send({
            url: `${url}` + `/classifiers/notice-template/${id}`,
            method: "delete"
        });
    },
}