
import { defineComponent, PropType } from "vue";
import { AreaApi } from "@/api/area.api";
import AreaCharacteristics from "@/components/areas/AreaCharacteristics.vue";
import { IncidentsApi } from "@/api/incidents.api";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import moment from "moment";
import { ClickhouseApi } from "@/api/clickhouse";
import Graphic from "@/components/base/Graphic.vue";
import { Period } from "@/views/monitoring-objects/MonitoringObjectsView.vue";
import ViewBase from "@/classes/ViewBase.vue";
import MonitoringBase from "@/classes/MonitoringBase.vue";
import MetricIndicatorList from "@/components/metrics-indicators/MetricIndicatorList.vue";
import { MetricIndicatorListData } from "@/types/metric-indicator";
import { metricIndicatorFields } from "@/consts/fields/metrics-indicators.fields";
import { Field } from "@/types/fields";
import { MetricIndicatorFilterImp } from "@/components/classes/MetricIndicatorFilterImp";
import { IndicatorApi } from "@/api/indicator.api";
import MetricsIndicatorsFilter from "@/components/metrics-indicators/MetricsIndicatorsFilter.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import MonitoringObjectsList from "@/components/monitoring-objects/MonitoringObjectsList.vue";
import MonitoringObjectsFilter from "@/components/monitoring-objects/MonitoringObjectsFilter.vue";
import IncidentList from "@/components/incidents/IncidentList.vue";
import IncidentsFilter from "@/components/incidents/IncidentsFilter.vue";
import ServiceList from "@/components/services/ServiceList.vue";
import ServicesFilter from "@/components/services/ServicesFilter.vue";
import { ServiceApi } from "@/api/service.api";
import ArchiveDialog from "@/components/dialogs/ArchiveDialog.vue";
import { suffix } from "@/consts/nameSuff";
import { currentUser } from "@/consts/currentUser";

export default defineComponent({
  name: "AreaView",
  components: {
    MetricsIndicatorsFilter,
    MetricIndicatorList,
    AreaCharacteristics,
    DeleteDialog,
    EditForm,
    Graphic,
    TableSettings,
    MonitoringObjectsList,
    MonitoringObjectsFilter,
    IncidentList,
    IncidentsFilter,
    ServiceList,
    ServicesFilter,
    ArchiveDialog
  },
  mixins: [ViewBase, MonitoringBase],
  data: () => ({
    list: [] as MetricIndicatorListData[],
    body: {},
    selectorData: [
      { value: "Объекты", menuCode: "monitoring", childMenuCode: "object" },
      { value: "Заявки", menuCode: "incidents", childMenuCode: null },
      { value: "Услуги", menuCode: "monitoring", childMenuCode: "service" }
    ],
    currentSelect: "Объекты",
    visibleSidebarFilter: false,
    visibleSidebarTableSettings: false,
    type: "area",
    currentUser
  }),
  mounted() {
    this.getById();

    const accessParams = { module: "dictionary", object: "locations" };
    const tabMap = {
      "tab_common_information_locations": "1",
      "tab_relation_locations": "3",
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm(
      "Закрыть сайт? Возможно внесенные изменения не сохранятся!"
    );
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openArchiveDialog() {
      if (this.body.deleted) {
        this.$refs.archive.openDialog("Восстановление местоположения",
          `восстановить из архива  местоположение ${this.body.id}`, "Восстановить");
      } else {
        this.$refs.archive.openDialog("Архивирование местоположения ",
          `архивировать  местоположение ${this.body.id} `, "В архив");
      }
    },
    archiveObject() {
      if (this.body.deleted) {
        AreaApi.unArchive(this.body.id).then(() => {
          this.$notification.open({
            message: "Восстановлено из архива",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px"
            },
            placement: "bottomRight",
            duration: 3
          });
          this.getById();
        });
      } else {
        AreaApi.archive(this.body.id).then(() => {
          this.$notification.open({
            message: "Архивировано",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px"
            },
            placement: "bottomRight",
            duration: 3
          });
          this.getById();
        });
      }
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog(
        "местоположения",
        `местоположение ${this.body.name}`
      );
    },
    getById() {
      AreaApi.getById(this.$route.params.id)
        .then((res) => {
          this.body = res;
          suffix.next(" " + res.name);
        })
        .finally(() => {
          this.$refs.characteristics.cleanWarning();
        });
    },
    // getMonitoringData({ id, instanceName, name }) {
    //   this.isLoadingGraphic = true;
    //   ClickhouseApi.getIndicatorData(
    //     instanceName,
    //     "day",
    //     this.period[0].format("DD.MM.yyyy HH:mm:ss"),
    //     this.period[1].format("DD.MM.yyyy HH:mm:ss"),
    //     ""
    //   ).then((res) => this.computeMonitoringData({ res, id, name }));
    // },
    findDefaultMetric() {
      this.selectedMetrics.push(
        this.metrics.reduce(
          (prev, current) => prev.concat(current.indicators),
          []
        ).find((metric) => metric.name === "Статус объекта")
      );
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics =
        this.$refs.characteristics.formatCharacteristicsCopy();
      delete characteristics.services;
      AreaApi.getAreaEditSave(characteristics).then((res) => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px"
          },
          placement: "bottomRight",
          duration: 3
        });
        this.getById();
      });
    },
    deleteObject() {
      AreaApi.delete(this.$route.params.id).then((res) => {
        this.$router.go(-1);
      });
    }
  },
  emits: ["select"]
});
