
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getCounterpartyData} from "@/consts/fields/counterparty.fields";
import {ExecutorUsersApi} from "@/api/executor-users.api";
import {SupportTeamApi} from "@/api/supportTeam.api";

export default defineComponent({
  name: "CounterpartyCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getCounterpartyData(),
    leaderList: [],
    supportTeamList: [],
    supportTeamUserList: [],
    statusList: [
      {id: 1, name: "Активен"},
      {id: 2, name: "Неактивен"},
    ],
    typeList: [
      {id: 1, name: "Клиент"},
      {id: 2, name: "Исполнитель"}
    ],
    timeList: [
      {id: 1, name: "1:00"},
      {id: 2, name: "2:00"},
      {id: 3, name: "3:00"},
      {id: 4, name: "4:00"},
      {id: 5, name: "5:00"},
      {id: 6, name: "6:00"},
      {id: 7, name: "7:00"},
      {id: 8, name: "8:00"},
      {id: 9, name: "9:00"},
      {id: 10, name: "10:00"},
      {id: 11, name: "11:00"},
      {id: 12, name: "12:00"},
      {id: 13, name: "13:00"},
      {id: 14, name: "14:00"},
      {id: 15, name: "15:00"},
      {id: 16, name: "16:00"},
      {id: 17, name: "17:00"},
      {id: 18, name: "18:00"},
      {id: 19, name: "19:00"},
      {id: 20, name: "20:00"},
      {id: 21, name: "21:00"},
      {id: 22, name: "22:00"},
      {id: 23, name: "23:00"},
      {id: 24, name: "00:00"},
    ],
    validationFields: [
      {field: "name", validators: ["required"]},
      {field: "status.name", validators: ["required"]},
      {field: "type.name", validators: ["required"]},
      {field: "supportTeamDefault.name", validators: ["required"]},
    ],
  }),
  mounted() {
    this.getLeaderList();
    this.getSupportTeamList();
    if (this.data.supportTeamDefault.id) {
      this.getUsersList(this.data.supportTeamDefault.id)
    }
    this.getCRUDSettings(71)
  },
  methods: {
    onSelect(value, option) {
      const key = "id";
      this.data[option.field][key] = option[key];
    },
    changeAuto(event?) {
      if (!event) {
        this.clean();
      }
    },
    clean() {
      this.data.contactUser = {id: 0, name: ""};
    },
    getLeaderList() {
      ExecutorUsersApi.applyFilter({fio: this.data.contactUser.name}, 25, 0)
          .then((res) => {
            this.leaderList = res.data;
          });
    },
    getUsersList(user) {
      ExecutorUsersApi.applyFilter({supportTeamIds: [user]}, 25)
          .then((res) => {
            this.supportTeamUserList = res.data;
          })
    },
    getSupportTeamList() {
      SupportTeamApi.applyFilter({name: this.data.supportTeamDefault.name, statuses: [1,2]}, 25, 0)
          .then((res) => {
            this.supportTeamList = res.data;
          })
    },
    selectLeader(value, option) {
      this.onSelect(value, option);
    },
    selectSupportTeam(value, option) {
      this.data.responsible.id = null
      this.data.responsible.name = ''
      this.getUsersList(option.id)
      this.onSelect(value,option)
    },
    selectWorkTime(value, option) {
      this.data[option.field] = option.id
    },
    responsibleSelect(e) {
      if(e === undefined) {

        this.data.responsible.id = null
        this.data.responsible.name = ''
        console.log(this.data)
        this.getUserList(this.data.supportTeamDefault.id);
      }
    }
  }
})
