
    import { Options, Vue } from "vue-class-component";
    import {getRouteProps} from "@/helpers/route";
    import UpperMenu from "@/components/ui/UpperMenu.vue";
    import SidebarMenu from "@/components/ui/SidebarMenu.vue";
    import ruRU from 'ant-design-vue/es/locale/ru_RU';
    import {AuthService} from "@/api/auth";
    import {currentUser} from "@/consts/currentUser";
    import * as jwt from 'jsonwebtoken';

    @Options({
        components: {
            SidebarMenu,
            UpperMenu,
        },
        data: () => ({
            locale: ruRU,
            isOpened: true,
        }),
        mounted() {
            setTimeout(() => {
                console.log();
                console.log(this.$attrs);
            }, 300);
        },
    })
    export default class App extends Vue {
        get title(): string {
            if (this.$route.query?.isRelated) {
                return `Добавление взаимосвязи ${this.$route.query?.relatedTitle} ${this.$route.query?.relatedName} ${getRouteProps(this.$route)?.relate}`;
            }
            else {
                return getRouteProps(this.$route)?.title || '';
            }
        }
        get hasBackButton(): boolean {
            return (!!getRouteProps(this.$route)?.hasBackButton || !!this.$route.query.hasBackButton) as boolean;
        }

        mounted() {
            // this.getCurrentUser()
        }
    }
