import {Field} from "@/types/fields";
import {CounterpartyListData} from "@/types/counterparty";

export const counterpartyFields: Field<CounterpartyListData>[] = [
    {field: "id", sortDirections: ['descend','ascend'], name: "ID", value: true, minWidth: "100px"},
    {field: "name", sortDirections: ['descend','ascend'], name: "Наименование", value: true, minWidth: "180px"},
    {field: "typeName", sortDirections: ['descend','ascend'], name: "Тип", value: true, minWidth: "180px"},
    {field: "statusName", sortDirections: ['descend','ascend'], name: "Статус", value: true, minWidth: "180px"},
    {field: "inn", sortDirections: ['descend','ascend'], name: "ИНН", value: false, minWidth: "180px"},
    {field: "contactUserName", sortDirections: ['descend','ascend'], name: "Контактное лицо", value: false, minWidth: "180px"},
]
export const getCounterpartyData = () => ({
    id: null,
    name: '',
    inn: '',
    kpp: '',
    ogrn: '',
    shortName: '',
    contactUser: {
        id: null, name: ""
    },
    email: '',
    phone: '',
    startWork: null,
    endWork: null,
    status: {id: null, name: ""},
    type: {id: null, name: ""},
    supportTeamDefault: {id: null, name: ''},
    responsible: {id: null, name: ''}
})