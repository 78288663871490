import {Field} from "@/types/fields";
import {IncidentTypeListData} from "@/types/incident-type";

export const IncidentTypeFields: Field<IncidentTypeListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "150px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
]

export const getIncidentTypeData = () => ({
    id: null,
    name: null,
})