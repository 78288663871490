import {Field} from "@/types/fields";
import {SystemListData} from "@/types/system";

export const systemFields: Field<SystemListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "200px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
    {field: "client", sortDirections: ['descend', 'ascend'], name: "Клиент", value: true, minWidth: "" },
    {field: "isDefault", sortDirections: ['descend', 'ascend'], name: "По умолчанию", value: true, minWidth: "",  compute: (val) => val === true ? 'Да' : 'Нет'},
]
export const getSystemData = () => ({
    id: null,
    name: '',
    client: {
        id: null,
        name: ""
    },
    isDefault: false,
    bigLogo: '',
    bigLogoName: '',
    smallLogoName: '',
    mediumLogoName: ''
})
