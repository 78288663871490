import {Field} from "@/types/fields";
import {DepartmentListData} from "@/types/department";

export const departmentsFields: Field<DepartmentListData>[] = [
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "240px"},
    {field: "parent", sortDirections: ['descend', 'ascend'], name: "Головной тип", value: true, minWidth: "", compute: (val) => val?.name}
]

export const getDepartmentsData = () => ({
    id: null,
    name: "",
    parent: {id: null, name: ''},
    counterparty: {id: null, name: ''},
})