
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getGroupsData} from "@/consts/fields/groups.fields";

export default defineComponent({
  name: "GroupCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getGroupsData(),
    validationFields:[
      {field: "name", validators: ["required"]},
    ],
  }),
  methods: {
    clean(){
      this.data = getGroupsData();
    },
  },
  mounted() {
    this.getCRUDSettings(93)
  }
})
