import {send, url} from "@/helpers/api";

export const ChangelogApi = {
    applyFilter(data: any, size = 10, page = 0): any {
        return send({
            url: `/skyv-mon/audit/entity/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getAuditClasses() {
        return send({
            url: `/skyv-mon/audit/classes`,
            method: "get",
        });
    },
    getAtributFieldList(name) {
        return send({
            url: `/skyv-mon/audit/classes/fields?name=${name}`,
            method: "get",
        });
    }
}