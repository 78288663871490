
import { defineComponent } from "vue";
import { DashboardsApi } from "@/api/dashboards.api";
import DashboardAnalyticCard from "@/views/dashboards/DashboardAnalyticCard.vue";
import { getListObjectMock } from "@/helpers/listObject";
import {
  GET_INCIDENT_RELATED_OBJECT, GET_MONITORING_OBJECT_RELATED_OBJECT,
  GET_SERVICE_RELATED_OBJECT,
} from "@/classes/ViewBase.vue";
import IncidentList from "@/components/incidents/IncidentList.vue";
import ServiceList from "@/components/services/ServiceList.vue";
import { currentUser } from "@/consts/currentUser";
import MonitoringObjectsList from "@/components/monitoring-objects/MonitoringObjectsList.vue";
import MonitoringObjectsFilter from "@/components/monitoring-objects/MonitoringObjectsFilter.vue";
export default defineComponent({
  components: {
    DashboardAnalyticCard,
    IncidentList,
    ServiceList,
    MonitoringObjectsList,
    MonitoringObjectsFilter
  },
  mounted() {
    if(localStorage.getItem('displayIncidents')) {
      this.visibleWidgetSettings.displayIncidents = localStorage.getItem('displayIncidents') === 'true';
    }
    if(localStorage.getItem('displayServices')) {
      this.visibleWidgetSettings.displayServices = localStorage.getItem('displayServices') === 'true';
    }
    if(localStorage.getItem('displayMonitoringObjects')) {
      this.visibleWidgetSettings.displayMonitoringObjects = localStorage.getItem('displayMonitoringObjects') === 'true';
    }
    if(localStorage.getItem('displayProblemRegions')) {
      this.visibleWidgetSettings.displayProblemRegions = localStorage.getItem('displayProblemRegions') === 'true';
    }
  },
  data: () => ({
    visibleWidgetSettings: {
      displayIncidents: true,
      displayServices: true,
      displayMonitoringObjects: true,
      displayProblemRegions: true,
    },
    currentUser,
    operator: null,
    client_user: null,
    isVisibleWidgetSettings: false,
    currentLegend: null,
    currentLegendFrom: null as "incidents" | "services" | "monitoring-objects",
    isDrawerOpened: false,
    isAnalyticsLoaded: false,
    currentRelatedObject: { ...GET_INCIDENT_RELATED_OBJECT() },
    relatedObjects: {
      incidents: {
        ...GET_INCIDENT_RELATED_OBJECT(),
      },
      services: {
        ...GET_SERVICE_RELATED_OBJECT(),
      },
      'monitoring-objects': {
        ...GET_MONITORING_OBJECT_RELATED_OBJECT(),
      },
    },
    period: null,
    regionsStatistics: {
      isLoad: false,
      size: 10,
      isAnalyticsLoaded: false,
      data: [
        {
          code: "availableCount",
          title: 'Доступно',
          color: "#33C5FF",
          count: [],
          links: `/monitoring?statuses=1&isBackBtn=true`,
        },
        {
          code: "failedCount",
          title: 'Недоступно',
          color: "#F53DA8",
          count: [],
          links: `/monitoring?statuses=1&isBackBtn=true`,
        },
      ]
    },
    areasByStatus: {
      isLoad: false,
      isAnalyticsLoaded: false,
      data: [
        {
          code: "available",
          color: "#33C5FF",
          links: `/monitoring?statuses=1&isBackBtn=true`,
        },
        {
          code: "warning",
          color: "#5563F6",
          links: `/monitoring?statuses=2&statuses=4&isBackBtn=true`,
          queryParams: {
            statuses: [2, 4],
            isBackBtn: true,
          },
        },
        {
          code: "failed",
          color: "#F53DA8",
          links: `/monitoring?statuses=3&statuses=5&statuses=6&isBackBtn=true`,
        },
      ]
    },
    monitoringObjectByStatus: {
      filterVisibility: false,
      isLoad: false,
      isAnalyticsLoaded: false,
      filter: {},
      fields: [
        {field: "type", sortDirections: ['descend', 'ascend'], name: "Тип объекта", value: true, minWidth: "175px",
          compute: (val) => val ? val.name : ''},
        {field: "brand", sortDirections: ['descend', 'ascend'], name: "Производитель", value: true, minWidth: "150px",
          compute: (val) => val ? val.name : ''},
        {field: "model", sortDirections: ['descend', 'ascend'], name: "Модель", value: true, minWidth: "120px",
          compute: (val) => val ? val.name : ''},
      ],
      data: [
        {
          code: "1",
          color: "#33C5FF",
          links: `/monitoring-objects?statuses=1&isBackBtn=true`,
        },
        {
          code: "2",
          color: "#5563F6",
          links: `/monitoring-objects?statuses=2&statuses=4&isBackBtn=true`,
          queryParams: {
            statuses: [2, 4],
            isBackBtn: true,
          },
        },
        {
          code: "3",
          color: "#F53DA8",
          links: `/monitoring-objects?statuses=3&statuses=5&statuses=6&isBackBtn=true`,
        },
      ]
    },
    incidentsByCategory: {
      isLoad: false,
      isAnalyticsLoaded: false,
      data: [
        {
          code: "1",
          color: "#33C5FF",
        },
        {
          code: "2",
          color: "#5563F6",
        },
        {
          code: "3",
          color: "#7027D8",
        },
        {
          code: "4",
          color: "#F53DA8",
        },
      ]
    },
  }),
  methods: {
    handleChangeSizeProblemRegions(size: number) {
      this.regionsStatistics.size = size;
      this.getRegions();
    },
    updateFilter(filter, from: "incidents" | "services" | "monitoring-objects") {
      if(from === 'monitoring-objects') {
        this.monitoringObjectByStatus.filter = Object.assign({}, filter);
        this.getMonitoringObjectByStatusStatistics();
      }
    },
    changeStateVisibleWidgetSettings() {
      this.getWidgets();
    },
    getWidgets() {
      DashboardsApi.getWidgets().then(res => {
        this.operator = res.filter(set => set.role == 'operator')[0];
        this.client_user = res.filter(set => set.role == 'client_user')[0];
        this.operator.settingsJson = JSON.parse(this.operator.settingsJson);
        this.client_user.settingsJson = JSON.parse(this.client_user.settingsJson);
      })
    },
    saveWidgetsSettings() {
      let widgetsSettings = [];
      let operator = JSON.parse(JSON.stringify(this.operator));
      operator.settingsJson = JSON.stringify(operator.settingsJson);
      let client_user = JSON.parse(JSON.stringify(this.client_user));
      client_user.settingsJson = JSON.stringify(client_user.settingsJson);
      widgetsSettings.push(operator);
      widgetsSettings.push(client_user);

      DashboardsApi.saveWidgets(widgetsSettings)
          .toPromise()
          .then(() => {
          });
    },
    updateList(value) {
      this.isLoad = false;
      let order = "";
      let column = "";
      if (value.columnKey === "active") {
        column = "activity";
      } else {
        column = value.columnKey;
      }
      if (value.order) {
        order = value.order === "descend" ? "desc" : "asc";
      } else {
        this.currentRelatedObject.filter.sortParams = [
          { sortFieldName: "id", direction: "desc" },
        ];
        this.applyCurrentRelatedFilter();
        return;
      }
      let sortParams: any = { sortFieldName: column, direction: order };
      this.currentRelatedObject.filter.sortParams = [sortParams];
      this.applyCurrentRelatedFilter();
    },
    updatePage({ page, size }) {
      this.currentRelatedObject.size = size;
      this.currentRelatedObject.page = page - 1;
      this.currentRelatedObject.listPage = page;
      this.applyCurrentRelatedFilter();
    },
    handleClickOnLegend(part, from) {
      this.isDrawerOpened = true;

      this.currentLegend = part;
      if (from !== this.currentLegendFrom) {
        this.currentLegendFrom = from;
        this.currentRelatedObject = this.relatedObjects[this.currentLegendFrom];
      }
      this.applyCurrentRelatedFilter();
    },
    getStatusByCode(code) {
      if (code === "warning") {
        return [2, 4];
      }
      if (code === "failed") {
        return [3, 5, 6];
      }
      return [1];
    },
    applyCurrentRelatedFilter() {
      let filter;
      if(this.currentLegendFrom === "incidents") {
        filter = {
          types: [this.currentLegend.code],
          creationDateEnd: this.period[1].format("DD.MM.yyyy"),
          creationDateStart: this.period[0].format("DD.MM.yyyy"),
        }
      } else if (this.currentLegendFrom === "services") {
        filter = { serviceStatuses: this.getStatusByCode(this.currentLegend.code) }
      } else {
        filter = { statuses: [this.currentLegend.code] }
      }
      this.currentRelatedObject
          .get(
              { ...this.currentRelatedObject.filter, ...filter },
              this.currentRelatedObject.size,
              this.currentRelatedObject.page
          )
          .then((res) => {
            this.currentRelatedObject.list = res.data;
            this.currentRelatedObject.totalCount = res.totalCount;
            this.isLoad = true;
          });
    },
    getIncidentsByCategoryStatistics(period) {
      this.period = period;
      this.incidentsByCategory.isAnalyticsLoaded = false;
      DashboardsApi.getIncidentsByCategory(period[0], period[1]).then(
          (res) => {
            this.incidentsByCategory.data = this.setAnalyticsByCode(
                res,
                this.incidentsByCategory.data
            );
            this.incidentsByCategory.isAnalyticsLoaded = true;
          }
      );
    },
    getMonitoringObjectByStatusStatistics(period) {
      this.period = period;
      this.monitoringObjectByStatus.isAnalyticsLoaded = false;
      DashboardsApi.getMonitoringObjectByStatusStatistics(this.monitoringObjectByStatus.filter).then(
          (res) => {
            this.monitoringObjectByStatus.data = this.setAnalyticsByCode(
                res,
                this.monitoringObjectByStatus.data
            );
            this.monitoringObjectByStatus.isAnalyticsLoaded = true;
          }
      );
    },
    getAreaByStatusStatistics(period) {
      this.period = period;
      this.areasByStatus.isAnalyticsLoaded = false;
      DashboardsApi.getAreaByStatusStatistics().then(
          (res) => {
            this.areasByStatus.data = this.setAnalyticsByCode(
                res,
                this.areasByStatus.data
            );
            this.areasByStatus.isAnalyticsLoaded = true;
          }
      );
    },
    getRegions() {
      this.regionsStatistics.isAnalyticsLoaded = false;
      DashboardsApi.getRegionStats({
        direction: "desc",
        sortFieldName: "failedPercent"
      }, '', this.regionsStatistics.size).then((res) => {
        this.regionsStatistics.data[0].count = [];
        this.regionsStatistics.data[1].count = [];
        this.regionsStatistics.data.forEach(regionStatistics => {
          res.forEach(regionStatisticsFromRequest => {
            regionStatistics.count.push({value: regionStatisticsFromRequest[regionStatistics.code] || '', title: regionStatisticsFromRequest.regionName});
          })
          return regionStatistics;
        })
        // преобразуем числа двух серий (доступные и недоступные), в процентное соотношение
        // т.к title используется только из первого массива, дополняем процентами только первый массив regionsStatistics
        this.regionsStatistics.data[0].count.forEach((countObj, i) => {
          const percent = (countObj.value + this.regionsStatistics.data[1].count[i].value) / 100;
          countObj.displayedValue = countObj.value;
          countObj.value = (countObj.value / percent).toFixed(2);
          countObj.name = `${countObj.title}`
          this.regionsStatistics.data[1].count[i].name = this.regionsStatistics.data[1].count[i].title;
          this.regionsStatistics.data[1].count[i].displayedValue = this.regionsStatistics.data[1].count[i].value;
          this.regionsStatistics.data[1].count[i].value = (this.regionsStatistics.data[1].count[i].value / percent).toFixed(2);
          countObj.title = `${countObj.title} percent${countObj.value}% `
        })
        this.regionsStatistics.data = this.regionsStatistics.data.map((data) => ({
          ...data,
          count: data.count.reverse()
        }))
        this.regionsStatistics.isAnalyticsLoaded = true;
        this.isLoad = true;
      })
    },
    setAnalyticsByCode(analytics, comparedAnalytics) {
      comparedAnalytics.forEach((statusFromRequest) => {
        analytics.forEach((status, i) => {
          if (status.code === statusFromRequest.code) {
            analytics[i] = Object.assign(statusFromRequest, status);
          }
        });
      });
      return analytics;
    },
  },
  computed: {
    currentLegendTitle() {
      return (
          this.currentLegend?.title +
          (this.currentLegendFrom == "incidents" ? " заявки" : "")
      );
    },
  },
  watch: {
    visibleWidgetSettings: {
      handler(val) {
        localStorage.setItem('displayIncidents', val.displayIncidents);
        localStorage.setItem('displayServices', val.displayServices);
        localStorage.setItem('displayMonitoringObjects', val.displayMonitoringObjects);
        localStorage.setItem('displayProblemRegions', val.displayProblemRegions);
      },
      deep:true,
    }
  },
  name: "DashboardAnalytic"
});
