import {Field} from "@/types/fields";

export const contactFields: Field<any>[] = [
    {
        field: "contactType",
        sortDirections: ["descend", "ascend"],
        name: "Тип",
        value: true,
        compute: (val) => val ? val.name : ''
    },
    {
        field: "contactInfo",
        sortDirections: ["descend", "ascend"],
        name: "Значение",
        value: true
    }
]