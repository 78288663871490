
import { Options, Vue } from "vue-class-component";
import {IncidentListData} from "@/types/incident";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import Accordion from "@/components/ui/Accordion.vue";
import IncidentList from "@/components/incidents/IncidentList.vue";
import {IncidentsApi} from "@/api/incidents.api";
import {getIncidentData, incidentsFields} from "@/consts/fields/incidents.fields";
import {Field} from "@/types/fields";
import {reactive} from 'vue'
import TableSettings from "@/components/base/TableSettings.vue";
import {IncidentFilterImp} from "@/components/classes/IncidentFilterImp";
import IncidentsFilter from "@/components/incidents/IncidentsFilter.vue";
import NSIBase from "@/classes/NSIBase.vue";
import {ServiceApi} from "@/api/service.api";
import { incidentsSettings$, operationQueueSettings$, setVisibleToFieldsInTable } from "@/consts/settings";
import { takeWhile } from "rxjs/operators";
import { operationQueueFields } from "@/consts/fields/operation-queues.fields";
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {
    TableSettings,
    Accordion,
    IncidentList,
    IncidentsFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as IncidentListData[],
      selectedList: [],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof IncidentListData,
      fields: null,
      filter: new IncidentFilterImp(),
      prepareData: getIncidentData(),
      currentUser,
    };
  },
  setup() {
    return {
      locale
    }
  },
  methods: {
    formattedPage() {
      return this.page-1;
    },
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      IncidentsApi
          .downloadExcel(filter, false, 'EXCEL')
          .then((res) => {
            this.$notification.open({
              message: new TextDecoder().decode(res.data),
              style: {
                width: '600px',
                marginLeft: `${400 - 600}px`,
                fontSize: "14px",
              },
              placement: 'bottomRight',
              duration: 3,
            });
          })
          .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix(el) {
      this.search = '';
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    getSelectedIncidents(e) {
      this.selectedList = e;
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    handleClickOnRelateButton() {
      if(this.exceptKey === 'exceptServiceId') {
        this.relateIncidentToService();
        return;
      }
    },
    updateList(value) {
      let column = '';
      if(value.columnKey === 'incidentNumber') {
        column = 'id'
      } else if(value.columnKey === 'createTime') {
        column = 'creationDate'
      } else {
        column = value.columnKey;
      }

      let order = '';
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddIncident() {
      this.$router.push("/incidents/add");
    },
    searchMethod(_) {
      return IncidentsApi
          .applyFilter2(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
    relateIncidentToService() {
      return ServiceApi.addIncidentToService(+this.$route.query.relatedId, this.selectedList[0]).then(() => {
        this.$router.go(-1);
      })
    },
    hasAccessToCreate() {
      return this.currentUser.value.access.items.find((res) => res.menuCode === 'incidents').create
    },
  },
  mounted() {
    incidentsSettings$
      .pipe(
        takeWhile(() => !this.fields),
      )
      .subscribe(incidentsSettings => {
        if(!incidentsSettings) {
          return;
        }
        if(!incidentsSettings.fields) {
          this.fields = incidentsSettings;
          return;
        }
        this.fields = setVisibleToFieldsInTable(incidentsFields, incidentsSettings.fields) as Field<IncidentListData>[];
      })
  }
})
export default class Incidents extends Vue {
  list!: IncidentListData[];
  search: string;
  filter: IncidentFilterImp;
}
