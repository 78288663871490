import { ImportsListData } from "@/types/imports";
import { Field } from "@/types/fields";
import { formatDate } from "@/filters/date";
import { defineTextColorByStatus } from "@/helpers/define-status-color";

export const importsFields: Field<ImportsListData>[] = [
  {
    field: "id",
    sortDirections: ["descend", "ascend"],
    name: "ID",
    value: true,
    minWidth: "100px",
  },
  {
    field: "startTime",
    sortDirections: ["descend", "ascend"],
    name: "Дата операции",
    compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
      formatDate(value, format),
    minWidth: "175px",
    value: true,
  },
  {
    field: "reportStatus",
    sortDirections: ["descend", "ascend"],
    name: "Статус",
    compute: (value) => value?.name,
    getClass: (val) => defineTextColorByStatus(val),
    minWidth: "175px",
    value: true,
  },
  {
    field: "params",
    sortDirections: ["descend", "ascend"],
    name: "Наименование файла",
    value: true,
    minWidth: "350px",
  },
  {
    field: "link",
    sortDirections: ["descend", "ascend"],
    name: "Протокол",
    value: true,
    minWidth: "100px",
  },
];

export const getImportsData = () => ({
  id: "",
  startTime: "",
  endTime: "",
  author: {
    id: null,
    name: "",
  },
  link: "",
  objectName: "",
  packageCount: 1,
  ignoreErrors: false,
  params: "",
  reportType: {
    id: null,
    name: "",
  },
  status: {
    name: "",
    id: null
  },
  file: null
});
