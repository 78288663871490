import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c5fb274c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pl-4 pr-4 mb-4 view-container" }
const _hoisted_2 = { class: "pb-5 mb-4 pl-4 pr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandCharacteristics = _resolveComponent("BrandCharacteristics")!
  const _component_EditForm = _resolveComponent("EditForm")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_delete_dialog = _resolveComponent("delete-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change(_ctx.activeKey),_ctx.handleTabChange,_ctx.changeTab(_ctx.activeKey)))
      }, {
        default: _withCtx(() => [
          (_ctx.hasAccessToRead('dictionary','manufacturers', 'tab_common_information_metric_category'))
            ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: "1",
                tab: "Общая информация"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    (!!_ctx.body)
                      ? (_openBlock(), _createBlock(_component_EditForm, {
                          key: 0,
                          onSave: _ctx.save,
                          onReject: _ctx.cancel,
                          onDelete: _ctx.openDeleteDialog,
                          data: {id: _ctx.body.id,
          isHasSave: _ctx.hasAccessToSave('dictionary','manufacturers', 'tab_common_information_metric_category'),
         isDel: _ctx.hasAccessToDelete('dictionary','manufacturers', 'tab_common_information_metric_category')}
                        }, {
                          default: _withCtx(() => [
                            (!!_ctx.body)
                              ? (_openBlock(), _createBlock(_component_BrandCharacteristics, {
                                  key: 0,
                                  ref: "characteristics",
                                  body: _ctx.body
                                }, null, 8, ["body"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["onSave", "onReject", "onDelete", "data"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["activeKey"])
    ]),
    _createVNode(_component_delete_dialog, {
      ref: "delete",
      onUpdateDialog: _ctx.deleteObject
    }, null, 8, ["onUpdateDialog"])
  ], 64))
}