

import {defineComponent, PropType} from "vue";
import FilterBase from "@/classes/FilterBase.vue";
import {Field} from "@/types/fields";
import {FileManagerFilterImp} from "@/components/classes/FileManagerFilterImp";

export default defineComponent({
  name: 'FileManagerFilter',
  components: {},
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    applyFilter() {
      this.filterChange(new FileManagerFilterImp());
      this.$emit("filter", this.filter);
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    cleanFilter() {
      this.filter = new FileManagerFilterImp();
      this.filterChange(new FileManagerFilterImp());
      this.$emit("filter", this.filter);
    }
  },
  data: () => ({
    filter: new FileManagerFilterImp(),
    filterData: {}
  }),
  mounted() {
    this.applyFilter();
  }
})
