import {Field} from "@/types/fields";
import {ModelsListData} from "@/types/models";

export const modelsFields: Field<ModelsListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "150px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
    {field: "brand", sortDirections: ['descend', 'ascend'], name: "Производитель", value: true, minWidth: ""},
];

export const getModelsData = () => ({
    id: null,
    name: null,
    brand: {id: null, name: ""}
});
