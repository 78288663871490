import {Field} from "@/types/fields";
import {CalculationRuleListData} from "@/types/сalculation-rule";
import {
    defineValueTypeAggregation,
    defineValueTypeInterpolation,
    defineValueTypeWorkTime
} from "@/helpers/defineFieldsValue";

export const calculationRulesFields: Field<CalculationRuleListData>[] = [
    {field: "id", sortDirections: ['descend','ascend'], name: "ID", value: true, minWidth: "100px"},
    {field: "typeRule", sortDirections: ['descend','ascend'], name: "Тип правила", value: true, minWidth: "180px"},
    {field: "timeCondition", sortDirections: ['descend','ascend'], name: "Частота расчета метрики", value: true, minWidth: "180px"},
    {field: "isActive", sortDirections: ['descend', 'ascend'], name: "Активность", value: true, minWidth: "150px",
        compute: (val) => val === true ? 'Да' : 'Нет'},
    {field: "metricIds", sortDirections: ['descend','ascend'], name: "ID метрик", value: true, minWidth: "180px"},
    {field: "typeAggregation", sortDirections: ['descend','ascend'], name: "Тип агрегации", value: true, minWidth: "180px",
        compute: (val) => defineValueTypeAggregation(val)},
    {field: "typeInterpolation", sortDirections: ['descend','ascend'], name: "Тип интерполяции", value: true, minWidth: "180px",
        compute: (val) => defineValueTypeInterpolation(val)},
    {field: "defaultValue", sortDirections: ['descend','ascend'], name: "По умолчанию", value: true, minWidth: "180px"},
    {field: "typeWorkTime", sortDirections: ['descend','ascend'], name: "Тип рабочего времени", value: true, minWidth: "200px",
        compute: (val) => defineValueTypeWorkTime(val)},
    {field: "formula", sortDirections: ['descend','ascend'], name: "Формула", value: true, minWidth: "120px"},
    {field: "metricTypeId", sortDirections: ['descend','ascend'], name: "Тип измерения", value: true, minWidth: "180px"},
];

export const getCalculationRuleData = () => ({
    id: null,
    typeRule: "",
    timeCondition: 0,
    isActive: false,
    metricIds: "",
    typeAggregation: "max",
    typeInterpolation: "default",
    defaultValue: "",
    typeWorkTime: "allTime",
    formula: ""
});
