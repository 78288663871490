import {Field} from "@/types/fields";
import {RolesListData} from "@/types/roles";

export const rolesFields: Field<RolesListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "350px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "200px"},
    {field: "description", sortDirections: ['descend', 'ascend'], name: "Описание", value: true, minWidth: ""},
    // {field: "containerId", sortDirections: ['descend', 'ascend'], name: "ID контейнера", value: true, minWidth: "150px"},
    // {field: "clientRole", sortDirections: ['descend', 'ascend'], name: "Роль", value: true, minWidth: "150px",
    //     compute: (val) => val === true ? 'Активна' : 'Неактивна'},
    // {
    //     field: "attributes", sortDirections: ['descend', 'ascend'], name: "Атрибуты", value: true, minWidth: "150px",
    //     compute: (val) => val && val.length > 0 ? val.map(v => v.name).join(', ') : ''
    // }
];

export const getRolesData = () => ({
    roleKeycloakName: '',
    description: '',
    menuItems: [],
    id: null
});
