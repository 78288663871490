
import {defineComponent} from "vue";
import EditForm from "@/components/base/EditForm.vue";
import {MonitoringTemplateApi} from "@/api/monitoring-template.api";

export default defineComponent({
  components: {EditForm},
  data: () => ({
    body: {},
    fileList: [],
  }),
  methods: {
    handleChange(files) {
      files.fileList = files.fileList.map(_ => {_.status = 'done'; return _})
      this.fileList = files.fileList[0]
    },
    customRequest(ev) {
      console.log(ev);
    },
    add() {
      const formData = new FormData();
      if(this.fileList) {
        formData.append('file' ,this.fileList.originFileObj, this.fileList.name)
      }
      MonitoringTemplateApi.uploadAndReadMibAndSave(
          formData
      ).then((res) => {
        this.route(res.id);
      });
    },
    route(id) {
      if (id) {
        this.$router.replace("/settings/monitoring-templates/" + id);
        return;
      }

      this.$router.replace("/settings/monitoring-templates/");
    },
  },
})
