import { Field } from '@/types/fields'
import { UrgencyData } from '@/types/urgency'

export const urgencyFields: Field<UrgencyData>[] = [
  {
    field: "id",
    sortDirections: ['descend', 'ascend'],
    name: "ID",
    value: true,
    minWidth: "50px",
  },
  {
    field: "name",
    sortDirections: ["descend", "ascend"],
    name: "Наименование",
    value: true,
    minWidth: "140px",
  }
]
export const getUrgencyData = () => ({
  id: null,
  name: '',
})
