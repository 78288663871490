
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getEquipmentTypesData} from "@/consts/fields/equipment-types.fields";
import {MonitoringObjectTypesApi} from "@/api/monitoring-object-types.api";
import {MetricApi} from "@/api/metric.api";
import {SupportTeamApi} from "@/api/supportTeam.api";

export default defineComponent({
  name: "EquipmentTypesCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getEquipmentTypesData(),
    validationFields:[
      {field: "name", validators: ["required"]}
    ],
    parentObjectTypes: [],
    supportTeamList: []
  }),
  mounted() {
    this.getParentObjectTypes();
    this.onSearchSupportTeam();
    this.getCRUDSettings(80)
  },
  methods: {
    onSelectParentId(value, option)  {
      const key = "id";
      this.data.parentId = option[key];
      this.data.parent.id = option[key];
    },
    onSelectSupportTeam(value, option) {
      const key = "id";
      this.data.supportTeamId = option[key];
      this.data.supportTeam.id = option[key];
    },
    test(e) {
      if (!e) {
        this.data.supportTeamId = null;
        this.data.supportTeam.id = null;
      }
    },
    getParentObjectTypes() {
      MonitoringObjectTypesApi.applyFilter(this.data.id ?  {exceptId: this.id} : {}, 100).then((res) => {
        this.parentObjectTypes = res.data;
      })
    },
    onSearchSupportTeam(value?) {
      SupportTeamApi.applyFilter({name: value}).then((res) => {
        this.supportTeamList = res.data;
      });
    }

  }
})
