import { Field } from "@/types/fields";
import { IncidentListData } from "@/types/incident";
import { formatDate } from "@/filters/date";
import moment from "moment";
import { defineTextColorByIncidentsStatus, defineTextColorByStatus } from "@/helpers/define-status-color";

export const incidentsFields: Field<IncidentListData>[] = [
  {
    field: "id",
    sortDirections: ["descend", "ascend"],
    name: "ID",
    value: true,
    minWidth: "100px"
  },
  {
    field: "order_n",
    sortDirections: ["descend", "ascend"],
    name: "Номер услуги",
    value: false,
    minWidth: "160px",
  },
  {
    field: "slaTimeWorking",
    sortDirections: ["descend", "ascend"],
    name: "Время в работе",
    value: false,
    minWidth: "160px",
  },
  {
    field: "createTime",
    sortDirections: ["descend", "ascend"],
    name: "Дата создания",
    value: true,
    compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
      formatDate(value, format),
    minWidth: "175px",
  },
  {
    field: "executionTime",
    sortDirections: ["descend", "ascend"],
    name: "Дата решения",
    value: true,
    compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
      formatDate(value, format),
    minWidth: "150px",
  },
  {
    field: "responsible",
    sortDirections: ["descend", "ascend"],
    name: "Ответственный",
    value: true,
    minWidth: "150px",
  },
  {
    field: "status",
    sortDirections: ["descend", "ascend"],
    name: "Статус",
    value: true,
    minWidth: "125px",
    getClass: (val) => defineTextColorByIncidentsStatus(val),
    compute: (val) => val.name,
  },
  {
    field: "urgency",
    sortDirections: ["descend", "ascend"],
    name: "Срочность",
    value: false,
    getClass: (val) => defineTextColorByStatus(val),
    minWidth: "150px",
    compute: (val) => val?.name || '',
  },
  {
    field: "monitoringObject",
    sortDirections: ["descend", "ascend"],
    name: "Объект",
    value: false,
    getClass: (val) => defineTextColorByStatus(val),
    minWidth: "150px",
  },
  {
    field: "priority",
    sortDirections: ["descend", "ascend"],
    name: "Приоритет",
    value: false,
    getClass: (val) => defineTextColorByStatus(val),
    minWidth: "150px",
    compute: (val) => val.name,
  },
  {
    field: "source",
    sortDirections: ["descend", "ascend"],
    name: "Источник",
    value: true,
    minWidth: "150px",
  },
  {
    field: "description",
    sortDirections: ["descend", "ascend"],
    name: "Описание",
    value: true,
    compute: (val) => {
      if (val) {
        return  val.length <= 150 ? val : val.slice(0,150) + '...'
      }
      else {
        return val
      }
    },
    minWidth: "280px" },
  {
    field: "supportTeam",
    sortDirections: ["descend", "ascend"],
    name: "Команда поддержки",
    value: true,
    minWidth: "200px",
  },
  {
    field: "areaName",
    sortDirections: ["descend", "ascend"],
    name: "Местоположение",
    value: false,
    minWidth: "200px",
  },
  { field: "areaCode", name: "Код местоположения", value: false, minWidth: "200px" },
  {
    field: "responseZone",
    sortDirections: ["descend", "ascend"],
    name: "Контрагент",
    value: false,
    minWidth: "200px",
  },
  { field: "author", name: "Автор", value: false, minWidth: "150px" },
  { field: "nextStatuses", name: "Статусы по схеме", value: false, minWidth: "150px" },
  { field: "timeWorking", name: "Время в работе", value: false, minWidth: "150px" },
  { field: "commentCount", name: "Кол-во комментариев", value: false, minWidth: "150px" },
  { field: "channelType", name: "Тип услуги", value: false, minWidth: "150px" },
  { field: "timePassed", name: "Время простоя", value: false, minWidth: "150px" },
  { field: "contactPerson", name: "Контактное лицо", value: false, minWidth: "150px" },
  { field: "incidentNumber", name: "Номер заявки", value: false, minWidth: "150px" },
  { field: "topic", name: "Тема", value: false, minWidth: "150px" },
  { field: "customerAgent", name: "Специалист заказчика", value: false, minWidth: "150px",  compute: (val) => {
    if (val) {
      return val.name
    }
    }},
  { field: "clientName", name: "Клиент", value: false, minWidth: "150px" },
  { field: "responseZoneId", name: "Зона ответственности", value: false, minWidth: "150px" },
  { field: "deleted",sortDirections: ['descend', 'ascend'],  name: "Архивирован", value: true, minWidth: "150px",
    compute: (val) => val === true ? 'Да' : 'Нет'},
];

export const getIncidentData = () => ({
  id: null,
  incidentNumber: "",
  status: {
    id: null,
    name: "",
    style: "#E50909",
    color: "#FFFFFF",
    comment: null,
  },
  priority: { id: null, name: "" },
  description: "",
  source: { id: null, name: "" },
  supportTeam: { id: null, name: "" },
  createTime: "",
  executionTime: null,
  monitoringObjectDto: { id: null, name: "", ipaddress: "" },
  customerAgent: { id: null, name: ""},
  service: { id: null, orderN: "", channelType: "" , value: "" },
  area: {
    id: null,
    address: "",
    areaStatusCode: 0,
    code: "",
    name: "",
  },
  comments: [],
  files: [],
  author: { id: null, name: "" },
  connectionUnavailable: false,
  metric: {
    id: null,
    name: null,
  },
  responseZone: { id: null, name: null },
  massIncidentType: null,
  parentId: null,
  typeId: null,
  deleted: '',
  type: {
    id: null, name: null
  },
  customAttributes: [],
  commonAttributes: [],
  urgency: {
    id: null, name: null
  },
  additionalContacts: [],
  responsible: {
    id: null,
    name: ''
  }
});

export const incidentCommentFields: Field<any>[] = [
  {
    field: "createTime",
    sortDirections: ["descend", "ascend"],
    name: "Дата",
    value: true,
    compute: (val) => moment(val).format('DD.MM.yyyy, HH:mm')
  },
  {
    field: "author",
    sortDirections: ["descend", "ascend"],
    name: "Автор",
    compute: (val) => val.name,
    value: true
  },
  {
    field: "text",
    sortDirections: ["descend", "ascend"],
    name: "Текст",
    value: true
  },
  {
    field: "file",
    sortDirections: ["descend", "ascend"],
    name: "Файл",
    compute: (val) => val ? val.name : '',
    value: true
  },
]
export const incidentHistoryFields: Field<any>[] = [
  {
    field: "createTime",
    sortDirections: ["descend", "ascend"],
    name: "Дата",
    value: true,
    minWidth: "150px",
    compute: (val) => moment(val).format('DD.MM.yyyy, HH:mm')
  },{
    field: "author",
    sortDirections: ["descend", "ascend"],
    name: "Автор",
    compute: (val) => val.name,
    value: true,
    minWidth: "250px",
  },{
    field: "newStatus",
    sortDirections: ["descend", "ascend"],
    name: "Статус",
    value: true,
    minWidth: "120px"
  },{
    field: "comment",
    sortDirections: ["descend", "ascend"],
    name: "Комментарий",
    value: true,
    // minWidth: "150px"
  },
]
