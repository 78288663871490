import { send, url } from "@/helpers/api";
import { Observable } from "rxjs/dist/types";
import Axios from "axios-observable";
import axios from "axios";

export const ServiceApi = {
  applyFilter(data: any, size = 10, page = 0) {
    return send({
      url: `${url}` + `/services/filter`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilter2(data: any, size = 10, page = 0): Observable<any> {
    return Axios.request({
      method: "post",
      url: `${url}` + `/services/filter`,
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getServiceStatuses(): any {
    return send({
      url: `${url}` + `/classifiers/services/statuses`,
      method: "get",
    });
  },
  save(data: any) {
    return send({
      url: `${url}` + `/services/`,
      method: "post",
      data,
    });
  },
  delete(id) {
    return send({
      url: `${url}` + `/services/${id}`,
      method: "delete",
    });
  },
  getById(id) {
    return send({
      url: `${url}` + `/services/`,
      method: "get",
      params: { serviceId: id },
    });
  },
  getStatuses() {
    return send({
      url: `${url}` + `/classifiers/services/statuses`,
      method: "get",
    });
  },
  getChannelTypes() {
    return send({
      url: `${url}` + `/classifiers/services/channel-types`,
      method: "get",
    });
  },
  getMetrics(serviceId) {
    return send({
      url: `${url}` + `/services/metrics`,
      params: {
        serviceId,
        size:100
      },
      data: {
          sortParams: [{sortFieldName: "name", direction: "asc"}]
      },
      method: "post",
    });
  },
  addIncidentToService(serviceId,incidents) {
    return send({
      url: `${url}` + `/incidents/service`,
      method: "post",
      params: {serviceId: serviceId, incidentId: incidents},
    })
  },
  downloadExcel(request) {
    const zoneId = encodeURIComponent(
        "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return axios({
      method: "post",
      url: `${url}/services/download?zoneId=${zoneId}`,
      data: request,
      responseType: "arraybuffer",
      headers: {
        "Accept": "application/octet-stream",
      }
    });
  },
  deleteAreaService(serviceId, areaId) {
    return send({
      url: `${url}` + `/services/area?serviceId=${serviceId}&areaId=${areaId}`,
      method: "delete",
    });
  },
  archive(id) {
    return send({
      url: `${url}` + `/services/archive?id=${id}`,
      method: "delete",
    });
  },
  unArchive(id) {
    return send({
      url: `${url}` + `/services/unarchive/?id=${id}`,
      method: "post",
    });
  },
  getServiceMetrics(id) {
    return send({
      url: `${url}` + `/services/metrics?size=10&serviceId=${id}`,
      method: "post",
      data: {
        sortParams: [{sortFieldName: "name", direction: "asc"}]
      },
    });
  },
};
