
import {defineComponent, ref} from "vue";

export default defineComponent ({
  mounted() {
    if(this.hasButton) {
      this.isChange = false;
    }
    this.newPass = '';
    this.acceptPass = '';
    this.showPassword = false;
    console.log(this.isChange)
  },
  data () {
    return {
      title: '',
      text: 'Изменение пароля ',
      textApp: '',
      isChange: false,
      newPass: '',
      acceptPass: '',
      showPassword: false,
      hasButton: true
    }
  },
  setup() {
    const visible = ref <boolean> (false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = (e: MouseEvent) => {
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
    };

    return {
      visible,
      showModal,
      handleOk,
      handleCancel
    };
  },
  emits: ["send-message", "change-password"],
  methods: {
    openDialog(title, text) {
      this.showModal();
      this.title = `Смена пароля`;
      this.textApp = text;
    },
    openDialog1(title, text) {
      this.isChange = true
      this.showModal();
      this.title = `Смена пароля`;
      this.textApp = text;
    },
    sendMessage() {
      this.$emit('send-message');
      this.isChange = false;
      this.newPass = '';
      this.acceptPass = '';
      this.showPassword = false;
      this.handleOk(null);
    },
    openChangePassDialog() {
      this.isChange = !this.isChange
    },
    changePass() {
      if (this.newPass === this.acceptPass) {
        this.$emit('change-password', this.newPass);
        this.isChange = false;
        this.newPass = '';
        this.acceptPass = '';
        this.showPassword = false;
        this.handleOk(null);
      }
      else {
        this.$notification.open({
          message: "Введенные пароли не совпадают",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },

  }
})
