import { Field } from "@/types/fields";
import {MetricIndicatorListData} from "@/types/metric-indicator";

export const metricIndicatorFields: Field<MetricIndicatorListData>[] = [
  {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "100px"},
  {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "250px"},
  {field: "codeName", sortDirections: ['descend', 'ascend'], name: "Кодовое наименование", value: true, minWidth: "220px"},
  {field: "isActive", sortDirections: ['descend', 'ascend'], name: "Активность", value: true, minWidth: "150px",
    compute: (val) => val === true ? 'Да' : 'Нет'},
  {field: "description", sortDirections: ['descend', 'ascend'], name: "Описание", value: true, minWidth: "250px"},
  {field: "scale", sortDirections: ['descend', 'ascend'], name: "Единица измерения", value: true, minWidth: "150px"},
  // {field: "key", sortDirections: ['descend', 'ascend'], name: "Ключ", value: true, minWidth: "100px"},
  {field: "aggrType", sortDirections: ['descend', 'ascend'], name: "Агрегация", value: true, minWidth: "150px"},
  {field: "metricGroups", name: "Группы показателей", value: true, minWidth: "200px",
    compute: (val) => val.length > 0 ? val.map(v => v.name).join(', ') : ''},
];

export const getMetricIndicatorData = () => ({
  id: null,
  codeName: "",
  description: "",
  isActive: false,
  name: "",
  scale: ""
});
