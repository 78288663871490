
import {defineComponent} from "vue";
import EditForm from "@/components/base/EditForm.vue";
import {BrandsApi} from "@/api/brands.api";
import BrandCharacteristics from "@/components/brands/BrandCharacteristics.vue";
export default defineComponent({
  name: "BrandAdd",
  components: {BrandCharacteristics, EditForm},
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      BrandsApi.save(
          characteristics
      ).then((res) => {
        this.route(res.id);
      });
    },
    route(id) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace("/directory/brands/" + id);
    },
  },
});
