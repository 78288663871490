
import { defineComponent } from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import { getMetricTypeData } from "@/consts/fields/metrics.fields";
import { ClassifilesApi } from "@/api/classifires.api";
import { getCalculatedStatusRuleData } from "@/consts/fields/calculated-status-rule.fields";
import { CounterpartyApi } from "@/api/counterparty.api";
import { HealthModelRuleApi } from "@/api/health-model-rule.api";
import { MonitoringObjectsApi } from "@/api/monitoring-objects.api";
import { defineTextColorByStatus, getStatusStyle } from "@/helpers/define-status-color";
import { CalculatedStatusRuleApi } from "@/api/calculated-status-rule.api";
import { EquipmentApi } from "@/api/equipment.api";

export default defineComponent({
  name: "CalculatedStatusRuleCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getCalculatedStatusRuleData(),
    measureList: [],
    displayUnitList: [],
    metricInstanceList: [],
    monitoringSystemList: [],
    monitoringObjectStatuses: [],
    isLoaded: false,
    limitNameList: [],
    counterpartyList: [],
    objectTypeList: [],
    metricList: [],
    metricLimits: new Array(1).fill({
      metricId: 0,
      metricName: "",
      limits: [
        {
          id: null,
          name: {
            id: null,
            name: "",
          },
          displayName: "Минимальное значение",
          value: null,
        },
        {
          id: null,
          name: {
            id: null,
            name: "",
          },
          displayName: "Максимальное значение",
          value: null,
        },
      ],
    }),
    counterpartyTotalCount: 0,
    counterpartyCurrentSize: 25,
    counterObjectTypeTotalCount: 0,
    counterObjectTypeCurrentSize: 25,
    oidSnmpList: [],
    metricListTotalCount: 0,
    metricListCurrentSize: 25,
    snmpListTotalCount: 0,
    snmpListCurrentSize: 25,
    metricTypeList: [],
    aggregationList: [
      { name: "avg", id: 1 },
      { name: "min", id: 2 },
    ],
    validationFields: [
      { field: "name", validators: ["required"] },
      { field: "measure.name", validators: ["required"] },
    ],
  }),
  mounted() {
    this.getMeasure();
    this.getMonitoringSystem();
    this.getCRUDSettings(115);
    this.getSnmpOidList();
    this.getMetricInstanceList();
    this.getLimitName();
    this.getStatusesObject();
    this.getObjectType();
    this.getCounterparty();
  },
  methods: {
    getStatusStyle,
    defineTextColorByStatus,
    setInitialMetricInstance() {
      if (this.data.metricInstance?.id) {
        this.metricLimits[0].metricId = this.data.metricInstance.id;
        this.metricLimits[0].metricName =
          this.data.metricInstance.itemId
            ? this.data.metricInstance.itemId + " - " + this.data.metricInstance.name
            : this.data.metricInstance.name;
        this.metricLimits[0].limits[0].name.name = this.data.minValueOperator;
        this.metricLimits[0].limits[0].value = this.data.minValue;
        this.metricLimits[0].limits[1].name.name = this.data.maxValueOperator;
        this.metricLimits[0].limits[1].value = this.data.maxValue;
        this.dataTemp = this.data;
      }
    },
    getLimitName() {
      this.limitNameList = [
        {
          id: 1,
          name: ">",
        },
        {
          id: 2,
          name: ">=",
        },
        {
          id: 3,
          name: "<",
        },
        {
          id: 2,
          name: "<=",
        },
      ];
    },
    changeValueLimit(type, value) {
      if (type === "Минимальное значение") {
        this.data.minValue = value;
      } else {
        this.data.maxValue = value;
      }
    },
    tests() {},
    selectLimit(e, option) {
      if (option.anotherIndex === "Минимальное значение") {
        this.data.minValueOperator = option;
      } else {
        this.data.maxValueOperator = option;
      }
    },
    addLimit(a?) {},
    deleteMetricLimit(a?) {},
    deleteLimit(a?, a2?) {},
    selectMetric(e, option) {
      this.metricLimits[option.index].metricId = option.id;
      this.data.metricInstance = option.id;
    },
    getMetricList() {},
    addMetricLimit() {},
    getMetricInstanceList(e?, option?) {
      return CalculatedStatusRuleApi.getMetricInstance({ name: e }, this.metricListCurrentSize).then(
        (res) => {
          this.metricList = res.data;
          this.metricListTotalCount = res.totalCount;
          if (!this.isLoaded) {
            this.setInitialMetricInstance();
            this.isLoaded = true;
          }
        }
      );
    },
    getMoreInstanceList() {
      this.metricListCurrentSize += 25;
      this.getMetricInstanceList();
    },
    getObjectType() {
      MonitoringObjectsApi.getTypesList().then((res) => {
        this.objectTypeList = res.data;
        this.counterObjectTypeTotalCount = res.totalCount;
      });
    },
    getMoreObjectType() {
      this.counterObjectTypeCurrentSize += 25;
      this.getObjectType();
    },
    getCounterparty() {
      CounterpartyApi.applyFilter({ name: this.data.counterparty.name }).then((res) => {
        this.counterpartyList = res.data;
        this.counterpartyTotalCount = res.totalCount;
      });
    },
    getMoreCounterparty() {
      this.counterpartyCurrentSize += 25;
      this.getCounterpartyList();
    },
    selectOrganization(value, option) {
      this.data.counterparty.id = option.key;
    },
    selectObjectType(value, option) {
      this.data.objectType.id = option.key;
    },
    getStatusesObject() {
      MonitoringObjectsApi.getStatuses().then((res) => {
        this.monitoringObjectStatuses = res;
      });
    },
    getMeasure() {},
    getMonitoringSystem() {},
    cleanAutocomplete(option, event?) {
      if (!event) {
        this.cleanSelect(option);
      }
    },
    getSnmpOidList() {},
    loadMoreSnmp() {
      this.snmpListCurrentSize += 25;
    },
    selectMeasure(value, option) {},
    selectDisplayUnit(value, option) {},
    selectMonitoringSystem(value, option) {
      this.onSelect(value, option);
    },
    onSelectStatus(value) {
      const selectedStatus = this.monitoringObjectStatuses.find(status => status.name === value);
      if (selectedStatus) {
        this.data.status = { ...selectedStatus };
      }
    },
    onSelect(value, option) {
      if (option.field === "aggrType") {
        this.data[option.field] = value;
      }
      if (option.field === "metricCategory") {
        this.data.metricCategory.id = option.id;
      }
      if (option.field === "snmpOid") {
        this.data.snmpOid.id = option.id;
      } else {
        let key = "id";
        this.data[option.field] = option[key];
      }
    },
    clearAutocomplete(option, index?) {
      console.log('clear?', option, index)
      if (option === "counterparty") {
        console.log('clear?2', option, index)
        this.data.counterparty = { id: null, name: "" };
      } else if (option === "objectType") {
        this.data.objectType = { id: null, name: "" };
      } else if (option === "metric") {
        this.metricLimits[index].metricName = "";
        this.metricLimits[index].metricId = 0;
      }
      console.log('data1', this.data)
    },
  },
});
