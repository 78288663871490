
import {Util} from "@/components/utils/Util";
import Accordion from "@/components/ui/Accordion.vue";
import FilterBase from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {ClassifilesApi} from "@/api/classifires.api";
import {CalculationRulesFilterImp} from "@/components/classes/CalculationRulesFilterImp";

export default defineComponent({
  name: "CalculationRulesFilter",
  components: {
    Accordion,
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter();
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    handleVisibleChange(e1) {
      console.log(e1);
    },
    onCheckLastValue(array, name) { // only last value
      Util.onCheckLastValue(array, name);
    },
    onCheckManyValues(array, name) { // many values
      Util.onCheckManyValues(array, name);
    },
    // getMetricTypes() {
    //   ClassifilesApi.getMetricTypes()
    //       .then(res => {
    //         this.valuesForCheckboxes.metricTypeIds = Util.transformToCheckboxes(res);
    //       });
    // },
    applyFilter() {
      this.filter.isActive = this.valuesForCheckboxes.activitiesArr[0] === ''
          ? null : this.valuesForCheckboxes.activitiesArr[0] === 'true';
      this.filter.typeAggregation = this.valuesForCheckboxes.typeAggregationsArr[0] === ''
          ? null : this.valuesForCheckboxes.typeAggregationsArr[0];
      this.filter.typeInterpolation = this.valuesForCheckboxes.typeInterpolationsArr[0] === ''
          ? null : this.valuesForCheckboxes.typeInterpolationsArr[0];
      this.filter.typeWorkTime = this.valuesForCheckboxes.typeWorkTimesArr[0] === ''
          ? null : this.valuesForCheckboxes.typeWorkTimesArr[0];
      this.filter.metricTypeId = this.valuesForCheckboxes.metricTypeIdsArr[0] === ''
          ? null : this.valuesForCheckboxes.metricTypeIdsArr[0];

      // this.filter.metricIds = this.valuesForCheckboxes.metricIdsArr[0] === ''
      //     ? null : this.valuesForCheckboxes.metricIdsArr[0];

      this.filterChange(new CalculationRulesFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, ['id', 'formula', 'typeRule', 'timeCondition']);
      this.setQueryParamsArrDefault(queryParams, []);
      this.$router.push({query: queryParams});
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['id', 'formula', 'typeRule', 'timeCondition']);
      this.setCheckboxesArrFromQuery(query, []);
      if(query.activities) {
        this.valuesForCheckboxes.activitiesArr[0] = query.activities;
      }
      if(query.typeAggregations) {
        this.valuesForCheckboxes.typeAggregationsArr[0] = query.typeAggregations;
      }
      if(query.typeInterpolations) {
        this.valuesForCheckboxes.typeInterpolationsArr[0] = query.typeInterpolations;
      }
      if(query.typeWorkTimes) {
        this.valuesForCheckboxes.typeWorkTimesArr[0] = query.typeWorkTimes;
      }
    },
    cleanFilter() {
      this.filter = new CalculationRulesFilterImp();
      this.defaultValueFilter();
      this.setFilterFields();
      this.filterChange(new CalculationRulesFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'id') {
              this.filter.id = '';
            }
            if(f.field === 'formula') {
              this.filter.formula = '';
            }
            if(f.field === 'metricIds') {
              this.filter.metricIds = '';
            }
            if(f.field === 'isActive') {
              this.valuesForCheckboxes.activitiesArr = [''];
            }
            if(f.field === 'typeAggregation') {
              this.valuesForCheckboxes.typeAggregationsArr = [''];
            }
            if(f.field === 'typeInterpolation') {
              this.valuesForCheckboxes.typeInterpolationsArr = [''];
            }
            if(f.field === 'typeWorkTime') {
              this.valuesForCheckboxes.typeWorkTimesArr = [''];
            }
            // if(f.field === 'metricTypeId') {
            //   this.valuesForCheckboxes.metricTypeIdsArr = [''];
            // }
          });
    },
  },
  data: () => ({
    filter: new CalculationRulesFilterImp(),
    valuesForCheckboxes: {
      activities: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'}
      ],
      activitiesArr: [],
      typeAggregations: [
        {value: '', label: 'Не задано'},
        {value: 'max', label: 'Максимум'},
        {value: 'min', label: 'Минимум'},
        {value: 'avg', label: 'Cреднее'},
        {value: 'sum', label: 'Сумма'}
      ],
      typeAggregationsArr: [],
      typeInterpolations: [
        {value: '', label: 'Не задано'},
        {value: 'prev', label: 'Предыдущее значение'},
        {value: 'default', label: 'Значение по умолчанию'}
      ],
      typeInterpolationsArr: [],
      typeWorkTimes: [
        {value: '', label: 'Не задано'},
        {value: 'allTime', label: 'Вычисление производится постоянно'},
        {value: 'workTime', label: 'Вычисление производится только в рабочие часы'}
      ],
      typeWorkTimesArr: [],
      metricTypeIds: [
        {value: '', label: 'Не задано'},
        {value: 'allTime', label: 'Вычисление производится постоянно'},
        {value: 'workTime', label: 'Вычисление производится только в рабочие часы'}
      ],
      metricTypeIdsArr: [],
      metricIds: [
        {value: '', label: 'Не задано'}
      ],
      metricIdsArr: [],
    },
    filterData: {}
  }),
  mounted() {
    Promise.all([])
        .then(() => {
          this.defaultValueFilter();
          this.getQueryParams();
          this.setFilterFields();
          this.applyFilter();
        });
  },
  setup() {
    return {
      locale
    }
  },
})
