
import {Options, Vue} from "vue-class-component";
import {MetricGroupListData} from "@/types/metric-group";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import Accordion from "@/components/ui/Accordion.vue";
import {Field} from "@/types/fields";
import {reactive} from 'vue'
import TableSettings from "@/components/base/TableSettings.vue";
import NSIBase from "@/classes/NSIBase.vue";
import {MetricLimitListData} from "@/types/metric-limit";
import {MetricLimitFilterImp} from "@/components/classes/MetricLimitFilterImp";
import MonitoringObjectStatusesList from "@/components//monitoring-object-statuses/MonitoringObjectStatusesList.vue";
import MonitoringObjectStatusesFilter from "@/components/monitoring-object-statuses/MonitoringObjectStatusesFilter.vue";
import { MonitoringObjectStatusesListData } from "@/types/monitoring-object-statuses";
import {
  getMonitoringObjectStatusesData,
  monitoringObjectStatusesFields
} from "@/consts/fields/monitoring-object-statuses.fields";
import { MonitoringObjectsFilterImp } from "@/components/classes/MonitoringObjectsFilterImp";
import { MonitoringObjectStatusesFilterImp } from "@/components/classes/MonitoringObjectStatusesFilterImp";
import { MonitoringObjectStatusesApi } from "@/api/monitoring-object-statuses.api";

@Options({
  components: {
    MonitoringObjectStatusesFilter,
    TableSettings,
    Accordion,
    MonitoringObjectStatusesList,
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as MonitoringObjectStatusesListData[],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      /*TODO: dynamic fields*/
      fields: reactive(monitoringObjectStatusesFields.slice() as Field<MonitoringObjectStatusesListData>[]),
      filter: new MonitoringObjectStatusesFilterImp(),
      prepareData: getMonitoringObjectStatusesData()
    };
  },
  setup() {
    return {
      locale
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      MonitoringObjectStatusesApi
          .downloadExcel(filter)
          .then((res) => {
            this.$notification.open({
              message: new TextDecoder().decode(res.data),
              style: {
                width: '600px',
                marginLeft: `${400 - 600}px`,
                fontSize: "14px",
              },
              placement: 'bottomRight',
              duration: 3,
            });
          })
          .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix(el) {
      this.search = '';
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddMetricsGroups() {
      this.$router.push("/directory/monitoring-object-statuses/add");
    },
    searchMethod(_) {
      const filter = this.defineFilterData;
      return MonitoringObjectStatusesApi
          .applyFilter2(filter, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    this.applyFilter();
  },
})
export default class MetricLimits extends Vue {
  list!: MetricLimitListData[];
  search: string;
  filter: MetricLimitFilterImp;
}
