

import { defineComponent, PropType } from "vue";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "EditForm",
  props: {
    data: {
      type: Object as PropType<{ id }>,
      default() {
        return { id: null,
          isDel: true,
          isChangePass: false,
          isArchive: false,
          unArchive: false,
          isActivated: false,
          isDeactivated: false,
          isHasSave: true,
          isSend:false,
          isHealthModel: false,
          addButtonDisabled: false,
        isHasRefreshRelated: false};
      },
    },
    idTitle: {
      default: "ID"
    },
    withoutId: {
      default: false
    },
    addButtonText: {
      default: 'Добавить'
    }
  },
  methods: {
    emitAdd() {
      this.$emit("add");
    },
    emitDelete() {
      this.$emit("delete");
    },
    emitArchive() {
      this.$emit("archive");
    },
    emitChangePass() {
      this.$emit("changePass");
    },
    emitSave() {
      this.$emit("save");
    },
    emitSend() {
      this.$emit("send")
    },
    emitReject() {
      this.$router.go(-1);
    },
    hasButton() {
      return this.currentUser.value.userRoles.includes('client-operator');
    },
    hasAccessToEdit() {
      return !this.currentUser.value.userRoles.includes('nav_contracts')
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    }
  },
  data: ()=> {
    return {
      currentUser,
    }
  },
  mounted() {
  }
});
