
import { defineComponent, reactive } from "vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import IncidentCharacteristics from "@/components/incidents/IncidentCharacteristics.vue";
import {IncidentsApi} from "@/api/incidents.api";
import {incidentCommentFields, incidentHistoryFields} from "@/consts/fields/incidents.fields";
import IncidentCommentList from "@/components/incidents/IncidentCommentList.vue";
import IncidentHistoryList from "@/components/incidents/IncidentHistoryList.vue";
import ViewBase from "@/classes/ViewBase.vue";
import {currentUser} from "@/consts/currentUser";
import moment from "moment";
import BpmnViewer from 'bpmn-js';
import Modeler from 'bpmn-js/lib/Modeler';
import ArchiveDialog from "@/components/dialogs/ArchiveDialog.vue";
import {suffix} from "@/consts/nameSuff";
import IncidentServiceInformation from "@/components/incidents/IncidentServiceInformation.vue";
import { send, sendMultipartFile, url } from "@/helpers/api";
import { v4 as uuidv4 } from 'uuid';
import Editor from "@tinymce/tinymce-vue";
import {getTinymce} from "@tinymce/tinymce-vue/lib/es2015/main/ts/TinyMCE";
import { FileManagerApi } from "@/api/file-manager.api";
import axios from "axios";

export default defineComponent({
  mixins: [ViewBase],
  components: {IncidentServiceInformation, IncidentCommentList, IncidentCharacteristics, DeleteDialog, IncidentHistoryList, ArchiveDialog, Editor },
  mounted() {
    this.getById();
    this.getProcessInstanceId();
    this.getHistoryList();
    this.getHistoryStatusListForBpmn();
    this.getCommentList();

    const accessParams = { module: 'incidents' };
    const tabMap = {
      'tab_common_information_incident': '1',
      'tab_service_information_incident': '2',
      'tab_comments_incident': '3',
      'tab_history_incident': '4',
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null)
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  data: () => ({
    body: null,
    serviceInfoList: null,
    progress: 0,
    tinyCount: 0,
    currentUser,
    history: {
      search: '',
      fields: incidentHistoryFields.slice(),
      list: [],
      size: 10,
      page: 0,
      totalCount: 0,
      sortParams: [{sortFieldName: "createTime", direction: "desc"}]
    },
    bpmnHistoryList: [],
    comment: {
      search: '',
      fields: incidentCommentFields.slice(),
      list: [],
      size: 10,
      page: 0,
      totalCount: 0,
      sortParams: [{sortFieldName: "createTime", direction: "desc"}]
    },
    isAddCommentDialogOpen: false,
    isCheckResponsibleDialogOpen: false,
    isApproveCloseComment: false,
    commentSuccess: false,
    fileAdded: false,
    commentIdTemp: null,
    fileId: '',
    commentForm: {
      comment: '',
      fileList: []
    },
    processInstanceId: null,
    isBpmnOpened: false,
    activityInstance: null,
    statusesXML: null,
    diagramId: '',
    isLoadHistory: false,
    isLoadComments: false,
    addButton: false,
    abortControllers: [],
  }),
  methods: {
    destroyCommentDialog() {
      if (this.commentIdTemp) {
        IncidentsApi.deleteComment(this.commentIdTemp);
        this.commentIdTemp = null;
      }

      if (this.fileId) {
        FileManagerApi.deleteFileStorage(this.fileId);
      }
      this.cancelAllRequests();
      this.commentForm.fileList = [];
      this.closeDialog(true,true);
      this.commentIdTemp = undefined;
    },
    openArchiveDialog(){
      if (this.body.deleted) {
        this.$refs.archive.openDialog("Восстановление заявки",
            `восстановить из архива  заявку ${this.body.id}` , "Восстановить");
      }
      else {
        this.$refs.archive.openDialog("Архивирование заявки ",
            `архивировать  заявку ${this.body.id} `, "В архив");
      }
    },
    archiveObject() {
      if (this.body.deleted) {
        IncidentsApi.unArchive(this.body.id).then(() => {
          this.getById();
          this.$notification.open({
            message: "Восстановлена из архива",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        });
      }
      else {
        IncidentsApi.archive(this.body.id).then(() => {
          this.getById();
          this.$notification.open({
            message: "Архивирована",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        });
      }
    },
    getProcessInstanceId() {
      IncidentsApi.getProcessInstanceId(this.$route.params.id).then(res => {
        this.processInstanceId = res;
        this.getProcessDefinition();
      })
    },
    toggleBPMN() {
      this.isBpmnOpened = !this.isBpmnOpened;
      if(this.isBpmnOpened) {
        this.getActivityInstance();
      }
    },
    getActivityInstance() {
      IncidentsApi.getActivityInstance(this.processInstanceId)
          .then(res => {
            this.activityInstance = res;
            this.renderBpmn();
          })
    },

    getProcessDefinition() {
      IncidentsApi.getActivityInstance(this.processInstanceId)
          .then(res => {
            if(!res.length) {
              return;
            }
            IncidentsApi.getProcessDefinition(res[0].processDefinitionId)
                .then(({bpmn20Xml, id}) => {
                  this.statusesXML = bpmn20Xml;
                  this.diagramId = id;
                })
          })
    },
    renderBpmn() {
      let canvas = document.querySelector('#canvas');
      while (canvas?.firstChild) {
        canvas.removeChild(canvas.firstChild);
      }
      let properties = document.querySelector('#properties');
      while (properties?.firstChild) {
        properties.removeChild(properties.firstChild);
      }
      if(this.viewer) {
        this.viewer.clear();
      }
        let viewer = new Modeler.NavigatedViewer({
            container: '#canvas'
          });
        let activityInstance = this.activityInstance;
        viewer.importXML(this.statusesXML, function(err) {
          if (!err) {
            viewer.get('canvas').zoom('fit-viewport');
            const overlays = viewer.get("overlays"),
                  elementRegistry = viewer.get("elementRegistry");
            activityInstance.forEach(activity => {
              var shape = elementRegistry.get(activity.activityId);
              if (activity.endTime) {
                activity.endTime = moment(activity.endTime).fromNow(true) + ' назад <br>' + `(${moment(activity.endTime).format('DD.MM.yyyy HH:mm')})`;
                overlays.add(activity.activityId, {
                  position: {
                    bottom: -20,
                    left: 0
                  },
                  html: '<div class="timer-bpmn"> ' + activity.endTime + "</div"
                });
              }else {
                overlays.add(activity.activityId, {
                  position: {
                    top: -35,
                    left: -50
                  },
                  html: '<div class="job-bpmn"> Текущее состояние </div>'
                });
              }
              let $overlayHtml = document.createElement("div");
              $overlayHtml.style.width = `${shape.width}px`
              $overlayHtml.style.height = `${shape.height}px`
              $overlayHtml.className = 'highlight-overlay-bpmn'
              overlays.add(activity.activityId, {
                position: {
                  top: 0,
                  left: 0
                },
                html: $overlayHtml
              });
            })
          }
        });
    },
    updateList(value, from: 'history' | 'comment') {
      const {get, params} = this.definedGetList(from);
      let column = value.columnKey;
      let order = '';
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        params.sortParams = [{sortFieldName: "createTime", direction: "desc"}];
        get();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      params.sortParams = [sortParams];
      get();
    },
    updatePage({page, size}, from: 'history' | 'comment') {
      const {get, params} = this.definedGetList(from);
      params.size = size;
      params.page = page - 1;
      get();
    },
    definedGetList(type: 'history' | 'comment') {
      if(type == 'history') {
        return {get: () => this.getHistoryList(), params: this.history}
      }
      return {get: () => this.getCommentList(), params: this.comment}
    },
    customRequest(ev) {
    },
    handleChange(files) {
      files.fileList = this.commentForm.fileList = files.fileList.map(_ => {
        _.status = 'done'; return _
      });

      if (files.fileList.length > 0) {
        let fileExceedsLimit = false;

        this.commentForm.fileList.forEach(({name, originFileObj}) => {
          if (fileExceedsLimit) return;

          const formData = new FormData();
          formData.append('file', originFileObj, name);
          let file: File = formData.get("file") as any;

          if (file.size >= 1073741824) {
            this.$notification.open({
              message: `Размер выбранного файла превышает 1 ГБ`,
              style: {
                width: "500px",
                marginLeft: `${400 - 500}px`,
                fontSize: "14px",
              },
              placement: 'bottomRight',
              duration: 3,
            });
            fileExceedsLimit = true;
          } else {
            this.addComment();
          }
        });

        if (fileExceedsLimit) {
          this.commentForm.fileList = [];
        }
      } else {
        if (!this.commentSuccess) {
          if (this.commentIdTemp) {
            IncidentsApi.deleteComment(this.commentIdTemp);
            this.commentIdTemp = null;
          }
          if (this.fileId) {
            FileManagerApi.deleteFileStorage(this.fileId);
          }
        }
      }
    },
    handleSearchHistoryChanges(ev) {
    },
    openAddCommentDialog() {
      this.isAddCommentDialogOpen = true;
    },
    closeDialog(close: boolean, deleteFile?) {
      console.log('1');
      if(close) {
        this.isAddCommentDialogOpen = false;
        this.commentForm.comment = ""
        this.progress = 0;
        this.commentForm.fileList = []
        this.handleChange({fileList: []})
        this.isApproveCloseComment = false;
        console.log('2', this.commentIdTemp && deleteFile, deleteFile, this.commentIdTemp);
        if (this.commentIdTemp && deleteFile) {
          this.cancelAllRequests();
          IncidentsApi.deleteComment(this.commentIdTemp);
          this.commentIdTemp = null;
          if (this.fileId && deleteFile) {
            FileManagerApi.deleteFileStorage(this.fileId);
          }
        } else {
          this.commentIdTemp = null;
          this.cancelAllRequests();
          this.addButton = false;
        }
      } else {
        console.log('3');
        console.log('TESTESTES', deleteFile);
        this.isApproveCloseComment = !this.isApproveCloseComment;
        this.showApproveCloseModal = this.isApproveCloseComment;
        if(!deleteFile) {
          close = !close;
        }
        if (!this.showApproveCloseModal && close) {
          this.isAddCommentDialogOpen = false;
        }
      }
      // this.$refs.uploadDagger.remove()
    },
    handleOk() {
      this.closeDialog(true);
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("заявки", `заявку ${this.$route.params.id}`);
    },
    getHistoryList() {
      this.isLoadHistory = false;
      IncidentsApi.getHistoryList({
        incidentId: +this.$route.params.id,
        containsValue: this.history.search,
        sortParams: this.history.sortParams
      }, this.history.size, this.history.page).then(({data, pageCount, totalCount}) => {
        this.history.list = data;
        this.history.totalCount = totalCount;
        this.isLoadHistory = true;
      });
    },
    getHistoryStatusListForBpmn() {
      IncidentsApi.getHistoryList({
        incidentId: +this.$route.params.id,
        containsValue: this.history.search,
        sortParams: [{sortFieldName: "createTime", direction: "asc"}]
      }, 100, 0).then(({data}) => {
        this.bpmnHistoryList = data;
      });
    },
    getCommentList() {
      this.isLoadComments = false;
      IncidentsApi.getCommentList({
        incidentId: +this.$route.params.id,
        containsValue: this.comment.search,
        sortParams: this.comment.sortParams
      }, this.comment.size, this.comment.page).then(({data, pageCount, totalCount}) => {
        this.comment.list = data;
        this.comment.totalCount = totalCount;
        this.isLoadComments = true;
      });
    },
    getById() {
      suffix.next(' ' + this.$route.params.id)
      this.getQueryParams();
      IncidentsApi.getById(this.$route.params.id).then((res) => {
        if (res.service) {
          res.service.value =
              res.service.channelType + " канал " + "(" + res.service.orderN + ")";
        }
        this.body = res;
      }).finally(() => {
        this.$refs.characteristics?.cleanWarning();
      });
    },
    addComment(isTextOnly?, isNotification?) {
      this.addButton = true;
      IncidentsApi.addComment({
        authorId: currentUser.value.id,
        incidentId: this.$route.params.id,
        text: this.commentForm.comment
      }, isNotification).then((id) => {
        if(isTextOnly) {
          this.isCheckResponsibleDialogOpen = false;
          this.getById();
          this.getHistoryList()
          this.getCommentList();
          this.addButton = false;
          this.commentSuccess = undefined;
          this.closeDialog(true);
          this.commentIdTemp = undefined;
        } else {
          this.commentIdTemp = id;
          this.uploadFiles(id);
        }
      });
    },
    uploadFiles(id) {
      const promises = [];
      this.commentForm.fileList.forEach(({name, originFileObj}) => {
        const formData = new FormData();
        formData.append('file', originFileObj, name);
        formData.append('sourceId', id);
        formData.append('source', 'COMMENT');
        promises.push(this.sendFile(formData));
      });
      Promise.all(promises).then(_ => {
        this.addButton = false;
        this.commentIdTemp = id;
      })
    },
    checkResponsible() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics =
          this.$refs.characteristics.formatCharacteristicsCopy();
      if ((!characteristics.responsible || characteristics.responsible.id == null || characteristics.responsible.id !== currentUser.value.id) && this.canBeResponsible())
      {
        this.isCheckResponsibleDialogOpen = true;
      } else {
        this.save()
      }

    },
    checkResponsibleForAddCommit() {
      console.log('11');
      this.fileAdded = this.commentForm.fileList.length > 0;
      if(this.tinyCount > 2048) {
        this.$notification.open({
          message: `Превышен лимит символов`,
          style: {
            width: "500px",
            marginLeft: `${400 - 500}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        return;
      }
      let isReturn

      console.log('12');
      if (this.$refs.characteristics.validate() || isReturn) {
        return;
      }
      const characteristics =
          this.$refs.characteristics.formatCharacteristicsCopy();
      console.log('13', currentUser.value.id, characteristics.responsible?.id);
      console.log('isResp',
        (!characteristics.responsible ||characteristics.responsible.id === null || characteristics.responsible.id !== currentUser.value.id) && this.canBeResponsible(),
        !characteristics.responsible,
        characteristics.responsible?.id === null,
        characteristics.responsible?.id !== currentUser.value.id,
        this.canBeResponsible(),
      )
      if ((!characteristics.responsible || characteristics.responsible?.id === null || characteristics.responsible?.id !== currentUser.value.id) && this.canBeResponsible())
      {
        this.isCheckResponsibleDialogOpen = true;
      } else {
        console.log('commentid temp', this.commentIdTemp);
        if (this.commentIdTemp) {
          this.sendNotification();
        } else {
          this.addComment(true, true)
        }
      }
    },
    sendNotification(characteristics?) {
      if(this.commentIdTemp) {
        IncidentsApi.addCommentNotification({
          authorId: currentUser.value.id,
          incidentId: this.$route.params.id,
          commentId: this.commentIdTemp,
          text: this.commentForm.comment
        }).then((id)=> {
          this.getCommentList();
          this.addButton = false;
          this.commentSuccess = true;
          this.closeDialog(true);
        })
      }
      if (characteristics) {
        IncidentsApi.save(characteristics).then((_) => {
          this.isCheckResponsibleDialogOpen = false;
          this.getById();
          this.getHistoryList()
          this.getCommentList();
          this.addButton = false;
          this.commentSuccess = true;
          this.closeDialog(true);
        });
      }
    },
    save(setNewResponsible?) {
      console.log('test4');
      if( this.isAddCommentDialogOpen && !setNewResponsible) {
        this.isCheckResponsibleDialogOpen = false;
        if (this.isAddCommentDialogOpen) {
          console.log('commentid temp', this.commentIdTemp);
          if (this.commentIdTemp) {
            this.sendNotification();
          } else {
            this.addComment(true, true)
          }
        }
        console.log('test5');
        return;
      }
      if (this.$refs.characteristics.validate()) {
        console.log('test6');
        return;
      }
      const characteristics =
          this.$refs.characteristics.formatCharacteristicsCopy();
      characteristics.typeId = characteristics?.type?.id
      if( this.$refs.characteristics.selectedContacts) {
        let arr = [];
        this.$refs.characteristics.selectedContacts.map(res => arr.push({id: res}))
        characteristics.additionalContacts =    arr
      }
      characteristics.customAttributes = this.$refs.characteristics.data.customAttributes.map((res) => {
          if (res.type === "date" && res.value === null) {
          res.value = ''
        }
          return res
      })
      characteristics.commonAttributes = this.$refs.characteristics.data.commonAttributes.map((res) => {
        if (res.type === "date" && res.value === null) {
          res.value = ''
        }
        return res
      })
      if(characteristics.responsible) {
        characteristics.responsibleId = characteristics.responsible.id
      } else {
        characteristics.responsibleId = null
      }
      if( setNewResponsible) {
        characteristics.responsibleId = currentUser.value.id
      }

      this.addButton = false;
      console.log('test1');
      if (this.isAddCommentDialogOpen) {
        console.log('test2');
        IncidentsApi.save(characteristics).then((_) => {
          this.isCheckResponsibleDialogOpen = false;
          this.getById();
          this.getHistoryList()
          if(this.isAddCommentDialogOpen) {
            if (this.commentIdTemp) {
              this.sendNotification(characteristics);
            } else {
              this.addComment(true, true)
            }
            this.isCheckResponsibleDialogOpen = false;
            this.getById();
            this.getHistoryList()
            this.getCommentList();
            this.addButton = false;
            this.commentSuccess = true;
          }
        });
      } else {
        console.log('test3');
        IncidentsApi.save(characteristics).then((_) => {
          this.isCheckResponsibleDialogOpen = false;
          this.getById();
          this.getHistoryList()
          if(this.isAddCommentDialogOpen) {
            this.isCheckResponsibleDialogOpen = false;
          this.getById();
          this.getHistoryList()
          this.getCommentList();
          this.addButton = false;
          this.commentSuccess = true;
          }
        });

      }
    },
    deleteObject() {
      IncidentsApi.delete(this.$route.params.id).then((res) => {
        this.$router.go(-1);
      });
    },
    sendFileOld(data) {
      return send({
        url: `${url}` + `/incidents/file`,
        method: "post",
        onUploadProgress: (ev) => {
          this.progress = Math.round(ev.loaded / ev.total * 100);
        },
        data
      })
    },
    cancelAllRequests() {
      this.abortControllers.forEach(controller => controller.abort());
      this.abortControllers = [];
    },
    canBeResponsible() {
      console.log('TTESTESTEST', this.currentUser.value.roleObjects.includes('set_responsible_model'));
      return this.currentUser.value.roleObjects.includes('set_responsible_model');
    },
    async sendFile(data: FormData) {
      const uniqueId = uuidv4();
      let fileField = data.get('file');
      let body = new FormData();
      let intervalId: number | undefined = undefined;

      if (fileField instanceof File) {
        let fileName = fileField.name;
        let fileSize = fileField.size;
        let source = null;
        let sourceId = null;
        for (let k of data.keys()) {
          if (k === 'source') {
            source = data.get(k);
          }
          if (k === 'sourceId') {
            sourceId = data.get(k);
          }
          if (k !== 'file') {
            body.append(k, data.get(k));
          }
        }
        // data.append('fileId', uniqueId);

        try {
          const controller = new AbortController();
          const { signal } = controller;
          this.abortControllers.push(controller);
          const onUploadProgress = (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total * 100) - 1);
            this.progress = progress;
          };

          await axios({
            method: "post",
            url: `/files/upload2?fileId=${uniqueId}&name=${encodeURIComponent(fileName)}&source=${source}&sourceId=${sourceId}&fileSize=${fileSize}`,
            data: fileField,
            onUploadProgress: onUploadProgress,
            signal: signal,
            headers: {
              'Content-Type': 'application/octet-stream',
            },
          }).then((res) => {
            this.fileId = res.data.id;
          });
          const intervalFunction = async () => {
            const res = await IncidentsApi.fileSaveProgress(uniqueId, fileSize);
            console.log('uploaded files', res.bytesLoaded, res.bytesTotal, res.bytesLoaded === res.bytesTotal);
            if (res.bytesLoaded === res.bytesTotal) {
              this.progress = 100;
              await send({
                url: `${url}` + `/incidents/file?fileId=${uniqueId}&name=${fileName}&source=${source}&sourceId=${sourceId}&fileSize=${fileSize}`,
                method: "post",
                body
              }).then(r => {
                clearInterval(intervalId);
                intervalId = undefined;
              });
            } else {
              let persent = Math.round((res.bytesLoaded / res.bytesTotal * 100) / 2 + 50);
              if(persent !== 50) {
                this.progress = persent;
              }
            }
          };
          intervalId = setInterval(intervalFunction, 2000);
          signal.addEventListener('abort', () => clearInterval(intervalId));
          const intervalPromise = new Promise<void>((resolve) => {
            const checkInterval = setInterval(() => {
              if (intervalId === undefined) {
                clearInterval(checkInterval);
                resolve();
              }
            }, 100);
          });

          await intervalPromise;
        } catch (error) {
          clearInterval(intervalId);
          this.progress = 0;
        }
      }
    }
  },
  watch: {
    'history.search'() {
      this.getHistoryList()
    },
    'comment.search'() {
      this.getCommentList()
    },
    'commentForm.comment'() {
      this.tinyCount = getTinymce().activeEditor.plugins.wordcount.body.getCharacterCount();
    }

  },
  computed: {
    showApproveCloseModal: {
      get() {
        return this.isApproveCloseComment && (this.commentForm.comment || this.commentForm.fileList.length > 0);
      },
      set(value) {
        this.isApproveCloseComment = value;
      },
    },
    diagramTitle() {
      if(!this.diagramId) {
        return '';
      }
      return `${this.diagramId.split(':')[0]},  v${this.diagramId.split(':')[1]}`
    }
  }

});
