import {send, send2, url} from "@/helpers/api";
import axios from "axios";
import Axios from "axios-observable";
import {Observable} from "rxjs/dist/types";

export const MonitoringObjectTypesApi = {
  applyFilter(data: any, size = 10, page = 0): any {
    const zoneId = encodeURIComponent(
      "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return send({
      url: `${url}` + `/classifiers/monitoring-object/filter?zoneId=${zoneId}`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  applyFilter2(data: any, size = 10, page = 0): Observable<any> {
    const zoneId = encodeURIComponent(
      "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return Axios.request({
      url: `${url}` + `/classifiers/monitoring-object/filter?zoneId=${zoneId}`,
      method: "post",
      data,
      params: {
        size: size.toString(),
        page: page.toString(),
      },
    });
  },
  getById(id) {
    return send({
      url: `${url}` + `/classifiers/monitoring-object/${id}`,
      method: "get",
    });
  },
  save(data: any) {
    return send({
      url: `${url}` + `/classifiers/monitoring-object`,
      method: "post",
      data,
    });
  },
  edit(data: any) {
    return send({
      url: `${url}` + `/classifiers/monitoring-object`,
      method: "post",
      data,
    });
  },
  delete(id: string) {
    return send({
      url: `${url}` + `/classifiers/monitoring-object/${id}`,
      method: "delete"
    });
  },
  downloadExcel(request) {
    const zoneId = encodeURIComponent(
        "UTC+" +
        ((new Date().getTimezoneOffset() / 60) * -1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":00"
    );
    return axios({
      method: "post",
      url: `${url}/classifiers/monitoring-object-download?zoneId=${zoneId}`,
      data: request,
      responseType: "arraybuffer",
      headers: {
        "Accept": "application/octet-stream",
      }
    });
  },
  downloadTemplate(name) {
    return send2({
      url: `${url}` + `/reports/download-example?objectName=${name}`,
      method: "get",
      responseType: "blob",
      observe: 'response',
    });
  },
  addRealateMonitoringObjectTypetoMetricType(monitoringObjectTypeId: number, metricTypes: string[]) {
    return send({
      url: `${url}` + `/metrics/metric-type-monitoring-object-type`,
      method: "post",
      data: {
        monitoringObjectTypeId,
        metricTypes
      },
    });
  },
};
