import {Field} from "@/types/fields";
import {FileManagerListData} from "@/types/file-manager";
import {formatDate} from "@/filters/date";

export const fileManagerFields: Field<FileManagerListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "150px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: "120px"},
    {field: "entity", sortDirections: ['descend', 'ascend'], name: "Сущность", value: true, minWidth: "120px"},
    {field: "entityId", sortDirections: ['descend', 'ascend'], name: "ID сущности", value: true, minWidth: "120px"},
    {field: "createAuthor", sortDirections: ['descend', 'ascend'], name: "Автор создания", value: true, minWidth: "120",
        compute: (val) => val ? val.name : ''},
    {field: "deleteAuthor", sortDirections: ['descend', 'ascend'], name: "Автор удаления", value: true, minWidth: "120",
        compute: (val) => val ? val.name : '-'},
    {field: "loadTime", sortDirections: ['descend', 'ascend'], name: "Дата загрузки", value: true, minWidth: "120px"  ,  compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
        {
            return formatDate(new Date(new Date(value).getTime()).toString(), format)
        }},
    {field: "deleteDate", sortDirections: ['descend', 'ascend'], name: "Дата удаления", value: true, minWidth: "120px",    compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
        {
            if(value == null) {
                return ''
            } else {
                return formatDate(new Date(new Date(value).getTime()).toString(), format)
            }
        }},
    {field: "size", sortDirections: ['descend', 'ascend'], name: "Размер(Мб)", value: true, minWidth: "130px", compute: (value)=> {
        return (value/1048576).toFixed(2)
        }},
]