import {Field} from "@/types/fields";
import {ActiveSessionLogListData} from "@/types/active-session-log";
import {formatDate} from "@/filters/date";

export const activeSessionsLogFields:Field<ActiveSessionLogListData>[] = [
    {field: "user", sortDirections: ['descend', 'ascend'], name: "Логин", value: true, minWidth: "150px", compute: val => val.login},
    {field: "ip", sortDirections: ['descend', 'ascend'], name: "IP адрес", value: true, minWidth: "150px"},
    {field: "sessionStartTime", sortDirections: ['descend', 'ascend'], name: "Дата начала сессии", value: true, minWidth: "150px",        compute: (value = null, format = "DD.MM.yyyy, HH:mm") =>
        {
            return formatDate(new Date(new Date(value).getTime()).toString(), format)
        }
        ,},
]