import {send, url} from "@/helpers/api";
import {Observable} from "rxjs";
import Axios from "axios-observable";

export const CustomAttributesApi = {
    applyFilter(data:any, size = 10, page = 0): any {
        return send({
            url: `${url}` + `/classifiers/custom-attributes/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    applyFilter2(data: any, size = 25, page = 0): Observable<any> {
        return Axios.request({
            url: `${url}` + `/classifiers/custom-attributes/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/classifiers/custom-attributes/${id}`,
            method: "get",
        });
    },
    delete(id) {
        return send({
            url: `${url}` + `/classifiers/custom-attributes/${id}`,
            method: "delete",
        });
    },
    getSearchableCustomAttributes(name) {
        return send({
            url: `${url}` + `/classifiers/custom-attributes/searcheable?entityName=${name}`,
            method: "get",
        });
    },
    save(data) {
        return send({
            url: `${url}` + `/classifiers/custom-attributes/save`,
            method: "post",
            data,
        });
    },
}