
import {defineComponent} from "vue";
import {ProcessApi} from "../../api/process.api";
import ProcessCharacteristics from "@/components/processes/ProcessCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";

export default defineComponent({
  name: "ProcessAdd",
  components: { ProcessCharacteristics, EditForm },
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      ProcessApi.save(
          characteristics
      ).then((res) => {
        this.route(characteristics.processDefinitionKey);
      });
    },
    route(id?) {
      this.$refs.characteristics.cleanWarning();
      if (!id) {
        this.$router.replace("/settings/processes/");
        return;
      }
      this.$router.replace("/settings/processes/edit/" + id);
    },
  },
});
