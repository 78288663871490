import {send, url} from "@/helpers/api";

export const IncidentTypeApi = {
    applyFilter(data: any, size = 10, page = 0): any {
        return send({
            url: `/skyv-mon/api/incidents/incident-type/filter`,
            method: "post",
            data,
            params: {
                size: size.toString(),
                page: page.toString(),
            },
        });
    },
    getById(id) {
        return send({
            url: `${url}` + `/incidents/incident-type/${id}`,
            method: "get",
        });
    },
    save(data: any) {
        return send({
            url: `${url}` + `/incidents/incident-type/save`,
            method: "post",
            data,
        });
    },
    delete(id: string) {
        return send({
            url: `${url}` + `/incidents/incident-type/${id}`,
            method: "delete"
        });
    },
}