import {Field} from "@/types/fields";
import {HealthModelRulesListData} from "@/types/health-model-rules";
import {HealthModelRuleRefresh} from "@/types/health-model-rule-refresh";

export const HealthModelRulesFields: Field<HealthModelRulesListData>[] = [
    {field: "id", sortDirections: ['descend', 'ascend'], name: "ID", value: true, minWidth: "150px"},
    {field: "name", sortDirections: ['descend', 'ascend'], name: "Наименование", value: true, minWidth: ""},
]

export const HealthModelRulesRefreshFields: Field<HealthModelRuleRefresh>[] = [
    {field: "id",name: "ID", value: true, minWidth: "50px"},
    {field: "name", name: "Наименование", value: true, minWidth: "112px"},
    {field: "healthModelTemplate",  name: "Текущий шаблон", value: true, minWidth: "112px",
        compute: (val) => val ? val.name : ''},
    {field: "newHealthModelTemplate", name: "Новый шаблон", value: true, minWidth: "112px",
        compute: (val) => val ? val.name : ''},
]
export const getHealthModelRulesData = () => ({
    id: null,
    name: '',
    checkTime: null,
    checkTimeClose: null,
    metricLimitCondition: '',
    metricLimitConditionClose: '',
    healthModelTemplateId: null,
    actions: [],
    openType: '',
    closeType: ''
})