
  import {Field} from "@/types/fields";
  import {Options, Vue} from "vue-class-component";
  import Accordion from "@/components/ui/Accordion.vue";
  import TableSettings from "@/components/base/TableSettings.vue";
  import ReportsList from "@/components/reports/ReportsList.vue";
  import ReportsFilter from "@/components/reports/ReportsFilter.vue";
  import {ReportsFilterImp} from "@/components/classes/ReportsFilterImp";
  import NSIBase from "@/classes/NSIBase.vue";
  import {ReportsListData} from "@/types/reports";
  import {ReportsApi} from "@/api/reports.api";
  import {getReportsData, reportsFields} from "@/consts/fields/reports.fields";
  import {reportsExecDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
  import {takeWhile} from "rxjs/operators";

  @Options({
    components: {
      TableSettings,
      Accordion,
      ReportsList,
      ReportsFilter
    },
    mixins: [NSIBase],
    data: () => {
      return {
        list: [] as ReportsListData[],
        search: "",
        selectedList: [],
        visibleSidebarFilter: false,
        visibleSidebarTableSettings: false,
        totalCount: 0,
        size: 20,
        page: 1,
        userId: null,
        incidentKeys: "" as keyof ReportsListData,
        fields: null,
        filter: new ReportsFilterImp(),
        prepareData: getReportsData(),
        mapLoader: null
      };
    },
    watch: {
      search: {
        handler() {
          this.commonSearch();
        },
        deep: true
      },
    },
    methods: {
      toggleSuffix() {
        this.search = '';
      },
      getSelectedEquipment(e) {
        this.selectedList = e;
      },
      changeStateVisibleSidebarFitler() {
        this.visibleSidebarFilter = !this.visibleSidebarFilter;
      },
      changeStateVisibleSidebarTableSettings() {
        this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
      },
      handleChangeField(e, e1) {
        console.log(this.fields)
      },
      applyFilter(from?: 'filter' | unknown) {
        if(from !== 'filter') {
          if (this.search !== '')
            this.applyFilterRequest$.next('containsValue');
          else
            this.debounceApplyFilter();
          return;
        }
        this.page = 0;
        this.listPage = 1;

        this.debounceApplyFilter();
      },
      debounceApplyFilter(){
        this.applyFilterRequest$.next();
      },
      updateList(value) {
        let order = '';
        let column = value.columnKey;

        if(value.order) {
          order = value.order === 'descend' ? 'desc': 'asc';
        } else {
          this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
          this.applyFilter();
          return;
        }
        let sortParams: any =
                {sortFieldName: column, direction: order};
        this.filter.sortParams = [sortParams];
        this.applyFilter();
      },
      searchMethod(_) {
        return ReportsApi
                .applyFilter2(this.defineFilterData, this.size, this.page);
      },
      resultMethod(res) {
        this.list = res.data.data;
        this.totalCount = res.data.totalCount;
        this.isLoad = true;
        const result = this.list.filter(report => report.link == null);
        if (result.length > 0) {
          this.mapLoader = setTimeout(() => this.applyFilter(), 10000);
        }
      },
    },
    mounted() {
      reportsExecDtoSettings$
          .pipe(
              takeWhile(() => !this.fields),
          )
          .subscribe(settings => {
            if(!settings) {
              return;
            }
            if(!settings.fields) {
              this.fields = settings;
              return;
            }
            this.fields = setVisibleToFieldsInTable(reportsFields, settings.fields) as Field<ReportsListData>[];
          });
    },
    beforeUnmount() {
      clearTimeout(this.mapLoader);
    },
  })
  export default class Processes extends Vue {
    list!: ReportsListData[];
    search: string;
    filter: ReportsFilterImp;
  }
